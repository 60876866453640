import * as React from 'react'
import classNames from 'classnames'

import styles from './AttachmentPreviewList.module.scss'
import { Link, RouteComponentProps, withRouter } from "react-router-dom";

export type Attachment = {
    id: string,
    name: string,
    issueId?: string
}

interface RouteParams {
    buildingId?: string
    id?: string
}

interface Props extends RouteComponentProps<RouteParams> {
    withRemoveButton?: boolean
    images: Attachment[]
    documents: Attachment[]
    onRemoveAttachment?: (event: React.MouseEvent<HTMLButtonElement>, id: string) => void
    className?: string
    invertedColors?: boolean
}

const ImageLink = ({ attachment, buildingId }: { attachment: Attachment, buildingId: string | undefined }) => {
    if (!!attachment.issueId) {
        let imageViewerUrl = `/issue/${attachment.issueId}/image/${attachment.id}`
        if (!!buildingId) {
            imageViewerUrl = `/building/${buildingId}/issues${imageViewerUrl}`
        }

        return (
            <Link to={imageViewerUrl}>
                {attachment.name}
            </Link>
        )
    } else {
        return (
            <a href={`/api/image/${attachment.id}`} target="_blank" download>{attachment.name}</a>
        )
    }
}

const DocumentLink = ({ attachment, buildingId }: { attachment: Attachment, buildingId: string | undefined }) => {

    return (
        <a href={`/api/documentAttachment/${attachment.id}`} target="_blank" download>{attachment.name}</a>
    )

}

const AttachmentPreviewList = (props: Props) => {

    const {
        withRemoveButton = false,
        images,
        documents,
        onRemoveAttachment,
        className = "",
        invertedColors = false
    } = props

    if (withRemoveButton && !onRemoveAttachment) {
        throw Error("onRemoveAttachment function should be included when withRemoveButton is true")
    }

    const classes = classNames(styles["list"], {
        [className]: !!className,
        [styles["list--inverted"]]: invertedColors
    })

    const { buildingId } = props.match.params

    return (
        <ul className={classes}>
            {images.map(attachment => (
                <li key={attachment.id} className={styles["list-element"]}>
                    <ImageLink attachment={attachment} buildingId={buildingId} />
                    {withRemoveButton && (
                        <button onClick={e => onRemoveAttachment!(e, attachment.id)} />
                    )}
                </li>
            ))}
            {documents.map(attachment => (
                <li key={attachment.id} className={styles["list-element"]}>
                    <DocumentLink attachment={attachment} buildingId={buildingId} />
                    {withRemoveButton && (
                        <button onClick={e => onRemoveAttachment!(e, attachment.id)} />
                    )}
                </li>
            ))}
        </ul>
    )
}

export default withRouter(AttachmentPreviewList)