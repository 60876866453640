import * as React from 'react'
import { FormEvent } from 'react'
import styles from './BuildingViewLayout.module.scss'
import { NavLink, Redirect, Route, RouteComponentProps, Switch } from "react-router-dom"
import BuildingOwnerIssues from "./Issues/BuildingOwnerIssues"
import Contacts from "../Contacts/Contacts"
import BuildingHeaderBlock from "./BuildingHeaderBlock/BuildingHeaderBlock"
import OwnerDocumentsContainer from "../Documents/OwnerDocuments";
import { Building, Company, Contract, UserInfo } from "../../types/UserInfo";

interface Params {
    buildingId: string
}

interface BuildingViewLayoutProps extends RouteComponentProps<Params> {
    userInfo: UserInfo
    preferredContract: Contract
    preferredBuilding: Building
    preferredCompany: Company
    onPreferredBuildingChange: (event?: FormEvent<HTMLSelectElement>, value?: any) => void
    onPreferredContractChange: (event: FormEvent<HTMLSelectElement>) => void
}

class BuildingViewLayout extends React.Component<BuildingViewLayoutProps> {
    render(): React.ReactNode {
        const {
            match: {
                params: {
                    buildingId
                }
            },
            userInfo,
            preferredContract,
            preferredBuilding,
            preferredCompany,
            onPreferredBuildingChange,
            onPreferredContractChange
        } = this.props

        return (
            <>
                <BuildingHeaderBlock selectedBuildingId={buildingId} onPreferredBuildingChange={onPreferredBuildingChange}
                    onPreferredContractChange={onPreferredContractChange} />

                <nav className={styles["navigation"]}>
                    <ul className={styles["navigation-list"]}>
                        <li className={styles["navigation-list-item"]}>
                            <NavLink to={`/building/${buildingId}/issues`}
                                className={styles["navigation-link"]}
                                activeClassName={styles["navigation-link--active"]}>
                                Saker
                            </NavLink>
                        </li>
                        {<li className={styles["navigation-list-item"]}>
                            <NavLink to={`/building/${buildingId}/documents`}
                                className={`${styles["navigation-link"]}`}
                                activeClassName={styles["navigation-link--active"]}>
                                Dokumenter
                            </NavLink>
                        </li>}
                        <li className={styles["navigation-list-item"]}>
                            <NavLink to={`/building/${buildingId}/contacts`}
                                className={styles["navigation-link"]}
                                activeClassName={styles["navigation-link--active"]}>
                                Kontakt
                            </NavLink>
                        </li>
                    </ul>
                </nav>

                <section>
                    <Switch>
                        <Route path={"/building/:buildingId/issues"} render={((props) => <BuildingOwnerIssues {...props} userInfo={userInfo} preferredBuilding={preferredBuilding} />)} />
                        <Route exact path={"/building/:buildingId/documents"} component={OwnerDocumentsContainer} />
                        <Route exact path={"/building/:buildingId/contacts"} render={(props) => <Contacts buildingId={props.match.params.buildingId} userInfo={this.props.userInfo} />} />
                        <Route exact path={"/building/:buildingId"} render={() => <Redirect to={`/building/${buildingId}/issues`} />} />
                    </Switch>
                </section>
            </>
        )
    }
}

export default BuildingViewLayout