import * as React from 'react'

import styles from './FieldErrorMessage.module.scss'
import ExclamationIcon from "../Icons/ExclamationIcon";

const FieldErrorMessage = ({ children, className = "", hidden, ...htmlAttributes }: React.HTMLProps<HTMLParagraphElement>) => (
    <p {...htmlAttributes} className={`${styles["message"]} ${className} ${hidden ? styles["message--hidden"] : ""}`}>
        <ExclamationIcon/> <span>{children}</span>
    </p>
)

export default FieldErrorMessage
