import * as React from 'react'
import { Link, useLocation, useHistory } from "react-router-dom";

import styles from "../Authentication.module.scss";
import TextField from "../../../viewComponents/TextField/TextField";
import { ConfirmationButton } from "../../../viewComponents/Buttons/Buttons";
import FieldErrorMessage from "../../../viewComponents/FieldErrorMessage/FieldErrorMessage";
import { useAuthApi, LOGIN_PATH, LoginRequestParams } from "../../../hooks/useAuthApi";
import useEffectSkipMount from "../../../hooks/useEffectSkipMount";

const LoginForm: React.FC = () => {

    const { isLoading, responseStatus, doApiCall } = useAuthApi()
    const location = useLocation<{ emailValidationSuccess: boolean }>()
    const history = useHistory()

    // Set isAuthenticated to true and redirect if response status changes to 200
    useEffectSkipMount(() => {
        if (responseStatus == 200) {
            localStorage.setItem("isAuthenticated", "true");
            history.push("/")
        }
    }, [responseStatus])

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const formElements = event.currentTarget.elements

        const apiParams: LoginRequestParams = {
            path: LOGIN_PATH,
            body: {
                username: (formElements.namedItem("username") as HTMLInputElement).value,
                password: (formElements.namedItem("password") as HTMLInputElement).value
            }
        }

        doApiCall(apiParams)
    }

    let headerText = "Logg inn"
    let hint = "Skriv inn epost og passord for å logge inn."
    if (location.state && location.state.emailValidationSuccess) {
        headerText = "Din e-postadresse er bekreftet"
        hint = "Du kan nå logge inn i kundeportalen"
    }

    return (
        <form className={styles['login-form']} onSubmit={onSubmit}>
            {/* <h2 className={styles['login-form-header']}>
                {headerText}
            </h2>

            <p className={styles['login-form-hint']}>
                {hint}
            </p> */}

            <TextField name="username"
                className={styles['input-field']}
                placeholder={"E-POSTADRESSE"}
                alwaysShowLabel={true}
                autoFocus
            // classNames={styles['inputFieldProp']}
            />
            <TextField name="password"
                className={styles['input-field']}
                placeholder={"PASSORD"}
                alwaysShowLabel={true}
                type="password" />

            <FieldErrorMessage hidden={(responseStatus == undefined || responseStatus == 200)}>
                Feil brukernavn eller passord
            </FieldErrorMessage>

            <div className={styles["action-wrapper"]}>

                <ConfirmationButton type="submit" className={styles['login-button']} isLoading={isLoading}>
                    LOGG INN
                    </ConfirmationButton>

                <div>
                    <Link to={"/auth/password/reset"} className={styles['link']}>GLEMT PASSORD?</Link>
                    <Link to={"/auth/register"} className={styles['link']}>REGISTRER DEG</Link>
                </div>
            </div>
        </form>
    )
}

export default LoginForm