import * as React from 'react'

const LogoutIcon = (props: React.ComponentProps<"svg">) => (
    <svg width="17px" height="18px" viewBox="0 0 17 18" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M11,2.589 L11,4.812 C12.791,5.852 14,7.786 14,10 C14,13.309 11.309,16 8,16 C4.691,16 2,13.309 2,10 C2,7.785 3.209,5.852 5,4.812 L5,2.589 C2.072,3.779 0,6.65 0,10 C0,14.411 3.589,18 8,18 C12.411,18 16,14.411 16,10 C16,6.65 13.928,3.779 11,2.589 Z"/>
        <rect x="7" y="0" width="2" height="8"/>
    </svg>
)

LogoutIcon.displayName = "LogoutIcon";

export default LogoutIcon