import * as React from 'react'

import styles from "./Header.module.scss";
import { Building, Contract, UserInfo } from "../../types/UserInfo";
import { buildingsForUser } from "../../tools/userUtils";
import SelectInput from "../../viewComponents/SelectInput/SelectInput";
import { FormEvent } from "react";

interface Props {
    userInfo: UserInfo
    preferredBuilding: Building
    preferredContract: Contract
    onPreferredBuildingChange: (event?: FormEvent<HTMLSelectElement>, value?: any) => void
    onPreferredContractChange: (event: FormEvent<HTMLSelectElement>) => void
}


class BuildingAndContract extends React.Component<Props> {

    render(): React.ReactNode {
        const { userInfo, preferredBuilding, preferredContract, onPreferredBuildingChange, onPreferredContractChange } = this.props

        const availableBuildings = buildingsForUser(userInfo)
        const availableContracts = preferredBuilding.contracts

        const multipleAvailableBuildings = availableBuildings.length > 1
        const multipleAvailableContracts = availableContracts.length > 1

        const buildingsDropdownContent = availableBuildings.map(building => {
            return { id: building.id, displayValue: building.street }
        })

        const contractDropdownContent = availableContracts.map(contract => {
            return { id: contract.contractId, displayValue: contract.name }
        })

        return (
            <>
                <span className={styles["building-and-contract"]}>
                    <span className={styles["dropdown-wrapper"]}>
                        {!multipleAvailableBuildings &&
                            (<span className={styles["text"]}>{preferredBuilding.street}</span>)
                        }
                        {multipleAvailableBuildings && (
                            <SelectInput optionsWithId={buildingsDropdownContent} onChange={onPreferredBuildingChange} defaultValue={preferredBuilding.id} />
                        )}
                    </span>
                    <span className={styles["dropdown-wrapper"]}>
                        {!multipleAvailableContracts &&
                            (<span className={styles["text"]}>{preferredContract.name}</span>)
                        }
                        {multipleAvailableContracts && (
                            <SelectInput optionsWithId={contractDropdownContent} onChange={onPreferredContractChange} defaultValue={preferredContract.contractId} />
                        )}
                    </span>
                </span>
            </>
        )
    }
}

export default BuildingAndContract
