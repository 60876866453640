import * as React from "react";
import {Company} from "../../types/UserInfo";

interface LogoProps extends React.HTMLProps<HTMLImageElement> {
    company: Company
}

export const CompanyLogo = (props: LogoProps) => {

    const {
        company,
        className
    } = props

    if (!!company.imageId) {
        return <img src={`/api/image/${company.imageId}`} alt={company.name} className={className}/>
    } else {
        return <span />
    }


}