import * as React from 'react'
import { FormEvent } from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'
import classNames from 'classnames'

import styles from './DashboardLayout.module.scss'
import IssuesDashboard from "../IssuesDashboard/IssuesDashboard"
import NewsecLogo from "../../viewComponents/Icons/NewsecLogo/NewsecLogo"
import Header from "./Header"
import { Building, Company, Contract, UserInfo } from "../../types/UserInfo"
import { Query } from "react-apollo"
import { gql } from "apollo-boost"
import BuildingList from "../BuildingOwnerDashboard/BuildingsList/BuildingsList";
import BuildingViewLayout from "../BuildingOwnerDashboard/BuildingViewLayout";
import { PrimaryLinkButton } from "../../viewComponents/Buttons_v2/PrimaryButton/PrimaryButton";
import { IconLinkButton } from "../../viewComponents/Buttons_v2/IconButton/IconButton";
import BuildingIcon from "../../viewComponents/Icons/BuildingIcon/BuildingIcon";
import IssuesIcon from "../../viewComponents/Icons/IssuesIcon";
import ContactIcon from "../../viewComponents/Icons/ContactIcon";
import IssueInformationForm from "../CreateIssueWizard/IssueInformationForm/IssueInformationForm";
import IssueCreatedConfirmation from "../CreateIssueWizard/IssueCreatedConfirmation/IssueCreatedConfirmation";
import HamburgerButton from "../../viewComponents/HamburgerButton/HamburgerButton";
import DocumentIcon from "../../viewComponents/Icons/DocumentIcon";
import DocumentsContainer from "../Documents/TenantDocuments";
import CenteredLoadingIcon from "../../viewComponents/CenteredLoadingIcon/CenteredLoadingIcon";
import {
    buildingFromBuildingId,
    buildingsForUser,
    contractFromContractId,
    getPreferredBuilding,
    getPreferredCompany,
    getPreferredContract
} from "../../tools/userUtils";
import ContractContactsContainer from '../Contacts/ContractContacts'
import { Services } from '../Services/Services'
import ServiceOrdersDashboard from '../IssuesDashboard/ServiceOrdersDashboard';
import { NotificationSidebar, Notification } from '../Notifications/Notification';
import { NewIssue } from '../CreateIssueWizard/NewIssue';
import Brannbok from '../Documents/Brannbok';
import History from '../../History';

interface RoleRoutingProps {
    userInfo: UserInfo
    preferredBuilding: Building
    preferredContract: Contract
    preferredCompany: Company
    onPreferredBuildingChange: (event?: FormEvent<HTMLSelectElement>, value?: any) => void
    onPreferredContractChange: (event: FormEvent<HTMLSelectElement>) => void
}

const RoleRouting = (props: RoleRoutingProps) => {

    const { userInfo, preferredBuilding, preferredContract, preferredCompany, onPreferredBuildingChange, onPreferredContractChange } = props
    const allBuildings: Building[] = buildingsForUser(userInfo)
    const isOwner = userInfo.roles.includes("OWNER")

    return (
        <Switch>
            <Route exact
                path={"/"}
                render={(props) => userInfo.roles.includes("OWNER") ? <BuildingList onPreferredBuildingChange={onPreferredBuildingChange}></BuildingList> : <IssuesDashboard userInfo={userInfo} {...props} preferredContract={preferredContract} preferredBuilding={preferredBuilding} />}
            />

            <Route exact
                path={"/issue/:id"}
                render={(props) => <IssuesDashboard {...props} userInfo={userInfo} preferredContract={preferredContract} preferredBuilding={preferredBuilding} />} />

            {isOwner && (
                <Route path={"/building/:buildingId"} render={(props) => <BuildingViewLayout {...props} userInfo={userInfo} preferredContract={preferredContract} preferredBuilding={preferredBuilding}
                    preferredCompany={preferredCompany} onPreferredBuildingChange={onPreferredBuildingChange}
                    onPreferredContractChange={onPreferredContractChange} />} />
            )}

            {!isOwner && (
                <Route exact path={"/contacts"} render={() => (
                    <>
                        {/* {allBuildings.map(building => (
                            <React.Fragment key={building.id}>
                                <ContactsContainer title={`Forvaltningsteam for ${building.street}`}
                                    userInfo={userInfo}
                                    buildingId={building.id}
                                    contractId={preferredContract.contractId}
                                    preferredContractName={preferredContract.name}
                                    preferredBuildingName={preferredBuilding.street}
                                    preferredCompany={preferredCompany} />
                            </React.Fragment>
                        ))} */}
                        {
                            <ContractContactsContainer
                                userInfo={userInfo}
                                contractId={preferredContract.contractId}
                                preferredContractName={preferredContract.name}
                                preferredBuildingName={preferredBuilding.street}
                                preferredCompany={preferredCompany}></ContractContactsContainer>
                        }
                    </>
                )} />
            )}

            {!userInfo.roles.includes("OWNER") && (
                <Route exact path={"/documents"} render={(props) => (
                    <DocumentsContainer {...props} userInfo={userInfo} preferredContract={preferredContract} />
                )} />
            )}

            {!userInfo.roles.includes("OWNER") && (
                <Route exact path={"/brannbok"} render={(props) => (
                    <Brannbok {...props} userInfo={userInfo} preferredContract={preferredContract} />
                )} />
            )}

            <Route exact path={"/notifiactions"} render={() => <Notification building={preferredBuilding}></Notification>} />

            <Route exact path={"/new-issue"} render={(props) => <NewIssue {...props} userInfo={userInfo} preferredBuilding={preferredBuilding} preferredContract={preferredContract} />} />
            <Route exact path={"/services"} render={(props) => <Services {...props} userInfo={userInfo} preferredBuilding={preferredBuilding} preferredContract={preferredContract} preferredCompany={preferredCompany} />} />
            <Route exact path={"/serviceOrders"} render={(props) => <ServiceOrdersDashboard userInfo={userInfo} {...props} preferredContract={preferredContract} preferredBuilding={preferredBuilding} />} />
            <Route path={"/new-issue/form"} render={(props) => <IssueInformationForm {...props} preferredBuilding={preferredBuilding} preferredContract={preferredContract} />} />
            <Route path={"/new-issue/confirmation"} component={IssueCreatedConfirmation} />


            <Route component={() => <h1>Page not found</h1>} />
        </Switch>
    )
}

interface DashboardLayoutProps extends RouteComponentProps {
    userInfo: UserInfo
}

interface State {
    isMobileMenuOpen: boolean
    preferredBuilding: Building
    preferredContract: Contract
    preferredCompany: Company,
    activeNavBtnRoute: string
}

class DashboardLayout extends React.Component<DashboardLayoutProps, State> {

    state: State = {
        isMobileMenuOpen: false,
        preferredBuilding: getPreferredBuilding(this.props.userInfo),
        preferredContract: getPreferredContract(this.props.userInfo),
        preferredCompany: getPreferredCompany(this.props.userInfo),
        activeNavBtnRoute: location.pathname
    }

    toggleMobileMenu = () => this.setState({ isMobileMenuOpen: !this.state.isMobileMenuOpen })

    onClickNavigationLink = () => {
        this.setState({ isMobileMenuOpen: false })
    }

    onPreferredBuildingChange = (event?: FormEvent<HTMLSelectElement>, value?: string) => {
        let buildingId;
        if (event) {
            buildingId = event.currentTarget.value;
        } else {
            buildingId = value;
        }
        localStorage.setItem("preferred-building-id", buildingId)
        localStorage.removeItem("preferred-contract-id")

        const preferredBuilding = buildingFromBuildingId(buildingId, this.props.userInfo)
        this.setState({ preferredBuilding: preferredBuilding, preferredContract: preferredBuilding.contracts[0] }, () => {
            this.setState({ preferredCompany: getPreferredCompany(this.props.userInfo) })
        })
    }

    onPreferredContractChange = (event: FormEvent<HTMLSelectElement>) => {
        const contractId = event.currentTarget.value
        localStorage.setItem("preferred-contract-id", contractId)
        this.setState({ preferredContract: contractFromContractId(contractId, this.state.preferredBuilding.id, this.props.userInfo) })
    }

    render() {
        let { userInfo } = this.props
        // userInfo.roles.push("OWNER");
        const { preferredBuilding, preferredContract, preferredCompany, activeNavBtnRoute } = this.state

        return (
            <div className={styles["wrapper"]}>
                <div className={styles["header-wrapper"]}>
                    <Header userInfo={userInfo} preferredBuilding={preferredBuilding} preferredContract={preferredContract} onPreferredBuildingChange={this.onPreferredBuildingChange} onPreferredContractChange={this.onPreferredContractChange} />
                </div>
                <div className={["ms-Grid", styles["main-content"]].join(" ")} dir="ltr">
                    <section className={styles["page-content-wrapper"]}>
                        <RoleRouting userInfo={userInfo} preferredBuilding={preferredBuilding} preferredContract={preferredContract} preferredCompany={preferredCompany}
                            onPreferredBuildingChange={this.onPreferredBuildingChange} onPreferredContractChange={this.onPreferredContractChange} />
                    </section>
                </div>

                <aside className={styles['left-column']}>
                    <HamburgerButton className={styles["hide-on-desktop"]}
                        isOpen={this.state.isMobileMenuOpen}
                        onClick={this.toggleMobileMenu} />

                    <div className={styles['logo-wrapper']}>
                        <NewsecLogo className={styles['newsec-logo']} />
                    </div>


                    <nav className={classNames(styles["main-menu"], { [styles["main-menu--open"]]: this.state.isMobileMenuOpen })}>
                        <ul className={styles["nav-links-list"]}>
                            <li className={`${styles["new-issue-button"]} ${activeNavBtnRoute == "/new-issue" ? styles.newIssueActive : ""}`} onClick={() => { this.setState({ activeNavBtnRoute: "/new-issue", isMobileMenuOpen: false }); History.push("/new-issue") }}>
                                <PrimaryLinkButton to={"/new-issue"} className={``} onClick={() => { this.setState({ activeNavBtnRoute: "/new-issue", isMobileMenuOpen: false }) }}>
                                    <span>REGISTRER NY SAK</span>
                                </PrimaryLinkButton>
                            </li>
                            <li className={`${styles["new-service-button"]} ${activeNavBtnRoute == "/services" ? styles.newServiceActive : ""}`} onClick={() => { this.setState({ activeNavBtnRoute: "/services", isMobileMenuOpen: false }); History.push("/services") }}>
                                <PrimaryLinkButton to={"/services"} className={``} onClick={() => { this.setState({ activeNavBtnRoute: "/services", isMobileMenuOpen: false }) }}>
                                    <span>BESTILL NY TJENESTE</span>
                                </PrimaryLinkButton>
                            </li>
                        </ul>
                        <ul className={styles["bottom-nav-links-list"]}>
                            {userInfo.roles.includes("OWNER") && (
                                <>
                                    <li className={`${activeNavBtnRoute == "/" ? styles.activeSidebarBtn : ""}`} onClick={() => { this.setState({ activeNavBtnRoute: "/" }); History.push("/") }}>
                                        <IconLinkButton IconComponentType={BuildingIcon}
                                            exact={true}
                                            activeClassName={styles["active"]}
                                            to={"/"}
                                            className={styles["nav-link"]}
                                            onClick={this.onClickNavigationLink}
                                            iconClassName={styles["nav-link-icon"]}>
                                            BYGG
                                        </IconLinkButton>
                                    </li>
                                </>
                            )}

                            {!userInfo.roles.includes("OWNER") && (
                                <>
                                    <li className={`${activeNavBtnRoute == "/" ? styles.activeSidebarBtn : ""}`} onClick={() => { this.setState({ activeNavBtnRoute: "/" }); History.push("/") }}>
                                        <IconLinkButton IconComponentType={IssuesIcon}
                                            exact={true}
                                            activeClassName={styles["active"]}
                                            to={"/"}
                                            className={styles["nav-link"]}
                                            onClick={this.onClickNavigationLink}
                                            iconClassName={styles["nav-link-icon"]}>
                                            SAKER
                                        </IconLinkButton>
                                    </li>
                                    <li className={`${activeNavBtnRoute == "/serviceorders" ? styles.activeSidebarBtn : ""}`} onClick={() => { this.setState({ activeNavBtnRoute: "/serviceorders" }); History.push("/serviceorders") }}>
                                        <IconLinkButton IconComponentType={IssuesIcon}
                                            exact={true}
                                            activeClassName={styles["active"]}
                                            to={"/serviceorders"}
                                            className={styles["nav-link"]}
                                            onClick={this.onClickNavigationLink}
                                            iconClassName={styles["nav-link-icon"]}>
                                            TJENESTER
                                        </IconLinkButton>
                                    </li>
                                    <li className={`${activeNavBtnRoute == "/documents" ? styles.activeSidebarBtn : ""}`} onClick={() => { this.setState({ activeNavBtnRoute: "/documents" }); History.push("/documents") }}>
                                        <IconLinkButton IconComponentType={DocumentIcon}
                                            exact={true}
                                            activeClassName={styles["active"]}
                                            to={"/documents"}
                                            onClick={this.onClickNavigationLink}
                                            className={styles["nav-link"]}
                                            iconClassName={styles["nav-link-icon"]}>
                                            DOKUMENTER
                                        </IconLinkButton>
                                    </li>
                                    <li className={`${activeNavBtnRoute == "/contacts" ? styles.activeSidebarBtn : ""}`} onClick={() => { this.setState({ activeNavBtnRoute: "/contacts" }); History.push("/contacts") }}>
                                        <IconLinkButton IconComponentType={ContactIcon}
                                            exact={true}
                                            activeClassName={styles["active"]}
                                            to={"/contacts"}
                                            className={styles["nav-link"]}
                                            onClick={this.onClickNavigationLink}
                                            iconClassName={styles["nav-link-icon"]}>
                                            KONTAKTLISTE
                                        </IconLinkButton>
                                    </li>
                                    <li className={`${activeNavBtnRoute == "/notifiactions" ? styles.activeSidebarBtn : ""}`} onClick={() => { this.setState({ activeNavBtnRoute: "/notifiactions" }); History.push("/notifiactions") }}>
                                        <IconLinkButton IconComponentType={ContactIcon}
                                            exact={true}
                                            activeClassName={styles["active"]}
                                            to={"/notifiactions"}
                                            className={styles["nav-link"]}
                                            onClick={this.onClickNavigationLink}
                                            iconClassName={styles["nav-link-icon"]}>
                                            OPPSLAGSTAVLE
                                        </IconLinkButton>
                                    </li>
                                    <li className={`${activeNavBtnRoute == "/brannbok" ? styles.activeSidebarBtn : ""}`} onClick={() => { this.setState({ activeNavBtnRoute: "/brannbok" }); History.push("/brannbok") }}>
                                        <IconLinkButton IconComponentType={ContactIcon}
                                            exact={true}
                                            activeClassName={styles["active"]}
                                            to={"/brannbok"}
                                            className={styles["nav-link"]}
                                            onClick={this.onClickNavigationLink}
                                            iconClassName={styles["nav-link-icon"]}>
                                            BRANNBOK
                                        </IconLinkButton>
                                    </li>
                                </>
                            )}
                        </ul>

                    </nav>
                </aside>
                <aside className={styles["right-column"]}>
                    <NotificationSidebar building={preferredBuilding}></NotificationSidebar>
                </aside>
            </div>
        )
    }
}

const DashboardLayoutContainer = (routeComponentProps: RouteComponentProps) => {
    const USER_DETAILS_QUERY = gql`
        query GetUserInfo {
            userInfo {
                username
                name
                roles
                contracts {
                    contractId
                    buildingId
                    name
                    validFrom
                    validTo
                    accountNum
                }
                companies {
                    id
                    name
                    imageId
                    buildings {
                        id
                        name
                        street
                        postalCode
                        city
                        contracts {
                            contractId
                            buildingId
                            name
                            validFrom
                            validTo
                            accountNum
                        }
                    }
                }
            }
        }
    `

    return (
        <Query query={USER_DETAILS_QUERY} partialRefetch={true}>
            {({ loading, error, data }) => {
                if (loading) return <CenteredLoadingIcon style={{ marginTop: "3.2rem" }} text={"Laster brukerinformasjon"} />
                if (error) return <span>Error :(</span>

                return (
                    <DashboardLayout userInfo={data.userInfo} {...routeComponentProps} />
                )
            }}
        </Query>
    )
}

export default DashboardLayoutContainer
