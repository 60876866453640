import * as React from "react";
import classNames from 'classnames';

import styles from "./LoadingIcon.module.scss";

const LoadingIcon = ({className = "", ...otherProps}: React.ComponentProps<"svg">) => (
    <svg viewBox="0 0 80 80"  width="80" height="80" xmlns="http://www.w3.org/2000/svg" {...otherProps} className={classNames(styles["loading-icon"], {[className]: !!className})}>
        <path d="M68.956 47.136c-3.03 13.28-14.92 23.228-29.113 23.228-13.462 0-24.863-8.946-28.588-21.2l8.668-8.664H.003v19.91l6.066-6.063C11.54 67.62 24.611 77 39.843 77c17.869 0 32.752-12.901 35.886-29.864h-6.773zM73.618 26.653C68.15 13.377 55.078 4 39.843 4 21.975 4 7.092 16.901 3.957 33.864h6.773c3.031-13.28 14.92-23.228 29.113-23.228 13.463 0 24.864 8.946 28.589 21.204l-8.669 8.66h19.92V20.59l-6.065 6.063z"/>
    </svg>
)

export default LoadingIcon