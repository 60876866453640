import * as React from "react";
import { RouteComponentProps } from "react-router";
import queryString from "query-string";
import FieldErrorMessage from "../../viewComponents/FieldErrorMessage/FieldErrorMessage";
import styles from "./EmailVerification.module.scss"
import { ConfirmationButton } from "../../viewComponents/Buttons/Buttons";
import { validatePassword } from "./Authentication";
import { NewPasswordParams, PASSWORD_NEW_PATH, PASSWORD_TOKEN_VALIDATE_PATH, useAuthApi } from "../../hooks/useAuthApi";
import TextField from "../../viewComponents/TextField/TextField";
import useEffectSkipMount from "../../hooks/useEffectSkipMount";
import { useEffect } from "react";

enum PasswordChangeStatus {
    UNSET,
    DONE,
    FAILED
}

const useValidateTokenOnMount = (username: string, token: string) => {
    const [hasValidationError, setHasValidationError] = React.useState<boolean>(false)

    const { isLoading: tokenValidationIsLoading, responseStatus: tokenValidationResponseStatus } = useAuthApi({
        callOnMount: true,
        params: {
            path: PASSWORD_TOKEN_VALIDATE_PATH,
            body: {
                username,
                token,
            }
        }
    })

    useEffect(() => {
        if (tokenValidationResponseStatus != 200) {
            setHasValidationError(true)
        } else {
            setHasValidationError(false)
        }
    }, [tokenValidationResponseStatus])

    return { tokenValidationIsLoading, hasValidationError }
}

const useSetNewPassword = () => {
    const { isLoading, responseStatus, doApiCall } = useAuthApi()

    const [passwordChangeStatus, setPasswordChangeStatus] = React.useState<PasswordChangeStatus>(PasswordChangeStatus.UNSET)
    const [newPasswordErrorMessage, setNewPasswordErrorMessage] = React.useState<string | undefined>(undefined)

    useEffectSkipMount(() => {
        if (responseStatus == 200) {
            setPasswordChangeStatus(PasswordChangeStatus.DONE)
        } else {
            setNewPasswordErrorMessage("Noe gikk galt ved tilbakestilling av passord, bekreftelseslenken kan være utgått")
            setPasswordChangeStatus(PasswordChangeStatus.FAILED)
        }
    }, [responseStatus])

    const doPost = (username: string, password: string, token: string) => {
        const requestBody: NewPasswordParams = {
            path: PASSWORD_NEW_PATH,
            body: {
                username,
                token,
                password
            }
        }

        doApiCall(requestBody)
    }

    return { isLoading, passwordChangeStatus, newPasswordErrorMessage, setNewPasswordErrorMessage, callSetNewPassword: doPost }
}

const SetNewPasswordVerification = (props: RouteComponentProps) => {

    const { k: token, u: username } = queryString.parse(props.location.search)

    const {
        tokenValidationIsLoading,
        hasValidationError
    } = useValidateTokenOnMount(`${username}`, `${token}`)

    const {
        isLoading,
        passwordChangeStatus,
        newPasswordErrorMessage,
        setNewPasswordErrorMessage,
        callSetNewPassword
    } = useSetNewPassword()

    const onSetNewPassword = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const formElements = event.currentTarget.elements

        const password = (formElements.namedItem("password") as HTMLInputElement).value
        const passwordConfirm = (formElements.namedItem("confirm_password") as HTMLInputElement).value
        const passwordValidationErrorMessage = validatePassword(password, passwordConfirm)

        if (passwordValidationErrorMessage) {
            setNewPasswordErrorMessage(passwordValidationErrorMessage)
        } else {
            callSetNewPassword(`${username}`, password, `${token}`)
        }
    }

    const showLoadingSpinner = isLoading || tokenValidationIsLoading

    if (passwordChangeStatus == PasswordChangeStatus.DONE) {
        return (
            <>
                <p>Nytt passord satt!</p>
                <ConfirmationButton onClick={() => props.history.push("/auth/login")} isLoading={showLoadingSpinner}>
                    Tilbake til innlogging
                </ConfirmationButton>
            </>
        )
    } else if (hasValidationError) {
        return (
            <>
                <FieldErrorMessage hidden={false} className={styles['error-message']}>
                    Bekreftelseslenken kan være utgått
                </FieldErrorMessage>

                <h1>Vi kunne ikke sette nytt passord</h1>

                <ConfirmationButton onClick={() => props.history.push("/auth/password/reset")} isLoading={showLoadingSpinner}>
                    Sett passord på nytt
                </ConfirmationButton>

                <p className={styles["helptext"]}>
                    Dersom problemet vedvarer ta kontakt med <strong><a href={"mailto:kundesenter@newsec.no."}>kundesenter@newsec.no</a></strong>.
                </p>
            </>
        )
    } else {
        return (
            <form className={styles['login-form']} onSubmit={onSetNewPassword}>
                {/* <h2 className={styles['login-form-header']}>
                    Sett nytt passord
                </h2> */}

                <TextField name="password"
                    className={styles['input-field']}
                    placeholder={"PASSORD"}
                    type="password" />
                <TextField name="confirm_password"
                    className={styles['input-field']}
                    placeholder={"BEKREFT PASSORD"}
                    type="password" />

                <FieldErrorMessage hidden={!newPasswordErrorMessage}>
                    {newPasswordErrorMessage}
                </FieldErrorMessage>

                <div className={styles["action-wrapper"]}>
                    <ConfirmationButton type="submit" className={styles['login-button']} isLoading={showLoadingSpinner}>
                        Ok
                    </ConfirmationButton>

                    {(passwordChangeStatus == PasswordChangeStatus.FAILED) && (
                        <div>
                            <ConfirmationButton className={styles['login-button']}
                                type="button"
                                onClick={() => props.history.push("/auth/password/reset")}
                                isLoading={showLoadingSpinner}>
                                Sett nytt passord på nytt
                        </ConfirmationButton>
                        </div>
                    )}
                </div>
            </form>
        )
    }
}

export default SetNewPasswordVerification