import gql from "graphql-tag";

export const ADD_COMMENT = gql`
    mutation UpdateIssue(
        $issueId: ID!
        $sender: String!
        $content: String!
        $imageIds: [ID!]
        $documentIds: [ID!]
    ) {
        updateIssue(issueId: $issueId, comment: {sender: $sender, content: $content, imageIds: $imageIds, documentIds: $documentIds}) {
            status
            message
        }
    }
`

export const SET_VIEWED = gql`
    mutation SetIssueViewed($issueId: ID!) {
        setIssueViewed(issueId: $issueId) {
            status
            message
        }
    }
`

export const COMPONENT_QUERY = gql`
    query ComponentQuery($id: ID!) {
        userInfo {
            username
            contracts {
                contractId
            }
        }
        issue(id: $id) {
            id
            title
            reportedDate
            finishedDate
            address
            floor
            room
            contractName
            priority
            status
            serviceDeskId
            contractId
            category {
                id
                name
                parentCategory {
                    id
                    name
                }
            }
            userInfo {
                name
            }
            comments {
                id
                issueId
                senderName
                senderUsername
                content
                created
                isSystemMessage
            }
            images {
                id
                commentId
                name
            }
            documents {
                id
                commentId
                name
            }
        }
    }
`

export const ISSUE_UPDATE_SUBSCRIPTION = gql`
    subscription IssueUpdates($contractId: String, $buildingId: String, $username: String!) {
        issueUpdates(contractId: $contractId, buildingId: $buildingId, username: $username) {
            type
            issue {
                id
                title
                reportedDate
                finishedDate
                address
                floor
                room
                contractName
                priority
                status
                serviceDeskId
                contractId
                category {
                    id
                    name
                    parentCategory {
                        id
                        name
                    }
                }
                userInfo {
                    name
                }
                comments {
                    id
                    issueId
                    senderName
                    senderUsername
                    content
                    created
                    isSystemMessage
                }
                images {
                    id
                    commentId
                    name
                }
                documents {
                    id
                    commentId
                    name
                }
            }
        }
    }
`