import * as React from 'react'
import styles from "../Dialog.module.scss";
import { DialogDateSpacer, DialogSystemMessage, DialogTextMessage } from "../DialogMessage/DialogMessage";
import { formatDateVerbose } from "../../../tools/formatters";
import { Message } from "../Dialog";
import { DateTime } from "luxon";
import SendIcon from "../../Icons/SendIcon";
import AttachmentPreviewList from "../../AttachmentPreviewList/AttachmentPreviewList";

interface Props {
    messages: Message[]
    lastDialogElementRef: React.RefObject<HTMLLIElement>
    issueId: string
}

const sortByDate = (a: Message, b: Message) => {
    return new Date(a.time).getTime() - new Date(b.time).getTime()
}

const isNewDate = (message: Message, index: number, messageArray: Message[]) => {
    if (index === 0) { return true }

    const thisDate = DateTime.fromISO(message.time)
    const previousDate = DateTime.fromISO(messageArray[index - 1].time)

    return !(
        thisDate.day === previousDate.day &&
        thisDate.month === previousDate.month &&
        thisDate.year === previousDate.year
    );
}

class DialogMessageList extends React.Component<Props> {
    render() {
        const { messages, lastDialogElementRef, issueId } = this.props

        if (messages.length === 0) {
            return (
                <div className={styles['dialog-list--empty']}>
                    <SendIcon />
                    <p>Start en samtale nå ved å skrive i feltet under.</p>
                </div>
            )
        }

        return (
            <ul className={styles['dialog-list']}>
                {[...messages].sort(sortByDate).map((message, index, thisArray) => {
                    const ref = index === messages.length - 1 ? lastDialogElementRef : null
                    const insertDateSpacer = isNewDate(message, index, thisArray)

                    let messageBody
                    if (message.isSystemMessage) {
                        messageBody = (
                            <DialogSystemMessage ref={ref}
                                time={message.time}>
                                {message.content}
                            </DialogSystemMessage>
                        )
                    } else {
                        messageBody = (
                            <DialogTextMessage ref={ref}
                                time={message.time}
                                sender={message.sender!}
                                sentByUser={message.sentByUser}>
                                <p>{message.content}</p>
                                <AttachmentPreviewList className={styles["attachments-list"]} invertedColors
                                    images={message.images.map(i => ({ id: i.id, name: i.name, issueId: issueId }))}
                                    documents={message.documents.map(i => ({ id: i.id, name: i.name, issueId: issueId }))} />
                            </DialogTextMessage>
                        )
                    }

                    return (
                        <React.Fragment key={index}>
                            {insertDateSpacer && (
                                <DialogDateSpacer>
                                    {formatDateVerbose(message.time)}
                                </DialogDateSpacer>
                            )}
                            {messageBody}
                        </React.Fragment>
                    )
                })}
            </ul>
        )
    }
}

export default DialogMessageList