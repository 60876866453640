export type ContractContact = {
    contractId: string,
    dataAreaId: string,
    email: string,
    name: string,
    phone: string,
    roleId: string
}

export type UpdateContractContact = {
    contact: {
        contractId: string,
        dataAreaId: string,
        email: string,
        name: string,
        phone: string,
        roleId: string
    },
    isNewPerson: boolean
}

export const getRoleDescription = (roleId: string): string | null => {
    if (roleId.toLowerCase() === "brann") return "Kontaktperson vedrørende brannøvelser o.l.";
    if (roleId.toLowerCase() === "kontrakt") return "Kontaktperson vedrørende leiekontraktens bestemmelser";
    if (roleId.toLowerCase() === "forvaltnin") return "Kontaktperson vedrørende den daglige driften av leieareal";
    if (roleId.toLowerCase() === "moms") return "Kontaktperson ved innhenting av Egenerklæring MVA";
    return null;
}

