import * as React from 'react'

import styles from './IssueCreatedConfirmation.module.scss'
import {IssueType} from "../../../types/Issue"
import IssueCard from "../../../viewComponents/IssueCard/IssueCard"
import {TagType} from "../../../types/TagType"
import {Link, useLocation} from "react-router-dom"

interface Props {
    issue: IssueType
}

class IssueCreatedConfirmation extends React.Component<Props> {
    render(): React.ReactNode {
        const { issue } = this.props

        return (
            <div className={styles["wrapper"]}>
                <header className={styles["confirmation-header"]}>
                    <h1>Henvendelsen er mottatt!</h1>
                    <p>
                        Innen kort tid vil du få et estimat på når vi får håndtert henvendelsen.
                    </p>
                </header>

                <div className={styles["issue-card-wrapper"]}>
                    <IssueCard cardType={"card"} data={issue} tagType={TagType.div}/>
                </div>

                <p className={styles["info-message"]}>
                    Du kan følge opp saken og legge til ny informasjon i Newsec Kundeportal.
                </p>

                <Link to={"/"} className={styles["dashboard-link-button"]}>Se alle saker</Link>
                <Link to={"/new-issue"} className={styles["register-new-link"]}>Registrer ny henvendelse</Link>
            </div>
        )
    }
}

export default (props: Props) => {
    const {
        state: {
            issue
        }
    } = useLocation<{issue: IssueType}>()

    const newIssueRepresentation = {
        id: "",
        title: issue.title,
        address: issue.address,
        floor: issue.floor,
        room: issue.room,
        priority: issue.priority,
        status: "NEW",
        reportedDate: new Date().toISOString(),
        comments: [],
        serviceDeskId: "",
    }

    return <IssueCreatedConfirmation issue={newIssueRepresentation} {...props} />
}
