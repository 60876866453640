import * as React from 'react'
import classNames from 'classnames'

import styles from './CardList.module.scss'

interface Props extends React.HTMLProps<HTMLUListElement>{
    children: React.ReactNode,
    isCollapsed?: boolean,
    className?: string
}

const CardList = (props: Props) => {
    const { children, isCollapsed = false, className = "" , ...rest} = props

    const classes = classNames(styles['card-list'], {
        [styles['card-list--collapsed']]: isCollapsed,
        [className]: !!className
    })

    return (
        <ul className={classes} {...rest}>
            {children}
        </ul>
    )
}

CardList.displayName = "CardList"

export default CardList