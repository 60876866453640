import * as React from "react";
import styles from "./IssueCard.module.scss";
import classNames from "classnames";

import {Card, CardProps, LinkCard} from "../Card/Cards";
import {IssueType} from "../../types/Issue";
import ArrowIcon from "../Icons/ArrowIcon";
import {formatIssueIdAndReportedDateString, formatLocationString, getFormattedStatusText} from "../../tools/formatters";
import {ReactComponent as EmailTimeoutIcon} from '../Icons/EmailTimeoutIcon.svg'
import {ReactComponent as FileAddIcon} from '../Icons/FileAddIcon.svg'
import {ReactComponent as EmailOpenIcon} from '../Icons/EmailOpenIcon.svg'
import {ReactComponent as MediumPriorityIcon} from '../Icons/MediumPriorityIcon.svg'

interface IssueCardBaseProps extends CardProps {
    data: IssueType
}

interface IssueLinkCard extends IssueCardBaseProps {
    cardType: 'link'
    to: string
}

interface IssueCard extends IssueCardBaseProps {
    cardType: 'card'
}

const StatusIcon = ({status}: { status: string | undefined }) => {
    if (!status) return null

    switch (status) {
        case "NEW": return <FileAddIcon className={styles["status-icon"]}/>

        case "ASSIGNED_TO_AGENT": return <EmailOpenIcon className={styles["status-icon"]}/>

        case "AWAITING_RESPONSE": // Intentional fallthrough
        case "WAITING_FOR_THIRD_PARTY": return <EmailTimeoutIcon className={styles["status-icon"]}/>

        case "SOLVED": // Intentional fallthrough
        case "CLOSED": // Intentional fallthrough
        default: return null
    }

}

const PriorityBody = ({priority}: {priority?: number}) => {
    let classes = classNames(styles['priority-icon'])
    switch (priority) {
        case 1:
            classes = classNames(classes, styles['priority-icon--high'])
            return (
                <><ArrowIcon className={classes}/> Høy</>
            )
        case 2:
            classes = classNames(classes, styles['priority-icon--medium'])
            return (
                <><MediumPriorityIcon className={classes}/> Medium</>
            )
        case 3:
            classes = classNames(classes, styles['priority-icon--low'])
            return (
                <><ArrowIcon className={classes}/> Lav</>
            )
        default:
            return null
    }
}

const IssueCardBody = (props: { data: IssueType }) => (
    <div className={styles['issue-card-section']}>
        <p className={styles['id']}>
            {formatIssueIdAndReportedDateString(props.data)}

        </p>
        <p className={styles['description']}>
            {props.data.title}
        </p>
        <p className={styles['location']}>
            {formatLocationString(props.data.address, props.data.floor, props.data.room)}
        </p>
        <p className={styles['priority']}>
            <PriorityBody priority={props.data.priority}/>
        </p>
        <p className={styles['status']}>
            <StatusIcon status={props.data.status}/> {getFormattedStatusText(props.data.status!)}
        </p>

        {props.data.updatesSinceLastViewed > 0 && (
            <p className={styles['update-count']}>
                {`${props.data.updatesSinceLastViewed} ${props.data.updatesSinceLastViewed > 1 ? "oppdateringer" : "oppdatering"}`}
            </p>
        )}
    </div>
)

const IssueCard = (props: IssueLinkCard | IssueCard) => {
    const {
        data,
        tagType,
        className = "",
        ...restProps
    } = props

    // Delete cardType before sending props down as HTML attributes
    const htmlAttributes = {...restProps}
    delete htmlAttributes.cardType

    if (props.cardType === 'link') {
        const linkClasses = classNames(styles['issue-card-body'], {
            [styles['issue-card-body--priority-high']]: data.priority === 1,
            [styles['issue-card-body--priority-medium']]: data.priority === 2,
            [styles['issue-card-body--priority-low']]: data.priority === 3,
            [styles['issue-card-body--new-updates']]: data.updatesSinceLastViewed > 0,
        })

        const { to } = props

        return (
            <LinkCard to={to}
                      tagType={tagType}
                      className={className}
                      linkClassName={linkClasses}
                      {...htmlAttributes}>
                <IssueCardBody data={data}/>
            </LinkCard>
        )
    }

    if (props.cardType === 'card') {
        const classes = classNames({
            [styles['issue-card-body--priority-high']]: data.priority === 1,
            [styles['issue-card-body--priority-medium']]: data.priority === 2,
            [styles['issue-card-body--priority-low']]: data.priority === 3,
            [styles['issue-card-body--new-updates']]: data.updatesSinceLastViewed > 0,
            [className]: !!className
        })
        return (
            <Card tagType={tagType}
                  className={classes}
                  {...htmlAttributes}>
                <IssueCardBody data={data}/>
            </Card>
        )
    }

    throw new Error('Unknow cardType')
}

IssueCard.displayName = "IssueCard"

export default IssueCard

