import * as React from 'react';
import { NotificationAttributes } from './Notification';
import styles from './Notification.module.scss'
import { ReactComponent as ArrowDown } from '../../viewComponents/Icons/newDesign/Arrow-down.svg';
import { ReactComponent as ArrowUp } from '../../viewComponents/Icons/newDesign/Arrow-up.svg';


export const NotificationBox = (props: { notificationData: NotificationAttributes }) => {
    const [readMore, setReadMore] = React.useState(false);
    const notificationData = props.notificationData;

    const extend = () => {
        if (!readMore) {
            setReadMore(true);
        }
        else {
            setReadMore(false);
        }
    }

    return (
        <div className={`${styles['item']} ${readMore == true ? styles['expand'] : ""}`} onClick={extend}>
            <p className={styles['sTitle']}>{notificationData['Title']}</p>
            <p className={styles['sPublisert']}>Melding publisert: {notificationData['PublishDate']}</p>
            <p className={styles['sTeaser']}>{notificationData['Teaser']}</p>
            {readMore && <div className={styles['sInnhold']} dangerouslySetInnerHTML={{ __html: notificationData['Description'] }}></div>}
            {!readMore && <div className={styles['hover']}><div>Åpne melding</div><ArrowDown className={styles['sSvg']}></ArrowDown></div>}
            {readMore && <div className={styles['hover']}><ArrowUp className={styles['sSvg']}></ArrowUp><div>Lukk melding</div></div>}
        </div>
    );
}

export const NotiBox = React.forwardRef<HTMLDivElement, any>((props, ref) => {
    const notificationData = props.props.notificationData;
    const view = props.props.view;
    const index = props.props.index;

    const toggle = () => {
        if (ref) {
            const notPanel = ref['current'];
            if (notPanel.clientHeight <= 100) {
                view(index)
            }
        }
    }
    const handleChildElementClick = (e) => {
        e.stopPropagation()
        view(index);
    }

    return (
        <div className={styles['panel']} ref={ref} onClick={toggle}>
            <p className={styles['title']}>{notificationData['Title']}</p>
            <p className={styles['teaser']}>{notificationData['Teaser']}</p>
            <div className={styles['description']} dangerouslySetInnerHTML={{ __html: notificationData['Description'] }}></div>
            <a className={styles['link']} onClick={() => { view(index) }}>Les hele Meldingen &gt;</a>
            <a className={styles['close'] + " " + styles['hide']} onClick={(e) => handleChildElementClick(e)}>&lt; Lukk Melding</a>
            <p className={styles['pubdate'] + " " + styles['hide']}>Melding publisert: {notificationData['PublishDate']}</p>
        </div>
    );
})
