import * as React from "react";

import { PASSWORD_RESET_PATH, ResetPasswordParams, useAuthApi } from "../../hooks/useAuthApi";
import useEffectSkipMount from "../../hooks/useEffectSkipMount";
import styles from "./Authentication.module.scss";
import TextField from "../../viewComponents/TextField/TextField";
import FieldErrorMessage from "../../viewComponents/FieldErrorMessage/FieldErrorMessage";
import { ConfirmationButton } from "../../viewComponents/Buttons/Buttons";
import { Link } from "react-router-dom";

const ResetPassword = () => {

    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined)
    const [isEmailSent, setIsEmailSent] = React.useState<boolean>(false)

    const { isLoading, responseStatus, doApiCall } = useAuthApi()
    useEffectSkipMount(() => {
        if (responseStatus == 200) {
            setIsEmailSent(true)
        } else if (responseStatus == 404) {
            setErrorMessage("Finner ikke bruker")
        } else {
            setErrorMessage("Det oppsto en feil ved forsøk på tilbakestilling av passord")
        }
    }, [responseStatus])

    const onResetPassword = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const formElements = event.currentTarget.elements

        const apiParams: ResetPasswordParams = {
            path: PASSWORD_RESET_PATH,
            body: (formElements.namedItem("username") as HTMLInputElement).value
        }
        doApiCall(apiParams)
    }

    if (isEmailSent) {
        return (
            <h1>E-post for oppretting av nytt passord sendt</h1>
        )
    } else {
        return (
            <form className={styles['login-form']} onSubmit={onResetPassword}>
                {/* <h2 className={styles['login-form-header']}>
                    SETT NYTT PASSORD
                </h2> */}

                <TextField name="username"
                    className={styles['input-field']}
                    placeholder={"E-POSTADRESSE"}
                    autoFocus
                    type="email" />


                <FieldErrorMessage hidden={!errorMessage}>
                    {errorMessage}
                </FieldErrorMessage>

                <div className={styles["action-wrapper"]}>
                    <ConfirmationButton type="submit" className={styles['login-button']} isLoading={isLoading}>
                        Ok
                    </ConfirmationButton>
                    <div>
                        <Link to={"/auth/login"} className={styles['link']}>TILBAKE</Link>
                    </div>
                </div>
            </form>
        )
    }
}

export default ResetPassword