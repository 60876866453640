import * as React from 'react'

const ContactIcon = (props: React.ComponentProps<"svg">) => (
    <svg width="18px" height="21px" viewBox="0 0 18 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
        <g transform="translate(-936.000000, -489.000000)">
            <g transform="translate(933.000000, 487.000000)">
                <g transform="translate(3.000000, 2.880000)">
                    <path d="M16.001,0 L4.001,0 C2.898,0 2.001,0.86016 2.001,1.92 L2.001,2.88 L4.001,2.88 L4.001,3.84 L0.001,3.84 L0.001,5.76 L2.001,5.76 L2.001,7.68 L4.001,7.68 L4.001,8.64 L0.001,8.64 L0.001,10.56 L2.001,10.56 L2.001,12.48 L4.001,12.48 L4.001,13.44 L0.001,13.44 L0.001,15.36 L2.001,15.36 L2.001,17.28 C2.001,18.33792 2.898,19.2 4.001,19.2 L16.001,19.2 C17.104,19.2 18.001,18.33792 18.001,17.28 L18.001,1.92 C18.001,0.86016 17.104,0 16.001,0 Z M10.001,5.75904 C11.105,5.75904 12.001,6.6192 12.001,7.67904 C12.001,8.73888 11.105,9.59904 10.001,9.59904 C8.897,9.59904 8.001,8.73888 8.001,7.67904 C8.001,6.6192 8.896,5.75904 10.001,5.75904 Z M7.001,13.44 C7.001,11.67264 8.16,10.56 10.001,10.56 C11.842,10.56 13.001,11.67264 13.001,13.44 L7.001,13.44 Z" id="Shape"/>
                </g>
            </g>
        </g>
    </svg>
)

export default ContactIcon