
import './polyfills'

import * as React from 'react';
import ReactDOM from 'react-dom';
// Polyfill for fetch


import history from './History'
import './index.scss';
import * as serviceWorker from './serviceWorker';
import { Redirect, Route, RouteComponentProps, Router, Switch } from 'react-router-dom'
import Authentication from "./features/Authentication/Authentication";
import AuthenticatedRoutes from "./routing/AuthenticatedRoutes";
import { ApolloProvider } from "react-apollo";
import getApolloClient from "./getApolloClient";
import './assets/root.scss';
import './assets/shared.scss';

const renderAuthRouts = (props: RouteComponentProps) => {
    if (localStorage.getItem("isAuthenticated") === "true") {
        return <Redirect to={"/"} />
    } else {
        return <Authentication />
    }
}

class Application extends React.Component {
    render(): React.ReactNode {
        return (
            <Router history={history}>
                <Switch>
                    <Route path="/auth/(login|register|validation|password)" render={renderAuthRouts} />
                    <ApolloProvider client={getApolloClient()}>
                        <Route component={AuthenticatedRoutes} />
                    </ApolloProvider>
                </Switch>
            </Router>
        )
    }
}

ReactDOM.render(<Application />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA