import * as React from 'react'
import classNames from 'classnames'

import styles from './ImageViewer.module.scss'
import {RouteComponentProps} from "react-router";
import {Query} from "react-apollo";
import {gql} from "apollo-boost";
import LoadingIcon from "../../viewComponents/Icons/LoadingIcon/LoadingIcon";
import {Image, IssueType} from "../../types/Issue";
import {Link} from "react-router-dom";

interface TextByImageIds {
    [key: string]: string
}

interface Props {
    images: Image[]
    textByImageId: TextByImageIds
    imageId: string
    issueId: string
    buildingId?: string
}

const getCloseUrl = (issueId: string, buildingId: string | undefined) => {
    let url = `/issue/${issueId}`
    if (!!buildingId) {
        url = `/building/${buildingId}/issues${url}`
    }
    return url
}

const ImageViewer = (props: Props) => {

    const {
        images, imageId, issueId, textByImageId, buildingId
    } = props

    return (
        <div className={styles["background"]}>
            <main className={styles["image-viewer"]}>
                <Link to={getCloseUrl(issueId, buildingId)} className={styles["close-link"]}>Lukk</Link>
                <div className={styles["viewed-image-wrapper"]}>
                    <img src={`/api/image/${imageId}`}
                         className={styles["viewed-image"]}
                         alt={`Image with name: ${images.find(i => i.id == imageId)!.name}`}/>
                    <p className={styles["image-text"]}>{textByImageId[imageId]}</p>
                </div>


                {images.length > 1 && (
                    <ul className={styles["images-list"]}>
                        {images.map(image => (
                            <li key={image.id}>
                                <Link className={classNames(styles["image-link"], {[styles["active"]]: imageId === image.id})} to={`/issue/${issueId}/image/${image.id}`} replace>
                                    <img
                                        className={styles["thumbnail"]}
                                        src={`/api/image/${image.id}`}
                                        alt={`Image with name: ${image.name}`}/>
                                </Link>
                            </li>
                        ))}
                    </ul>
                )}
            </main>
        </div>
    )
}


interface Params {
    imageId: string
    issueId: string
    buildingId?: string
}

const ImageViewerContainer = (props: RouteComponentProps<Params>) => {

    const QUERY = gql`
        query GetImages($issueId: ID!) {
            issue(id: $issueId) {
                comments {
                    id
                    content
                }
                images {
                    id
                    commentId
                    name
                }
            }
        }
    `

    const {
        match: {
            params: { issueId, imageId, buildingId }
        }
    } = props

    return (
        <Query query={QUERY} variables={{issueId: issueId}}>
            {({loading, error, data}) => {

                if (loading) return <LoadingIcon/>
                if (error) return <span>Feil ved lasting av bilder.</span>

                const issue: IssueType = data.issue
                const { images, comments } = issue

                const textByImageIds = images.reduce((result: TextByImageIds, current) => {
                    result[current.id] = comments.find(c => c.id == current.commentId)!.content
                    return result
                }, {})

                return <ImageViewer images={images}
                                    imageId={imageId}
                                    issueId={issueId}
                                    textByImageId={textByImageIds}
                                    buildingId={buildingId} />
            }}
        </Query>
    )
}

export default ImageViewerContainer