import * as React from 'react'

import styles from "./Header.module.scss";
import NotificationsPanel from "../NotificationsPanel/NotificationsPanel";
import { SecondaryButton } from "../../viewComponents/Buttons/Buttons";
import LogoutIcon from "../../viewComponents/Icons/LogoutIcon";
import { Building, Contract, UserInfo } from "../../types/UserInfo";
import { CompanyLogo } from "./CompanyLogo";
import BuildingAndContract from "./BuildingAndContract";
import { FormEvent } from "react";

interface Props {
    className?: string
    userInfo: UserInfo
    preferredBuilding: Building
    preferredContract: Contract
    onPreferredBuildingChange: (event?: FormEvent<HTMLSelectElement>, value?: any) => void
    onPreferredContractChange: (event: FormEvent<HTMLSelectElement>) => void
}

class Header extends React.Component<Props> {

    logout = () => {
        fetch('/api/auth/logout', {
            method: 'POST',
            headers: {
                'Content-Type': "application/json"
            },
            body: JSON.stringify({})
        })
            .then((result: Response) => {
                if (result.ok) {
                    localStorage.setItem("isAuthenticated", "false");
                    window.location.href = "/"
                }

            })
    }


    render(): React.ReactNode {
        const { userInfo } = this.props
        const companyWithImage = userInfo.companies.find(company => !!company.imageId);

        return (
            <header className={styles["main-header"]}>

                <>
                    {!!companyWithImage && (
                        <CompanyLogo company={companyWithImage} className={styles["company-logo"]} />
                    )}
                    <span className={styles["building-name"]}>
                        {!userInfo.roles.includes("OWNER") && (<BuildingAndContract {...this.props} />)}
                    </span>
                    <div className={styles["user-context"]}>
                        <NotificationsPanel className={styles["notification-panel"]} userInfo={userInfo} />
                        <SecondaryButton
                            className={styles["logout-button"]}
                            IconComponent={LogoutIcon}
                            onClick={this.logout}
                        />
                    </div>
                </>
            </header>
        )
    }
}

export default Header
