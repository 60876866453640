import * as React from "react";
import { Document } from "../../types/Document";
import { sortDates, sortStrings } from "../../tools/sorters";
import { TableSort } from "../../viewComponents/TableSort/TableSort";
import { formatDateSimple } from "../../tools/formatters";
import Table from "../../viewComponents/Table/Table";
import styles from "./DocumentsTable.module.scss";
import FilterButton from "../../viewComponents/FilterButton/FilterButton";
import { ReactComponent as SortUp } from "../../viewComponents/Icons/newDesign/Sorting-arrow-up.svg"
import { ReactComponent as SortDown } from "../../viewComponents/Icons/newDesign/Sorting-arrow-down.svg"

interface DocumentType {
    docType: string
    count: number
}

interface DocumentsTableProps {
    isBuildingOwner: boolean,
    documents: Document[],
    title?: string
}

interface DocumentsState {
    sortBy: string,
    reverseSort: boolean,
    docTypes: DocumentType[],
    filter: {
        [key: string]: boolean
    },
    activeDocTypes: number
}
const EmptyState = () => (
    <article className={styles['empty-list']}>
        <header>
            <p>Ingen dokumenter</p>
        </header>
        <p>Denne seksjonen vil inneholde alle dokumenter.</p>
    </article>
)
class DocumentsTable extends React.Component<DocumentsTableProps, DocumentsState> {

    getUniqueDocumentTypes = (): DocumentType[] => {
        return this.props.documents.reduce((result: DocumentType[], currentDocument: Document) => {
            const indexOfDoctype = result.findIndex(docType => docType.docType == currentDocument.documentType.name)
            const docTypeExistsInArray = indexOfDoctype != -1
            if (!docTypeExistsInArray) {
                result.push({
                    docType: currentDocument.documentType.name,
                    count: 1
                })
            } else {
                result[indexOfDoctype].count++
            }

            return result
        }, [])
    }

    defaultFilterName = (): string => {
        return "Leietakerinformasjon";
    }

    shouldDisplayDefaultFilter = (): boolean => {
        if (this.props.isBuildingOwner) {
            return false;
        }

        return this.getUniqueDocumentTypes().some(type => type.docType === this.defaultFilterName());
    }

    getDefaultDocumentTypeFilter = () => {
        if (this.shouldDisplayDefaultFilter()) {
            return {
                [this.defaultFilterName()]: true
            }
        } else {
            return {};
        }
    }

    getDefaultActiveDocTypes = (): number => {
        return this.shouldDisplayDefaultFilter() ? 1 : 0;
    }

    state: DocumentsState = {
        sortBy: "lastModified",
        reverseSort: false,
        docTypes: this.getUniqueDocumentTypes(),
        filter: this.getDefaultDocumentTypeFilter(),
        activeDocTypes: this.getDefaultActiveDocTypes()
    }

    resetFilter = () => {
        this.setState({ filter: {}, activeDocTypes: 0 });
    }

    changeSort = (sortBy: string) => {
        this.setState(this.state.sortBy !== sortBy ? { sortBy, reverseSort: false } : {
            sortBy,
            reverseSort: !this.state.reverseSort
        })
    }

    modifyFilter = (event: React.FormEvent<HTMLInputElement>) => {
        let docType = event.currentTarget.value;

        let newState = Object.assign({}, this.state)

        if (this.state.filter[docType] !== undefined) {
            newState.filter[docType] = !this.state.filter[docType]
            newState.filter[docType] ? newState.activeDocTypes++ : newState.activeDocTypes--;
        } else {
            newState.filter[docType] = true
            newState.activeDocTypes++;
        }

        this.setState(newState);
    }

    documentsFilter = (document: Document) => this.state.filter[document.documentType.name] || this.state.activeDocTypes === 0

    render(): React.ReactNode {
        const { isBuildingOwner, documents } = this.props
        const { sortBy } = this.state;
        let sortFn: (a: Document, b: Document) => number = (a, b) => 0

        if (documents.length == 0) {
            return <EmptyState />
        }

        switch (this.state.sortBy) {
            case "name":
                sortFn = (a: Document, b: Document) => !this.state.reverseSort ? sortStrings(a.name, b.name) : sortStrings(b.name, a.name)
                break
            case "tenantName":
                sortFn = (a: Document, b: Document) => !this.state.reverseSort ? sortStrings(a.tenantName, b.tenantName) : sortStrings(b.tenantName, a.tenantName)
                break
            case "contractId":
                sortFn = (a: Document, b: Document) => !this.state.reverseSort ? sortStrings(a.contractId, b.contractId) : sortStrings(b.contractId, a.contractId)
                break
            case "documentType":
                sortFn = (a: Document, b: Document) => !this.state.reverseSort ? sortStrings(a.documentType.name, b.documentType.name) : sortStrings(b.documentType.name, a.documentType.name)
                break
            default:
                sortFn = (a: Document, b: Document) => !this.state.reverseSort ? sortDates(new Date(b.lastModified), new Date(a.lastModified)) : sortDates(new Date(a.lastModified), new Date(b.lastModified))
        }

        return (
            <>
                <section className={styles["filter-options"]}>
                    <span className='filtersHeader'>Filter</span>
                    <div className="filterCatWrapper">
                        <span className="filterCatLabel">Kategori</span>
                        <div className={styles["filterChoiceWrapper"]}>
                            <FilterButton
                                className={styles["filter-option"]}
                                id={"reset_doctypes"}
                                value={"reset_doctypes"}
                                checked={this.state.activeDocTypes === 0}
                                name={"reset_doctype"}
                                onClick={this.resetFilter}>
                                {`Alle (${this.props.documents.length})`}
                            </FilterButton>
                            {this.state.docTypes.map(({ docType, count }, index) => (
                                <FilterButton id={docType} className={styles["filter-option"]} key={index}
                                    value={docType}
                                    name={docType}
                                    onClick={this.modifyFilter}
                                    checked={this.state.filter[docType] !== undefined && this.state.filter[docType] === true}>
                                    {`${docType} (${count})`}
                                </FilterButton>
                            ))}
                        </div>
                    </div>
                </section>
                <h2 className="headerBold40">{this.props.title || "DOKUMENTER"}</h2>
                <span className="sortBtnsWrapper">
                    <span style={{ float: "right" }}>
                        <span className="filterCatLabel">Sorter etter</span>
                        <span className={`filterBtn ${sortBy == "name" ? "filterBtnActive" : ""}`} onClick={() => this.changeSort("name")}>Navn {this.state.sortBy === "name" && this.state.reverseSort ? <SortUp /> : <SortDown />}</span>
                        <span className={`filterBtn ${sortBy == "documentType" ? "filterBtnActive" : ""}`} onClick={() => this.changeSort("documentType")}>Gruppe {this.state.sortBy === "documentType" && this.state.reverseSort ? <SortUp /> : <SortDown />}</span>
                        <span className={`filterBtn ${sortBy == "lastModified" ? "filterBtnActive" : ""}`} onClick={() => this.changeSort("lastModified")}>Sist oppdatert {this.state.sortBy === "lastModified" && this.state.reverseSort ? <SortDown /> : <SortUp />}</span>
                    </span>
                </span>
                <Table className={styles["documentsTable"]}>
                    {/* <thead>
                        <tr>
                            <th><TableSort active={this.state.sortBy === "name"}
                                reverse={this.state.sortBy === "name" && this.state.reverseSort}
                                onClick={() => this.changeSort("name")}>Navn</TableSort></th>
                            {isBuildingOwner && (
                                <>
                                    <th><TableSort active={this.state.sortBy === "tenantName"}
                                        reverse={this.state.sortBy === "tenantName" && this.state.reverseSort}
                                        onClick={() => this.changeSort("tenantName")}>Leietaker</TableSort></th>
                                    <th><TableSort active={this.state.sortBy === "contractId"}
                                        reverse={this.state.sortBy === "contractId" && this.state.reverseSort}
                                        onClick={() => this.changeSort("contractId")}>Kontrakt</TableSort></th>
                                </>
                            )}
                            <th><TableSort active={this.state.sortBy === "documentType"}
                                reverse={this.state.sortBy === "documentType" && this.state.reverseSort}
                                onClick={() => this.changeSort("documentType")}>Type</TableSort></th>
                            <th><TableSort active={this.state.sortBy === "lastModified"}
                                reverse={this.state.sortBy === "lastModified" && this.state.reverseSort}
                                onClick={() => this.changeSort("lastModified")}>Sist oppdatert</TableSort></th>
                        </tr>
                    </thead> */}
                    <tbody>
                        {[...documents].sort(sortFn).filter(this.documentsFilter).map((doc, index) => (
                            <tr key={index} className="dataRow">
                                <td><a href={"/api/document?fileUrl=" + doc.fileUrl}>{doc.name || doc.fileName}</a></td>
                                {isBuildingOwner && (
                                    <>
                                        <td>{doc.tenantName}</td>
                                        <td>{doc.contractId}</td>
                                    </>
                                )}
                                <td><a href={"/api/document?fileUrl=" + doc.fileUrl}>{doc.documentType.name}</a></td>
                                <td><a href={"/api/document?fileUrl=" + doc.fileUrl}>{formatDateSimple(doc.lastModified)}</a></td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </>
        )
    }
}

export default DocumentsTable