import * as React from "react";
import classNames from "classnames";

import styles from "../Dialog.module.scss";
import { getFormattedTimeFromDate } from "../../../tools/formatters";
import { ReactComponent as CustomerHead } from "../../../viewComponents/Icons/newDesign/Customer-user.svg"
import { ReactComponent as NewsecHead } from "../../../viewComponents/Icons/newDesign/Newsec-user.svg"

interface MessageProps {
    time: string
    sender: string
    sentByUser: boolean
    children: React.ReactNode
}

export const DialogTextMessage = React.forwardRef<HTMLLIElement, MessageProps>((props, ref) => {
    const { time, sender, children, sentByUser } = props

    const wrapperClass = sentByUser ? styles['message-by-user-wrapper'] : styles['message-by-counterpart-wrapper']


    const dialogBubbleClasses = classNames(styles['dialog-bubble'], {
        [styles['dialog-bubble--by-counterpart']]: !sentByUser
    })

    return (
        <li className={wrapperClass} ref={ref}>
            <section className={dialogBubbleClasses}>
                {/* <p className={styles['message-sender']}>{sender}</p> */}
                {children}
            </section>
            <span className={styles['message-time']}>{getFormattedTimeFromDate(time)}</span>
            <span className={styles.userHeadWrapper}>
                {sentByUser && <CustomerHead className={styles.userHeadSVG}></CustomerHead>}
                {!sentByUser && <NewsecHead className={styles.userHeadSVG}></NewsecHead>}
                <span className={styles.userHeadName}>{sender}</span>
            </span>
        </li>
    )
})

export const DialogDateSpacer = ({ children }: { children: React.ReactNode }) => (
    <li className={styles['date-spacer']}>
        {children}
    </li>
)

export const DialogSystemMessage =
    React.forwardRef<HTMLLIElement, { time: string, children: React.ReactNode }>((props, ref) => {
        const { time, children } = props
        return (
            <li className={styles['system-message']} ref={ref}>
                <div className={styles['system-message-content']}>
                    <span className={styles['system-message-time']}>{getFormattedTimeFromDate(time)}</span>
                    <span>{children}</span>
                </div>
            </li>
        )
    })
