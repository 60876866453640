import * as React from 'react'

const ArrowIcon = ({className = "", ...props}: React.ComponentProps<"svg">) => (
    <svg viewBox="0 0 24 18" width="24" height="18" xmlns="http://www.w3.org/2000/svg" className={className} {...props}>
        <path
            d="M.1 8.535L8.518.098A.352.352 0 0 1 8.763 0h5.569c.139 0 .263.083.316.212a.35.35 0 0 1-.073.374L8.75 6.411h14.908c.188 0 .342.152.342.345v4.084c0 .19-.154.345-.342.345H8.793l5.793 5.793a.341.341 0 1 1-.244.584l-5.58-.01a.352.352 0 0 1-.243-.1L.099 9.017a.34.34 0 0 1 0-.481"/>
    </svg>
)

ArrowIcon.displayName = "ArrowIcon"

export default ArrowIcon