import * as React from "react";

const ValidationInfo = () => (
    <>
        <h1>Bekreft at det er deg</h1>
        <p>For å fullføre registreingen må du bekrefte e-postadressen din.</p>
        <p>Klikk <strong>Bekreft</strong> i e-posten som er sendt til deg.</p>
    </>
)

export default ValidationInfo