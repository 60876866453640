import { DateTime } from 'luxon'

import { IssueType } from "../types/Issue";
import { IssueCategory } from '../types/IssueCategory';

export const formatIssueIdAndReportedDateString = (issue: IssueType): string => {
    const formattedReportedDate = DateTime
        .fromISO(issue.reportedDate!, { locale: "nb" })
        .toLocaleString(DateTime.DATE_SHORT)

    const firstPart = issue.serviceDeskId ? `#${issue.serviceDeskId} - ` : ""
    let reporterPart = ""
    if (!!issue.userInfo && !!issue.userInfo.name) {
        reporterPart = ` av ${issue.userInfo.name}`
    }

    const contractPart = issue.contractName ? `, ${issue.contractName}` : ""

    return `Innmeldt ${formattedReportedDate}${reporterPart}${contractPart}`
}

export const formatIssueReportedDateString = (issue: IssueType): string => {
    const formattedReportedDate = DateTime
        .fromISO(issue.reportedDate!, { locale: "nb" })
        .toLocaleString(DateTime.DATE_SHORT)

    let reporterPart = ""
    if (!!issue.userInfo && !!issue.userInfo.name) {
        reporterPart = ` av ${issue.userInfo.name}`
    }

    const contractPart = issue.contractName ? `, ${issue.contractName}` : ""

    return `Innmeldt ${formattedReportedDate}${reporterPart}${contractPart}`
}

export const formatIssueReportedDateOnlyString = (issue: IssueType): string => {
    const formattedReportedDate = DateTime
        .fromISO(issue.reportedDate!, { locale: "nb" })
        .toLocaleString(DateTime.DATE_SHORT)

    return `Innmeldt ${formattedReportedDate}`
}

export const formatIssueReportedDateStringCompact = (issue: IssueType): string => {
    const formattedReportedDate = DateTime
        .fromISO(issue.reportedDate!, { locale: "nb" })
        .toLocaleString(DateTime.DATE_SHORT)
    return `${formattedReportedDate}`
}

export const formatDateVerbose = (ISODateString: string | undefined) => {
    if (!!ISODateString) {
        return DateTime
            .fromISO(ISODateString, { locale: "nb" })
            .toLocaleString(DateTime.DATE_HUGE)
    } else {
        return ""
    }
}

export const formatDateSimple = (ISODateString: string | undefined) => {
    if (!!ISODateString) {
        return DateTime
            .fromISO(ISODateString, { locale: "nb" })
            .toLocaleString(DateTime.DATE_SHORT)
    } else {
        return ""
    }
}

export const getFormattedTimeFromDate = (ISODateString: string) => {
    return DateTime
        .fromISO(ISODateString, { locale: "nb" })
        .toLocaleString(DateTime.TIME_24_SIMPLE)
}

export const getFormattedStatusText = (status: string) => {
    switch (status) {
        case "NEW": return "Ny"
        case "ASSIGNED_TO_AGENT": return "Åpen"
        case "AWAITING_RESPONSE": return "Trenger svar"
        case "WAITING_FOR_THIRD_PARTY": return "Venter på 3. part"
        case "SOLVED": return "Løst"
        case "CLOSED": return "Lukket"
        default: return ""
    }
}

export const getFormattedCategory = (category?: IssueCategory) => {
    if (category === undefined || category === null) return "Ikke kategorisert"
    // Has parent category
    if (category.parentCategory !== undefined && category.parentCategory != null) {
        return `${category.parentCategory.name} - ${category.name}`;
    }
    // Has children categories
    let categoryString = category.name;
    if (category.subCategories !== undefined && category.subCategories.length > 0) {
        categoryString += " - " + category.subCategories.join("/")
    }
    return categoryString;
}

export const formatLocationString = (address: string | undefined, floor: string | undefined, room: string | undefined) => {
    if (!address && !floor && !room) return ""

    let temp: string[] = []
    if (!!address) temp = [...temp, address]
    if (!!floor) temp = [...temp, `Etasje: ${floor}`]
    if (!!room) temp = [...temp, `Rom: ${room}`]

    return temp.join(", ")
}