import * as React from 'react'
import classNames from 'classnames'

import styles from './FilterButton.module.scss'

interface Props {
    id: string,
    children?: string,
    value: string,
    checked: boolean,
    name: string,
    className?: string,
    onClick(event: React.FormEvent<HTMLInputElement>): void
}

const FilterButton = (props: Props) => {
    const { id, children, value, checked, name, className = "", onClick } = props

    const wrapperClasses = classNames(styles['label-wrapper'], {
        [className]: !!className
    })

    return (
        <div className={wrapperClasses}>
            <input id={id}
                   name={name}
                   type="radio"
                   value={value}
                   checked={checked}
                   readOnly={true}
                   onClick={onClick}
                   className={styles['input']}/>
            <label htmlFor={id} className={styles['label']}>
                {children}
            </label>
        </div>
    )
}
export default FilterButton