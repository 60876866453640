import * as React from 'react'
import { Link, LinkProps } from "react-router-dom";

import styles from './PrimaryButton.module.scss'

export const PrimaryButton = ({ children, className = "", disabled = false, ...buttonProps }: React.ComponentProps<"button">) => (
    <button {...buttonProps} className={`${styles["button"]} ${className}`} disabled={disabled} aria-disabled={disabled}>
        {children}
    </button>
)


interface PrimaryButtonProps extends LinkProps {
    disabled?: boolean
}

const onClickLink = (event: React.MouseEvent<HTMLAnchorElement>, disabled: boolean) => {
    if (disabled) event.preventDefault()
}

export const PrimaryLinkButton = ({ children, className = "", disabled = false, ...linkProps }: PrimaryButtonProps) => (
    <Link {...linkProps}
        className={`${styles["button"]} ${className}`}
        aria-disabled={disabled}
    //   onClick={e => onClickLink(e, disabled)}
    >

        {children}
    </Link>
)