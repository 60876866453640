import * as React from "react"
import * as H from 'history';
import styles from "./NewIssue.module.scss";
import { Mutation, MutationFn, Query } from "react-apollo";
import { gql } from "apollo-boost";
import { IssueInput } from "../../types/Issue";
import ImageUpload, { IFileUpload } from "../ImageUpload/ImageUpload";
import ChooseCategory from "./ChooseCategory/ChooseCategory";
import { IssueCategory } from "../../types/IssueCategory";
import DocumentUpload from "../DocumentUpload/DocumentUpload";
import { ReactComponent as Stepper1 } from "../../viewComponents/Icons/newDesign/Stepper-1.svg";
import { ReactComponent as Stepper2 } from "../../viewComponents/Icons/newDesign/Stepper 2.svg";
import { ReactComponent as Stepper3 } from "../../viewComponents/Icons/newDesign/Stepper 3.svg";
import { ReactComponent as StepperDone } from "../../viewComponents/Icons/newDesign/Stepper done.svg";
import { ReactComponent as Dots } from "../../viewComponents/Icons/newDesign/Stepper-dots.svg";
import history from '../../History'
import { userIsOwner, buildingsForUser, buildingFromBuildingId, contractFromContractId } from "../../tools/userUtils";
import SelectInput from "../../viewComponents/SelectInput/SelectInput";
import { UserInfo, Building, Contract } from "../../types/UserInfo";

const ServiceCard = (props: { index, spToken, history, serviceSelectedCallback }) => {

    const onClickGoToOrder = () => {

    }

    return <div className="" key={props.index} >
        <div className="bigCard" key={props.index} >
            <div className="imageWrapper" >

            </div>
            <span className="cardTitle" >  </span>
        </div>
        <span > </span>
    </div>
}

export const NewIssue = (props: { preferredBuilding, userInfo, preferredContract }) => {
    const [page, setPage] = React.useState(1);
    const [lastValidPage, setLastValidPage] = React.useState(1);
    const [desc, setDesc] = React.useState("")
    const [title, setTitle] = React.useState("")
    const [floor, setFloor] = React.useState("")
    const [room, setRoom] = React.useState("")
    const [imageAttachments, setImageAttachments] = React.useState<IFileUpload[]>([])
    const [documentAttachments, setDocumentAttachments] = React.useState<IFileUpload[]>([])
    const [showFloor, setShowFloor] = React.useState(false)
    const [showRoom, setShowRoom] = React.useState(false)
    const [requireFloor, setRequireFloor] = React.useState(false)
    const [requireRoom, setRequireRoom] = React.useState(false)
    const [validationActive, setValidationActive] = React.useState(false)
    const [isSubmittingForm, setIsSubmittingForm] = React.useState(false);
    const [topCat, setTopCat] = React.useState<IssueCategory>()
    const [subCat, setSubCat] = React.useState<IssueCategory>()
    const [chosenBuilding, setChosenBuilding] = React.useState<Building>(buildingsForUser(props.userInfo)[0])
    const [chosenContract, setChosenContract] = React.useState<Contract | null>();
    const [contractDropDownOptions, setContractDropDownOptions] = React.useState<any>([])


    //validation messages
    const [floorMessage, setFloorMessage] = React.useState("")
    const [roomMessage, setRoomMessage] = React.useState("")
    const [titleMessage, setTitleMessage] = React.useState("")
    const [descMessage, setDescMessage] = React.useState("")

    const resetState = () => {
        setPage(1);
        setLastValidPage(1)
        setDesc("")
        setTitle("")
        setFloor("")
        setRoom("")
        setImageAttachments([])
        setDocumentAttachments([])
        setShowRoom(false)
        setShowFloor(false)
        setRequireFloor(false)
        setRequireRoom(false)
        setTopCat(undefined)
        setSubCat(undefined)
        setValidationActive(false)
        setFloorMessage("")
        setRoomMessage("")
        setTitleMessage("")
        setDescMessage("")
        setChosenBuilding(buildingsForUser(props.userInfo)[0])
        setChosenContract(null)
    }

    const onSubmitForm = (createIssue: MutationFn) => {
        let newIssue: IssueInput = {
            title: title,
            buildingId: userIsOwner(props.userInfo) ? chosenBuilding.id : props.preferredBuilding.id,
            floor: floor,
            room: room,
            priority: 2,
            category: subCat ? subCat.id : (topCat ? topCat.id : ""),
            descriptionComment: {
                sender: props.userInfo.username,
                content: desc,
                imageIds: imageAttachments.map(f => f.id),
                documentIds: documentAttachments.map(d => d.id),
            },
            isService: false,
            contractId: userIsOwner(props.userInfo) ? (chosenContract && chosenContract.contractId) : props.preferredContract.contractId
        }

        createIssue({
            variables: newIssue
        }).then((result) => {
            console.log("issue created", result)
            setPage(4)
        })
    }

    const catSelectionCallback = (topCat: IssueCategory, subCat) => {
        setTopCat(topCat);
        if (subCat) {
            setSubCat(subCat)
            setShowFloor(shouldShowField("floor", subCat))
            setShowRoom(shouldShowField("room", subCat))
            setRequireFloor(shouldRequireField("floor", subCat))
            setRequireRoom(shouldRequireField("room", subCat))
        } else {
            setShowFloor(shouldShowField("floor", topCat))
            setShowRoom(shouldShowField("room", topCat))
            setRequireFloor(shouldRequireField("floor", topCat))
            setRequireRoom(shouldRequireField("room", topCat))
        }
        if (subCat || !topCat.subCategories || (topCat.subCategories.length < 1)) {
            setPage(2)
        }
    }

    const getFieldFromCategoryVisibility = (fieldName: string, cat: IssueCategory) => {
        return cat.fields.find(field => field.fieldId == fieldName)
    }

    const shouldRequireField = (fieldName: string, cat: IssueCategory): boolean => {
        const field = getFieldFromCategoryVisibility(fieldName, cat)
        if (!field) {
            return true
        } else {
            return !["HIDDEN", "OPTIONAL"].includes(field.value)
        }
    }

    const shouldShowField = (fieldName: string, cat: IssueCategory): boolean => {
        const field = getFieldFromCategoryVisibility(fieldName, cat)
        if (!field) {
            return true
        } else {
            return field.value !== "HIDDEN"
        }
    }

    const validateForm = () => {
        let floorInvalid = requireFloor && floor.length < 1
        let roomInvalid = requireRoom && room.length < 1
        let titleInvalid = title.length < 1
        let descInvalid = desc.length < 1
        if (floorInvalid) {
            setFloorMessage("Dette feltet er ikke utfylt korrekt!")
        } else { setFloorMessage("") }
        if (roomInvalid) {
            setRoomMessage("Dette feltet er ikke utfylt korrekt!")
        } else { setRoomMessage("") }
        if (titleInvalid) {
            setTitleMessage("Dette feltet er ikke utfylt korrekt!")
        } else { setTitleMessage("") }
        if (descInvalid) {
            setDescMessage("Dette feltet er ikke utfylt korrekt!")
        } else { setDescMessage("") }
        if (floorInvalid || roomInvalid || titleInvalid || descInvalid) {
            return false
        } else {
            return true
        }
    }

    React.useEffect(() => {
        if (validationActive) {
            validateForm()
        }
    }, [room, floor, title, desc, validationActive])

    const buildingDropdownContent = buildingsForUser(props.userInfo).map(building => {
        return { id: building.id, displayValue: building.street }
    })

    const onBuildingChange = (event: React.FormEvent<HTMLSelectElement>, userInfo: UserInfo) => {
        setChosenBuilding(buildingFromBuildingId(event.currentTarget.value, userInfo))
        setChosenContract(null)
    }

    const onContractChange = (event: React.FormEvent<HTMLSelectElement>, userInfo: UserInfo) => {
        setChosenContract(contractFromContractId(event.currentTarget.value, chosenBuilding.id, userInfo))
    }

    React.useEffect(() => {
        let vals = chosenBuilding.contracts.filter(contract => {
            let contractToDate = new Date(contract.validTo);
            let contractFromDate = new Date(contract.validFrom);
            let today = new Date();
            if (contractToDate > today && contractFromDate < today) {
                return true
            }
            return false;
        }).map(contract => {
            return { id: contract.contractId, displayValue: contract.name }
        })
        setContractDropDownOptions(vals)
    }, [chosenBuilding])

    return <Mutation mutation={CREATE_ISSUE_MUTATION} ignoreResults={true} >
        {(createIssue) => (
            <div className={styles.servicesWrapper} > {
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm10 ms-md10 ms-lg10">
                        {
                            page == 1 && <div>
                                <div className={styles.page1BodyWrapper} >
                                    <ChooseCategory userInfo={props.userInfo} selectionCallBack={catSelectionCallback} />
                                </div>
                            </div>
                        }
                        {
                            page == 2 && <div className={styles.page2Wrapper}>
                                <span className="filterBtn">{topCat && topCat.name}</span>
                                {subCat && <span>
                                    <span className={styles.biggerThanSign}> &gt; </span>
                                    <span className="filterBtn"> {subCat && subCat.name}</span>
                                </span>}
                                <span className={styles.page2Title}> Hvor ? </span>


                                {userIsOwner(props.userInfo) && (
                                    <div className={styles['flex-left-column']}>
                                        <div className={styles['select-dropdown']}>
                                            <label htmlFor={"building-select"} className={styles.formLabel}>Bygg</label>
                                            <SelectInput name={"building-id"}
                                                id={"building-select"}
                                                optionsWithId={buildingDropdownContent}
                                                onChange={(e) => onBuildingChange(e, props.userInfo)}
                                                className="fullWidth"
                                                selectedValueId={chosenBuilding ? chosenBuilding.id.toString() : undefined} />
                                        </div>

                                        <div className={styles['select-dropdown']}>
                                            <label htmlFor={"contract-select"} className={styles.formLabel}>Leieforhold (valgfritt)</label>
                                            <SelectInput name={"contract-id"}
                                                id={"contract-select"}
                                                optionsWithId={contractDropDownOptions}
                                                includeBlankOption={true}
                                                className="fullWidth"
                                                onChange={(e) => onContractChange(e, props.userInfo)}
                                                selectedValueId={chosenContract ? chosenContract.contractId : undefined} />
                                        </div>
                                    </div>
                                )}

                                {!userIsOwner(props.userInfo) && <>
                                    <span className={styles.formLabel} > Bygning * </span>
                                    <input className={styles.singleInput} type="text" disabled value={props.preferredBuilding ? props.preferredBuilding["name"] : ""} onChange={() => { }} />
                                </>}
                                {showFloor && <span className={styles.wrapper50}>
                                    <span className={styles.formLabel} > Etasje {requireFloor ? "*" : ""}</span>
                                    <input className={styles.singleInput} value={floor} onChange={(floor) => {
                                        setFloor(floor.target.value);
                                    }}></input>
                                    <span className={`${styles.validationMessageWrapper} validationError`}>{floorMessage}</span>
                                </span>}
                                {showRoom && <span className={styles.wrapper50MarginLeft}>
                                    <span className={styles.formLabel} > Rom {requireRoom ? "*" : ""}</span>
                                    <input className={styles.singleInput} value={room} onChange={(room) => {
                                        setRoom(room.target.value);
                                    }}></input>
                                    <span className={`${styles.validationMessageWrapper} validationError`}>{roomMessage}</span>
                                </span>}

                                <span className={styles.page2Title}> Tilleggsinfo </span>
                                <span className={styles.page2Address} >
                                    {userIsOwner(props.userInfo) && chosenBuilding && chosenBuilding.street}
                                    {(!userIsOwner(props.userInfo) && props.preferredBuilding) ? props.preferredBuilding["name"] : ""}
                                </span>
                                <span className={styles.formLabel} > Overskrift *</span>
                                <input className={styles.singleInput} value={title} onChange={(title) => {
                                    setTitle(title.target.value);
                                }}></input>
                                <span className={`${styles.validationMessageWrapper} validationError`}>{titleMessage}</span>
                                <span className={styles.formLabel} > Beskrivelse *</span>
                                <textarea id="" name="" value={desc} onChange={(event) => { setDesc(event.target.value); }} />
                                <span className={`${styles.validationMessageWrapper} validationError`}>{descMessage}</span>
                                <span className={styles.imageUploadWrapper}><ImageUpload className={styles["upload-input"]}
                                    onChange={images => setImageAttachments(images)}
                                    disabled={false} />
                                </span>
                                <span className={styles.imageUploadWrapper}><DocumentUpload className={styles["upload-input"]}
                                    onChange={images => setDocumentAttachments(images)}
                                    disabled={false} />
                                </span>
                                <div className={styles.page2BtnWrapper} >
                                    <span onClick={() => {
                                        let isValid = validateForm()
                                        setValidationActive(true);
                                        if (isValid) {
                                            setPage(3)
                                            setLastValidPage(2)
                                        }
                                    }} className="brightBtn" > SE OPPSUMMERING </span>
                                    {/* <span className="fadedBtn" onClick={() => { saveFormToBrowser() }}> AVSLUTT UTEN Å SENDE </span> */}
                                </div>
                            </div>}
                        {
                            page == 3 && <div className={styles.page3Wrapper}>
                                <span className={styles.page2Title}> Oppsummering </span>
                                <span className={styles.summaryBox} >
                                    <span className={styles.leftTitle}>{title}</span>
                                    <span className="filterBtn">{topCat && topCat.name}</span>
                                    {subCat && <span>
                                        <span className={styles.biggerThanSign}> &gt; </span>
                                        <span className="filterBtn"> {subCat && subCat.name}</span>
                                    </span>}
                                    <span className={styles.summaryDesc} > {desc} </span>
                                    <span className={styles.formLabel} >
                                        {userIsOwner(props.userInfo) && chosenBuilding && chosenBuilding.street}
                                        {(!userIsOwner(props.userInfo) && props.preferredBuilding) ? props.preferredBuilding["name"] : ""}
                                        {floor ? `, etg ${floor}` : ""} {room ? `, rom ${room}` : ""}
                                    </span>
                                    {/* <span className={styles.registeredLabel} > Registrert xx.zz.yyyy kl.ii.ii </span> */}
                                </span>
                                <div className={styles.page2BtnWrapper} >
                                    <span onClick={
                                        () => {
                                            setIsSubmittingForm(true);
                                            onSubmitForm(createIssue)
                                        }
                                    } className={isSubmittingForm ? "disabledBrightBtn" : "brightBtn"} >SEND INN FORESPØRSEL</span>
                                    <span className="fadedBtn" onClick={() => { setPage(2) }}>GJØR ENDRINGER</span>
                                    {/* <span className="fadedBtn" onClick={() => { saveFormToBrowser() }}> AVSLUTT UTEN Å SENDE </span> */}
                                </div>
                            </div>}
                        {
                            page == 4 && <div className={styles.page4Wrapper}>
                                <span className={styles.titleCompressed}> SAKEN ER REGISTRERT </span>
                                <span className={styles.confirmationSubHeaderWrapper} >
                                    <span className={styles.confirmationSubHeader}> Vi prøver å komme tilbake til deg så fort som mulig.</span>
                                    <span className={styles.confirmationSubHeader} > Du kan følge opp forespørselen og legge til ny informasjon under «Saker».</span>
                                </span>
                                <span className={styles.summaryBox} >
                                    <span className={styles.leftTitle}>{title}</span>
                                    <span className="filterBtn">{topCat && topCat.name}</span>
                                    {subCat && <span>
                                        <span className={styles.biggerThanSign}> &gt; </span>
                                        <span className="filterBtn"> {subCat && subCat.name}</span>
                                    </span>}
                                    <span className={styles.summaryDesc} > {desc} </span>
                                    <span className={styles.formLabel} >
                                        {userIsOwner(props.userInfo) && chosenBuilding && chosenBuilding.street}
                                        {(!userIsOwner(props.userInfo) && props.preferredBuilding) ? props.preferredBuilding["name"] : ""}
                                        {floor ? `, etg ${floor}` : ""} {room ? `, rom ${room}` : ""}
                                    </span>
                                    {/* <span className={styles.registeredLabel} > Registrert xx.zz.yyyy kl.ii.ii </span> */}
                                </span>
                                <div className={styles.page2BtnWrapper} >
                                    <span onClick={() => {
                                        // history.push("/")
                                        location.href = location.protocol + "//" + location.host
                                    }} className="brightBtn" > SE OVERSIKT OVER ALLE DINE SAKER </span>
                                    <span className={["fadedBtn", styles.marginRight24].join(" ")} onClick={() => { resetState() }}> REGISTRER NY SAK </span>
                                    {/* <span className="fadedBtn" onClick={() => { }}> SLETTE / ENDRE DENNE SAKEN </span> */}
                                </div>
                            </div>
                        }

                    </div>
                    {
                        <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2" >
                            {page != 4 ? <Stepper1 onClick={() => { setPage(1) }} className={[styles.link, styles.circledNumber, styles.circle1, page == 1 ? styles.activeCircleBtn : "", page > 1 ? styles.doneStepCircle : ""].join(" ")}></Stepper1> :
                                <StepperDone className={[styles.circledNumber, styles.circle1, styles.doneStepCircle].join(" ")}></StepperDone>}
                            <Dots className={[styles["dots1"], page > 1 ? styles.doneStepCircle : ""].join(" ")}></Dots>
                            {page != 4 ? <Stepper2 onClick={() => { if (lastValidPage < 2) { return; } setPage(2) }} className={[lastValidPage > 1 ? styles.link : "", styles.circledNumber, styles.circle2, page == 2 ? styles.activeCircleBtn : "", page > 2 ? styles.doneStepCircle : ""].join(" ")}></Stepper2> :
                                <StepperDone className={[styles.circledNumber, styles.circle2, styles.doneStepCircle].join(" ")}></StepperDone>}
                            <Dots className={[styles["dots2"], page > 2 ? styles.doneStepCircle : ""].join(" ")}></Dots>
                            {page != 4 ? <Stepper3 onClick={() => { if (lastValidPage < 3) { return; } setPage(3) }} className={[lastValidPage > 2 ? styles.link : "", styles.circledNumber, styles.circle3, page == 3 ? styles.activeCircleBtn : "", page > 3 ? styles.doneStepCircle : ""].join(" ")}></Stepper3> :
                                <StepperDone className={[styles.circledNumber, styles.circle3, styles.doneStepCircle].join(" ")}></StepperDone>}
                        </div>
                    }
                </div>}
            </div>
        )
        }
    </Mutation>

}

const CREATE_ISSUE_MUTATION = gql`
    mutation CreateIssue($title: String!, $buildingId: String!, $floor: String, $room: String, $priority: Int!, $category: String!, $contractId: String, $descriptionComment: CommentInput!) {
        createIssue(issue: { 
            title: $title,
            buildingId: $buildingId, 
            floor: $floor, 
            room: $room, 
            priority: $priority, 
            category:  $category, 
            contractId: $contractId,
            descriptionComment: $descriptionComment,
            isService: false
        }) {
            status
            message
        }
    }
`