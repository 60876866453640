import * as React from 'react'
import uuidv4 from "uuid/v4";

import styles from './ImageUpload.module.scss'
import AttachmentPreviewList from "../../viewComponents/AttachmentPreviewList/AttachmentPreviewList";
import { ReactComponent as Icon } from '../../viewComponents/Icons/PaperClipIcon.svg'
import FieldErrorMessage from "../../viewComponents/FieldErrorMessage/FieldErrorMessage";

export interface IFileUpload {
    id: string
    file: File
    loading: boolean
}

interface Props {
    id?: string
    onChange: (files: IFileUpload[]) => void
    className?: string
    disabled?: boolean
}

interface State {
    files: IFileUpload[],
    fileUploadError?: string
}

class ImageUpload extends React.Component<Props, State> {

    state: State = {
        files: []
    }

    onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()

        const files: FileList | null = event.target.files
        if (files == null) return
        const file = files.item(0)!
        const newFileUpload: IFileUpload = {
            id: uuidv4(),
            file: file,
            loading: true
        }

        event.target.value = ""

        this.setState({
            files: [...this.state.files, newFileUpload]
        }, () => {
            const formData = new FormData()
            formData.append("id", newFileUpload.id)
            formData.append("file", file)

            fetch("/api/image", {
                method: "POST",
                body: formData,
                credentials: "include"
            })
                .then(response => {
                    if (!response.ok) {
                        throw Error(`${response.status}`)
                    }
                    return response
                })
                .then(result => result.json())
                .then(response => {
                    const index = this.state.files.findIndex(file => file.id == response.id)
                    const newFilesArray = [...this.state.files]
                    newFilesArray[index] = Object.assign({}, newFilesArray[index], {
                        loading: false
                    })

                    this.setState({
                        files: newFilesArray,
                        fileUploadError: undefined
                    }, () => this.props.onChange(this.state.files))
                })
                .catch((error: Error) => {
                    this.setState({
                        files: [],
                        fileUploadError: error.message === "400" ? "Filtypen er ikke støttet eller filen er for stor" : "Noe gikk galt under opplastingen. Kontakt Newsec om problemet vedvarer."
                    })
                })
        })
    }

    // TODO: Bad practice to call functions from parent
    resetFileList = () => {
        this.setState({ files: [] })
    }

    onRemoveFileUpload = (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
        event.preventDefault()

        const index = this.state.files.findIndex(file => file.id == id)
        if (index != -1) {
            const newFileList = this.state.files.filter((_, i) => i != index)
            this.setState({
                files: newFileList
            }, () => this.props.onChange(this.state.files))
        }
    }

    render(): React.ReactNode {

        const { id, className = "", disabled = false } = this.props

        const inputId = id ? id : uuidv4()

        return (
            <div className={className}>
                <input id={inputId} type="file" className={styles["input"]} onChange={this.onFileInputChange} disabled={disabled} />
                <label htmlFor={inputId} className={styles["label"]}>
                    <Icon className={styles["icon"]} />
                    <span className={styles["uploaderBtn"]}>Legg til bilde</span>
                </label>

                {!!this.state.fileUploadError && (
                    <FieldErrorMessage>
                        {this.state.fileUploadError}
                    </FieldErrorMessage>
                )}

                <AttachmentPreviewList images={this.state.files.map(file => ({ id: file.id, name: file.file.name }))} documents={[]}
                    withRemoveButton={true}
                    onRemoveAttachment={this.onRemoveFileUpload} />
            </div>
        )
    }
}

export default ImageUpload