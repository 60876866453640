import * as React from "react"
import { FormEvent } from 'react'
import styles from "./BuildingList.module.scss"
import { Building, Company } from "../../../types/UserInfo"
import { Card, LinkCard } from "../../../viewComponents/Card/Cards"
import { TagType } from "../../../types/TagType"
import { Query } from "react-apollo";
import { gql } from "apollo-boost";
import { Redirect } from "react-router";
import DocumentIcon from "../../../viewComponents/Icons/DocumentIcon";
import BuildingIcon from "../../../viewComponents/Icons/BuildingIcon/BuildingIcon";
import IssuesIcon from "../../../viewComponents/Icons/IssuesIcon";
import ContactIcon from "../../../viewComponents/Icons/ContactIcon";
import ChevronDown from "../../../viewComponents/Icons/ChevronDown";
import CenteredLoadingIcon from "../../../viewComponents/CenteredLoadingIcon/CenteredLoadingIcon";

interface BuildingsListProps {
    buildings
    onPreferredBuildingChange: (event?: FormEvent<HTMLSelectElement>, value?: any) => void
}

// { buildings, onPreferredBuildingChange }: {
//     buildings: Building[],
//     onPreferredBuildingChange: any
// }

const BuildingsList = (props: BuildingsListProps) => (
    <ul className={styles["building-list"]}>
        {props.buildings.map(building => (
            <Card tagType={TagType.li} key={building.id} className={styles["building-list-item"]}>
                <header className={styles["building-header-block"]}>
                    <BuildingIcon roundBackground />
                    <h3>{building.street}, <span>{building.city.toLowerCase()}</span></h3>
                </header>

                <nav>
                    <ul className={styles["links-list"]}>
                        <LinkCard to={`/building/${building.id}/issues`} tagType={TagType.li} className={styles["links-list-item"]}
                            onClick={() => { props.onPreferredBuildingChange(undefined, building.id) }}>
                            <span className={styles["links-list-item-content"]}>
                                <IssuesIcon />
                                <span>Saker</span>
                                <ChevronDown className={styles["arrow-icon"]} />
                            </span>
                        </LinkCard>

                        {<LinkCard to={`/building/${building.id}/documents`} tagType={TagType.li} className={styles["links-list-item"]}
                            onClick={() => { props.onPreferredBuildingChange(undefined, building.id) }}>
                            <span className={styles["links-list-item-content"]}>
                                <DocumentIcon />
                                <span>Dokumenter</span>
                                <ChevronDown className={styles["arrow-icon"]} />
                            </span>
                        </LinkCard>}

                        <LinkCard to={`/building/${building.id}/contacts`} tagType={TagType.li} className={styles["links-list-item"]}
                            onClick={() => { props.onPreferredBuildingChange(undefined, building.id) }}>
                            <span className={styles["links-list-item-content"]}>
                                <ContactIcon />
                                <span>Kontakt</span>
                                <ChevronDown className={styles["arrow-icon"]} />
                            </span>
                        </LinkCard>
                    </ul>
                </nav>
            </Card>
        ))}
    </ul>
)

const BuildingListContainer = (props: { onPreferredBuildingChange: any }) => {
    const BUILDINGS_QUERY = gql`
        query GetUserInfo {
            userInfo {
                companies {
                    buildings {
                        id
                        street
                        city
                        contracts {
                            contractId
                            name
                        }
                    }
                }
                roles
            }
        }
    `

    return (
        <Query query={BUILDINGS_QUERY} partialRefetch={true}>
            {({ loading, error, data }) => {
                if (loading) return <CenteredLoadingIcon text={"Laster bygninger"} />
                if (error) return <span>Error :(</span>

                const buildings = data.userInfo.companies.reduce((result: Building[], company: Company) => {
                    return [...company.buildings, ...result]
                }, [])

                if (buildings.length === 1) return <Redirect to={`/building/${buildings[0].id}/issues`} />
                else return <BuildingsList buildings={buildings} onPreferredBuildingChange={props.onPreferredBuildingChange} />
            }}
        </Query>
    )
}

export default BuildingListContainer