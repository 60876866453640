import * as React from 'react'
import { Link, Redirect } from "react-router-dom";

import styles from "../Authentication.module.scss";
import TextField from "../../../viewComponents/TextField/TextField";
import { ConfirmationButton } from "../../../viewComponents/Buttons/Buttons";
import FieldErrorMessage from "../../../viewComponents/FieldErrorMessage/FieldErrorMessage";
import { useAuthApi, SIGNUP_PATH } from "../../../hooks/useAuthApi";

export const validatePassword = (password: string, passwordConfirm: string): string | undefined => {
    const passwordRequirements = new RegExp("(\\w|\\d){5,}") // todo: actual requirements?
    if (password != passwordConfirm) {
        return "Passordene er ikke like"
    }
    if (!passwordRequirements.test(password)) {
        return `Passord oppfyller ikke kravene`
    }

    return
}

const getErrorMessage = (responseStatus: number | undefined) => {
    if (responseStatus == undefined) return ""
    if (responseStatus == 200) return ""

    switch (responseStatus) {
        case 400: return "Bruker finnes fra før"
        case 406: return "Du har ikke tilgang til å registrere deg. Ta kontakt med kundesenter"
        default: return "Noe gikk feil. Prøv på nytt eller ta kontakt med kundesenter"
    }
}

const RegistrationForm = () => {
    const { isLoading, responseStatus, doApiCall } = useAuthApi()

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const formElements = event.currentTarget.elements

        doApiCall({
            path: SIGNUP_PATH,
            body: {
                username: (formElements.namedItem("username") as HTMLInputElement).value,
                password: (formElements.namedItem("password") as HTMLInputElement).value,
                name: (formElements.namedItem("name") as HTMLInputElement).value
            }
        })
    }

    if (responseStatus == 200) {
        return <Redirect to={"/auth/validation"} />
    } else {
        const errorMessage = getErrorMessage(responseStatus)

        return (
            <form className={styles['login-form']} onSubmit={onSubmit}>
                {/* <h2 className={styles['login-form-header']}>
                    Registrer deg
                </h2>

                <p className={styles['login-form-hint']}>
                    Skriv inn epost, navn og passord for å opprette konto
                </p> */}

                <TextField name="username"
                    className={styles['input-field']}
                    placeholder={"E-POSTADRESSE"}
                    autoFocus
                    type="email" />
                <TextField name="name"
                    className={styles['input-field']}
                    placeholder={"NAVN"} />
                <TextField name="password"
                    className={styles['input-field']}
                    placeholder={"PASSORD"}
                    type="password" />
                <TextField name="confirm_password"
                    className={styles['input-field']}
                    placeholder={"BEKREFT PASSORD"}
                    type="password" />

                <FieldErrorMessage hidden={!errorMessage}>
                    {errorMessage}
                </FieldErrorMessage>

                <div className={styles["action-wrapper"]}>
                    <ConfirmationButton type="submit" className={styles['login-button']} isLoading={isLoading}>
                        REGISTER DEG
                    </ConfirmationButton>

                    <div>
                        <Link to={"/auth/login"} className={styles['link']}>LOGG INN</Link>
                        <Link to={"/auth/login"} className={styles['link']}>TILBAKE</Link>
                    </div>
                </div>
            </form>
        )
    }
}

export default RegistrationForm