import * as React from 'react'
import {RouteComponentProps} from "react-router";
import {gql} from "apollo-boost";
import {Query} from "react-apollo";
import DocumentsTable from "./DocumentsTable";
import CenteredLoadingIcon from "../../viewComponents/CenteredLoadingIcon/CenteredLoadingIcon";

interface RouteParams {
    buildingId: string
}

const OwnerDocumentsContainer = (props: RouteComponentProps<RouteParams>) => {
    const QUERY = gql`
        query DocumentQuery($buildingId: String!) {
            commonTenantDocuments(buildingId: $buildingId) {
                name
                lastModified
                documentType {
                    id
                    name
                }
                fileUrl
                tenantName
                contractId
            }
        }
    `

    return (
        <Query query={QUERY} variables={{ buildingId: props.match.params.buildingId }} partialRefetch={true}>
            {({ loading, error, data }) => {
                if (loading) return <CenteredLoadingIcon text={"Laster dokumenter"}/>
                if (error) return <span>Feil ved lasting av dokumenter</span>
                return (
                    <DocumentsTable isBuildingOwner={true} documents={data.commonTenantDocuments} />
                )
            }}
        </Query>
    )
}

export default OwnerDocumentsContainer