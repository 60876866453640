import * as React from 'react'

const ExclamationIcon = () => (
    <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="Leietakerkontakt-(desktop)" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="web_login-[error]" transform="translate(-579.000000, -584.000000)">
                <g id="icon-/-excalamation" transform="translate(579.000000, 584.000000)">
                    <circle id="Oval" fill="#C4232A" fillRule="nonzero" cx="9" cy="9" r="9"/>
                    <g id="Group" transform="translate(7.500000, 4.500000)" fill="#FFFFFF">
                        <g id="!-Exclamation-mark-Blue-3">
                            <g id="!-Exclamation-mark-Blue-2">
                                <g id="!-Exclamation-mark-Blue-1">
                                    <g id="Group-2">
                                        <path d="M1.74979045,10.4999998 C1.1710437,10.4999998 0.700000003,10.0289561 0.700000003,9.45020938 C0.700000003,8.87146263 1.1710437,8.39999986 1.74979045,8.39999986 C2.32937535,8.39999986 2.79999997,8.87146263 2.79999997,9.45020938 C2.79999997,10.0289561 2.32937535,10.4999998 1.74979045,10.4999998" id="Fill-1"/>
                                        <path d="M1.71471997,7.43455982 C1.65479997,7.43455982 1.60047998,7.39535982 1.57975998,7.33711982 C1.51535998,7.14391983 5.45896661e-13,2.59503994 5.45896661e-13,1.71415996 C5.45896661e-13,0.768319982 0.769999988,-9.32587341e-15 1.71471997,-9.32587341e-15 C2.66055996,-9.32587341e-15 3.42999995,0.768319982 3.42999995,1.71415996 C3.42999995,2.59503994 1.91519997,7.14391983 1.85023997,7.33711982 C1.83119997,7.39535982 1.77743997,7.43455982 1.71471997,7.43455982" id="Fill-3"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default ExclamationIcon