import { Building, Contract, UserInfo, Company } from "../types/UserInfo";

export const getPreferredBuilding = (userInfo: UserInfo): Building => {
    const buildings = buildingsForUser(userInfo)
    if (buildings.length == 0) {
        throw `Expected one or more buildings for user ${userInfo.name}, but found none`
    }
    if (buildings.length == 1) {
        return buildings[0]
    }

    const preferredBuildingId = localStorage.getItem("preferred-building-id");
    if (!!preferredBuildingId) {
        const preferredBuilding = buildings.find(building => building.id == preferredBuildingId)
        if (!!preferredBuilding) {
            return preferredBuilding
        }
    }

    return buildings[0]
}

export const getPreferredContract = (userInfo: UserInfo): Contract => {
    const building = getPreferredBuilding(userInfo)
    if (!building.contracts || building.contracts.length == 0) {
        throw `Expected one or more contract for user ${userInfo.name} in building ${building.id}, but found none`
    }
    const preferredContractId = localStorage.getItem("preferred-contract-id");
    if (!!preferredContractId) {
        const preferredContract = building.contracts.find(contract => contract.contractId == preferredContractId)
        if (!!preferredContract) {
            return preferredContract
        }
    }

    return building.contracts[0]
}

export const getPreferredCompany = (userInfo: UserInfo): Company => {
    const building = getPreferredBuilding(userInfo)
    if (!building.contracts || building.contracts.length == 0) {
        throw `Expected one company for user ${userInfo.name} in building ${building.id}, but found none`
    }
    const preferredCompany = userInfo.companies.find(c => !!c.buildings.find(b => b.id == building.id));
    if (preferredCompany) {
        return preferredCompany
    }
    return userInfo.companies[0]
}

export const buildingsForUser = (userInfo: UserInfo): Building[] => {
    if (!userInfo.companies) {
        throw `Expected one or more companies for user ${userInfo.name}, but found none`
    }
    const buildings = userInfo.companies.map(company => company.buildings).reduce((b1, b2) => b1.concat(b2))
    let uniqueBuildings: Building[] = []
    buildings.forEach(building => {
        const alreadyAdded = uniqueBuildings.some(uniqueBuilding => uniqueBuilding.id == building.id)
        if (!alreadyAdded) {
            uniqueBuildings.push(building)
        }
    });

    return uniqueBuildings
}

export const userNotOwner = (userInfo: UserInfo): boolean => {
    return !userIsOwner(userInfo)
}

export const userIsOwner = (userInfo: UserInfo): boolean => {
    return userInfo.roles.includes("OWNER")
}

export const userIsTenant = (userInfo: UserInfo): boolean => {
    return userInfo.roles.includes("TENANT")
}

export const buildingFromBuildingId = (buildingId: string, userInfo: UserInfo): Building => {
    return buildingsForUser(userInfo).find(building => building.id == buildingId) as Building
}

export const contractFromContractId = (contractId: string, buildingId: string, userInfo: UserInfo): Contract => {
    return buildingFromBuildingId(buildingId, userInfo).contracts.find(contract => contract.contractId == contractId) as Contract
}

