import * as React from 'react'

const IssuesIcon = (props: React.ComponentProps<"svg">) => (
    <svg width="20px" height="19px" viewBox="0 0 20 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
        <g id="web_gårdeierdashboard" transform="translate(-731.000000, -283.000000)">
            <g id="content-note" transform="translate(729.000000, 281.000000)">
                <g id="Filled_Icons" transform="translate(2.880000, 2.880000)">
                    <path d="M16.2133333,0.00096 L2.02666667,0.00096 C0.90896,0.00096 0,0.86304 0,1.92096 L0,15.36096 C0,16.41984 0.90896,17.28096 2.02666667,17.28096 L9.12,17.28096 C9.95093333,17.28096 10.9794667,16.8768 11.5661867,16.32096 L17.2266667,10.95936 C17.8144,10.40256 18.24,9.42816 18.24,8.64192 L18.24,1.92192 C18.24,0.86304 17.3320533,0.00096 16.2133333,0.00096 Z M9.12,15.36096 L9.12,10.56096 C9.12,9.50112 10.0279467,8.64096 11.1466667,8.64096 L16.2133333,8.64096 L9.12,15.36096 Z" id="Shape"/>
                </g>
            </g>
        </g>
    </svg>
)

export default IssuesIcon