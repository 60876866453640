import * as React from 'react'
import uuidv4 from 'uuid/v4'

import styles from './SelectInput.module.scss'
import { FormEvent } from "react";

interface Props extends React.HTMLProps<HTMLSelectElement> {
    labelText?: string
    optionsWithId?: any[]
    includeBlankOption?: boolean
    onChange?: (event: FormEvent<HTMLSelectElement>) => void
    selectedValueId?: string
}

export default class SelectInput extends React.Component<Props> {
    static displayName = "SelectInput"

    render(): React.ReactNode {
        const {
            // Use placeholder as default if labelText is not given.
            labelText,
            className,
            optionsWithId,
            includeBlankOption,
            selectedValueId,
            ...htmlProps
        } = this.props

        // Used for connecting label to select
        const htmlId = !!this.props.id ? this.props.id : uuidv4()

        let options = this.props.children
        if (!!optionsWithId) {
            options = (optionsWithId.map(option => {
                return <option key={option.id} value={option.id} selected={selectedValueId == option.id}>
                    {option.displayValue}
                </option>
            }))
        }

        return (
            <>
                <div className={`${styles["select-wrapper"]} ${styles[`${className}`]}`}>
                    {!!labelText && (
                        <label htmlFor={htmlId} className={className}>
                            {labelText}
                        </label>
                    )}
                    <select id={htmlId} className={styles["select"]} {...htmlProps} onChange={this.props.onChange}>
                        {includeBlankOption && <option key={""} value={""}></option>}
                        {options}
                    </select>
                </div>
            </>
        )
    }
}
