import * as React from 'react'
import {createRef} from 'react'
import uuidv4 from 'uuid/v4'
import classNames from 'classnames'

import styles from './FullWidthTextField.module.scss'

interface InputProps extends React.HTMLProps<HTMLInputElement> {
}

interface State {
    hasText: boolean
}

export class FullWidthTextInput extends React.Component<InputProps, State> {

    private inputRef = createRef<HTMLInputElement>()

    state = {
        hasText: false
    }

    hasText = () => {
        const node = this.inputRef.current
        return !!node && !!node.value
    }

    componentDidMount(): void {
        this.setState({hasText: this.hasText()})
    }

    onChange = (event: React.FormEvent<HTMLInputElement>) => {
        this.setState({hasText: this.hasText()})

        if (this.props.onChange) {
            this.props.onChange(event)
        }
    }

    render(): React.ReactNode {
        const {
            placeholder,
            type = "text",
            ...htmlAttributes
        } = this.props

        const id = uuidv4()

        return (
            <div className={styles['input-wrapper']}>
                <input id={id}
                       className={styles['input']}
                       type={type}
                       onChange={this.onChange}
                       ref={this.inputRef}
                       {...htmlAttributes} />
                <label htmlFor={id} className={styles['label']} data-hasvalue={this.state.hasText}>
                    <span>{placeholder}</span>
                    {(!htmlAttributes.required && !htmlAttributes.readOnly) && (
                        <span>&nbsp;(valgfritt)</span>
                    )}
                </label>
            </div>
        )
    }
}

interface TextareaProps extends React.HTMLProps<HTMLTextAreaElement> {
}

export const FullWidthTextarea = (
    {
        placeholder,
        className = "",
        ...htmlAttributes
    }: TextareaProps
) => {
    const id = uuidv4()

    const wrapperClasses = classNames(styles["textarea-wrapper"], {
        [className]: !!className
    })

    return (
        <div className={wrapperClasses}>
            <textarea id={id} className={styles['input']} {...htmlAttributes} />
            <label htmlFor={id} className={styles['label']}>{placeholder}</label>
        </div>
    )
}

