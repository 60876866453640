import * as React from "react";
import styles from "../features/Authentication/Authentication.module.scss";
import NewsecLogo from "../viewComponents/Icons/NewsecLogo/NewsecLogo";
import OsloArealLogo from "../viewComponents/Icons/OsloArealLogo/OsloArealLogo";
import SvgLogo from "../viewComponents/Icons/SvgLogo/SvgLogo";

export const getIcon = () => {
    switch (getCompany()) {
        case "svg":
            return "/svg";
        default:
            return "/newsec";
    }
}

export const getLogo = () => {
    switch (getCompany()) {
        case "oa":
            return <OsloArealLogo className={styles['logo']} />;
        case "svg":
            return <SvgLogo className={styles['logo']} />;
        default:
            return <NewsecLogo className={styles['logo']} />;
    }
}

export const getBackground = () => {
    const company = getCompany();
    switch (company) {
        case "oa":
            return "osloareal-background";
        case "svg":
            return "svg-background";
        default:
            return "newsec-background";
    }
}

export const getMainHeading = () => {
    const company = getCompany();
    switch (company) {
        case "oa":
            return;
        case "svg":
            return;
        default:
            return <h1 className={styles['main-heading']}>Newsec&nbsp;-&nbsp;<span>The&nbsp;full&nbsp;service</span><span>property&nbsp;house</span></h1>;
    }
}


const getCompany = () => {
    const host = window.location.hostname.toLowerCase();
    return host.split("-")[0];
}
