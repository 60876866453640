import * as React from 'react'
import classNames from 'classnames'

import styles from './CenteredLoadingIcon.module.scss';
import LoadingIcon from "../Icons/LoadingIcon/LoadingIcon";

interface Props extends React.ComponentProps<"svg"> {
    text?: string
}

const CenteredLoadingIcon = ({text, className = "", ...otherProps}: Props) => {
    return (
        <div>
            <LoadingIcon className={classNames(styles["centered-icon"], {[className]: !!className})} {...otherProps}/>
            {!!text && (
                <p className={styles["text"]}>{text}</p>
            )}
        </div>
    )
}

export default CenteredLoadingIcon
