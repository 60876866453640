import * as React from 'react'
import classNames from 'classnames'

import styles from './Buttons.module.scss'
import {Link, LinkProps} from "react-router-dom";
import LoadingIcon from "../Icons/LoadingIcon/LoadingIcon";

interface BaseButtonProps extends React.ComponentProps<"button"> {
    isLoading?: boolean
}

const BaseButtonMarkup = ({ disabled = false, isLoading = false, children, ...htmlAttributes }: BaseButtonProps) => (
    <button {...htmlAttributes} disabled={isLoading ? true : disabled}>
        {children}
    </button>
)

export const ConfirmationButton = (props: BaseButtonProps) => {
    const {
        children,
        className = "",
        ...htmlAttributes
    } = props

    const classes = classNames(styles['button'], styles['confirmation-button'], {
        [className]: !!className
    })

    return (
        <BaseButtonMarkup {...htmlAttributes} className={classes}>
            <span>{children}</span>
            {props.isLoading && (
                <LoadingIcon className={styles["loading-icon"]}/>
            )}
        </BaseButtonMarkup>
    )
}
ConfirmationButton.displayName = "ConfirmationButton"

interface PrimaryButtonProps extends BaseButtonProps {
    IconComponent: React.ComponentType
    collapsed?: boolean
}

export const PrimaryButton = (props: PrimaryButtonProps) => {
    const {
        children,
        collapsed = false,
        IconComponent,
        className = "",
        ...htmlAttributes
    } = props

    const classes = classNames(styles['button'], styles['primary-button'], {
        [styles['primary-button--collapsed']]: collapsed,
        [className]: !!className
    })

    return (
        <BaseButtonMarkup {...htmlAttributes} className={classes}>
            <IconComponent/><span>{children}</span>
        </BaseButtonMarkup>
    )
}
PrimaryButton.displayName = "PrimaryButton"


interface PrimaryLinkButtonProps extends LinkProps {
    IconComponent: React.ComponentType
    collapsed?: boolean
}

export const PrimaryLinkButton = (props: PrimaryLinkButtonProps) => {
    const {
        to,
        children,
        collapsed = false,
        IconComponent,
        className = "",
        ...htmlAttributes
    } = props

    const classes = classNames(styles['button'], styles['primary-button'], {
        [styles['primary-button--collapsed']]: collapsed,
        [className]: !!className
    })

    return (
        <Link to={to} className={classes} {...htmlAttributes}>
            <IconComponent/><span>{children}</span>
        </Link>
    )
}
PrimaryLinkButton.displayName = "PrimaryLinkButton"

interface SecondaryButton extends BaseButtonProps {
    IconComponent: React.ComponentType
}

export const SecondaryButton = (props: SecondaryButton) => {
    const {
        children,
        IconComponent,
        className = "",
        ...htmlAttributes
    } = props

    const classes = classNames(styles['button'], styles['secondary-button'], {
        [className]: !!className
    })

    return (
        <BaseButtonMarkup {...htmlAttributes} className={classes}>
            <IconComponent/><span>{children}</span>
        </BaseButtonMarkup>
    )
}
SecondaryButton.displayName = "SecondaryButton"

interface IconButtonProps extends BaseButtonProps {
    IconComponent: React.ComponentType<React.SVGProps<SVGSVGElement>>
    iconClassName?: string
}

export const IconButton = (props: IconButtonProps) => {
    const {
        IconComponent,
        iconClassName = "",
        className = "",
        children, // Should not be past down to BaseButtonMarkup
        ...htmlAttributes
    } = props

    const buttonClasses = classNames(styles["button"], styles["icon-button"], {
        [className]: !!className
    })

    return (
        <BaseButtonMarkup {...htmlAttributes} className={buttonClasses}>
            <IconComponent className={iconClassName}/>
        </BaseButtonMarkup>
    )
}
IconButton.displayName = "IconButton"
