import * as React from 'react'

import styles from './IssuesList.module.scss';
import { IssueType } from "../../../types/Issue";
import CardList from "../../../viewComponents/CardList/CardList";
import { TagType } from "../../../types/TagType";
import { UserInfo } from "../../../types/UserInfo";
import FilterButton from "../../../viewComponents/FilterButton/FilterButton";
import { userIsOwner } from "../../../tools/userUtils";
import NewIssueCard from '../../../viewComponents/IssueCard/NewIssueCard';
import { ReactComponent as SortUp } from "../../../viewComponents/Icons/newDesign/Sorting-arrow-up.svg"
import { ReactComponent as SortDown } from "../../../viewComponents/Icons/newDesign/Sorting-arrow-down.svg"
import { ReactComponent as ListView } from "../../../viewComponents/Icons/newDesign/List-view.svg"
import { ReactComponent as ExpandedView } from "../../../viewComponents/Icons/newDesign/Expanded-view.svg"

interface Props {
    issues?: IssueType[]
    basePath: string
    userInfo: UserInfo
    service?: boolean
}


const sortByDate = (a: IssueType, b: IssueType) => {
    return new Date(b.reportedDate!).getTime() - new Date(a.reportedDate!).getTime()
}

const sortByDateDesc = (a: IssueType, b: IssueType) => {
    return new Date(a.reportedDate!).getTime() - new Date(b.reportedDate!).getTime()
}

const sortByFinishedDate = (a: IssueType, b: IssueType) => {
    return new Date(b.finishedDate!).getTime() - new Date(a.finishedDate!).getTime()
}

const EmptyState = ({ isBuildingOwner }: { isBuildingOwner: boolean }) => (
    <article className={styles['empty-list']} data-cy="no-issues-message">
        <header>
            <p>Ingen åpne saker</p>
        </header>
        <p>Denne seksjonen vil inneholde alle åpne saker.</p>
        {!isBuildingOwner && (
            <p className={styles['empty-list-hint']}>Vil du melde en sak? Klikk på knappen i sidemenyen.</p>
        )}
    </article>
)

const AllIssuesList = ({ issues, basePath, compactView }: { issues: IssueType[], basePath: string, compactView: boolean }) => (
    <section className={styles["issue-list-section"]}>
        {/* <h3>Åpne</h3> */}
        <CardList data-cy="issueList">
            {[...issues!].map((issue: IssueType) => (
                <NewIssueCard key={issue.id}
                    cardType="link"
                    to={`${basePath}/issue/${issue.id}`}
                    data={issue}
                    tagType={TagType.li}
                    compactView={compactView} />
            ))}
        </CardList>
    </section>
)

enum FilterStatus {
    "all" = "all",
    "open" = "open",
    "finished" = "finished",
    "waiting" = "waiting"
}

interface IssueListState {
    filterByOwner: boolean
    showAllClosedIssues: boolean
    compactView: boolean
    statusFilter: FilterStatus
    freeSearchVal: string
    filteredIssues: IssueType[]
    issues: IssueType[]
    dateSortAsc: boolean | undefined
    alphSortAsc: boolean | undefined
}

class IssuesList extends React.Component<Props, IssueListState> {

    state: IssueListState = {
        filterByOwner: false,
        showAllClosedIssues: false,
        compactView: false,
        statusFilter: FilterStatus.open,
        freeSearchVal: "",
        issues: this.props.issues ? this.props.issues.sort(sortByDate) : [],
        filteredIssues: [],
        dateSortAsc: true,
        alphSortAsc: undefined
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.issues && this.props.issues.length != this.state.issues.length) {
            let filteredIssues = this.getFilteredIssues()
            this.setState({
                filteredIssues: filteredIssues,
                issues: this.props.issues
            })
        }
    }

    componentDidMount = () => {
        this.getFilteredIssues();
    }

    getFilteredIssues = () => {
        let { issues } = this.state;
        let filteredIssues: IssueType[]
        if (issues) {
            let { filterByOwner, freeSearchVal, statusFilter } = this.state;
            filteredIssues = issues.filter(i => {
                if (freeSearchVal && freeSearchVal.length > 2) {
                    if ((!i.title || i.title.toLowerCase().indexOf(freeSearchVal.toLowerCase()) < 0) && !i.serviceDeskId.includes(freeSearchVal)) {
                        return false;
                    }
                }
                if (filterByOwner) {
                    if (!this.userIsIssueOwner(i)) {
                        return false;
                    }
                }
                if (statusFilter == FilterStatus.finished && this.issueIsOpen(i)) {
                    return false
                }
                if (statusFilter == FilterStatus.open && !this.issueIsOpen(i)) {
                    return false
                }
                if (statusFilter == FilterStatus.waiting && !this.issueIsWaiting(i)) {
                    return false
                }
                return true
            })
            this.setState({ filteredIssues: filteredIssues })
            return filteredIssues
        } else {
            return []
        }
    }

    filterByIssueCreator = (issue: IssueType) => {
        return !this.state.filterByOwner || this.userIsIssueOwner(issue)
    }

    userIsIssueOwner = (issue: IssueType) => {
        return this.props.userInfo && issue.userInfo && issue.userInfo.name === this.props.userInfo.name
    }

    getOpenIssues = () => {
        let issues: IssueType[] | undefined = undefined
        if (this.props.issues) {
            issues = this.props.issues
                .filter(this.issueIsOpen)
                .filter(this.filterByIssueCreator)
        }
        return issues
    }

    getClosedOrSolvedIssues = () => {
        let issues: IssueType[] | undefined = undefined
        if (this.props.issues) {
            issues = this.props.issues.filter((issue) => {
                return this.issueIsClosed(issue) && !!issue.finishedDate
            }).sort(sortByFinishedDate)
        }
        if (!!issues && !this.state.showAllClosedIssues) {
            issues = issues.slice(0, 4);
        }
        return issues
    }

    toggleShowOwnFilter = (event: React.FormEvent<HTMLInputElement>) => {
        let value = event.currentTarget.value
        if (this.state.filterByOwner && value === "disable_filter" || !this.state.filterByOwner && value === "enable_filter") {
            this.setState({ filterByOwner: !this.state.filterByOwner });
        }
    }

    toggleShowAllClosed = (event: React.FormEvent<HTMLInputElement>) => {
        let value = event.currentTarget.value
        if (this.state.showAllClosedIssues && value === "show_recent" || !this.state.showAllClosedIssues && value === "show_all") {
            this.setState({ showAllClosedIssues: !this.state.showAllClosedIssues });
        }
    }

    issueIsClosed = (issue: IssueType) => {
        return ["SOLVED", "CLOSED"].includes(issue.status!)
    }

    issueIsOpen = (issue: IssueType) => {
        return !this.issueIsClosed(issue) && !this.issueIsWaiting(issue)
    }

    issueIsWaiting = (issue: IssueType) => {
        return ["AWAITING_RESPONSE", "WAITING_FOR_THIRD_PARTY"].includes(issue.status!)
    }

    onToggleAlphSort = () => {
        let issues = this.state.issues;
        let sortedIssues = issues.sort((a, b) => {
            if (this.state.alphSortAsc) {
                return a.title.localeCompare(b.title)
            } else {
                return b.title.localeCompare(a.title)
            }
        })
        this.setState({
            issues: sortedIssues,
            alphSortAsc: !this.state.alphSortAsc,
            dateSortAsc: undefined
        }, () => {
            this.getFilteredIssues();
        })
    }

    onToggleDateSort = () => {
        let issues = this.state.issues;
        let sortedIssues = issues.sort(this.state.dateSortAsc ? sortByDateDesc : sortByDate)
        this.setState({
            issues: sortedIssues,
            dateSortAsc: !this.state.dateSortAsc,
            alphSortAsc: undefined
        }, () => {
            this.getFilteredIssues();
        })
    }

    render() {

        const { issues, basePath, userInfo } = this.props
        const { filteredIssues, compactView, statusFilter, dateSortAsc, alphSortAsc } = this.state;
        const isBuildingOwner = userIsOwner(userInfo)
        const hasFetchedIssues: boolean = Array.isArray(issues)

        if (!hasFetchedIssues) {
            return <p data-cy="loading-issues-message">Laster saker</p>
        } else {
            return (
                <>
                    <section className={styles["filter-options"]}>
                        <span className='filtersHeader'>Filter</span>
                        <div className="filterCatWrapper">
                            <span className="filterCatLabel">Eier</span>
                            <FilterButton id={"disable_show_own"}
                                className={styles["filter-option"]}
                                value={"disable_filter"}
                                name={"disable_show_own"}
                                onClick={this.toggleShowOwnFilter}
                                checked={!this.state.filterByOwner}>
                                {`Alle`}
                            </FilterButton>
                            <FilterButton id={"enable_show_own"}
                                className={styles["filter-option"]}
                                value={"enable_filter"}
                                name={"enable_show_own"}
                                onClick={this.toggleShowOwnFilter}
                                checked={this.state.filterByOwner}>
                                {`Mine saker`}
                            </FilterButton>
                        </div>
                        <div className="filterCatWrapper">
                            <span className="filterCatLabel">Status</span>

                            <span
                                className={`filterBtn ${statusFilter == FilterStatus.all ? "filterBtnActive" : ""}`}
                                onClick={() => {
                                    this.setState({
                                        statusFilter: FilterStatus.all
                                    }, () => { this.getFilteredIssues() })
                                }}>
                                {`Alle`}
                            </span>
                            <span
                                className={`filterBtn ${statusFilter == FilterStatus.open ? "filterBtnActive" : ""}`}
                                onClick={() => {
                                    this.setState({
                                        statusFilter: FilterStatus.open
                                    }, () => { this.getFilteredIssues() })
                                }}>
                                {`Åpen`}
                            </span>
                            <span
                                className={`filterBtn ${statusFilter == FilterStatus.finished ? "filterBtnActive" : ""}`}
                                onClick={() => {
                                    this.setState({
                                        statusFilter: FilterStatus.finished
                                    }, () => { this.getFilteredIssues() })
                                }}>
                                {`Ferdig`}
                            </span>
                            <span
                                className={`filterBtn ${statusFilter == FilterStatus.waiting ? "filterBtnActive" : ""}`}
                                onClick={() => {
                                    this.setState({
                                        statusFilter: FilterStatus.waiting
                                    }, () => { this.getFilteredIssues() })
                                }}>
                                {`Venter`}
                            </span>
                        </div>
                        <div className="filterCatWrapper">
                            <span className="filterCatLabel">Søk</span>
                            <input className="searchBoxInput" onChange={(e) => {
                                this.setState({
                                    freeSearchVal: e.target.value
                                }, () => {
                                    this.getFilteredIssues()
                                })
                            }}></input>
                        </div>
                    </section>

                    <span className={styles.contentHeader}>{this.props.service ? "INNSENDTE FORESPØRSLER" : "INNMELDTE SAKER"}</span>

                    <span className="sortBtnsWrapper">
                        <ExpandedView className={[styles.viewIcon1, !compactView ? styles.activeViewIcon : ""].join(" ")} onClick={() => {
                            this.setState({
                                compactView: false
                            })
                        }} ></ExpandedView>
                        <ListView className={[styles.viewIcon2, compactView ? styles.activeViewIcon : ""].join(" ")} onClick={() => {
                            this.setState({
                                compactView: true
                            })
                        }} ></ListView>

                        <span style={{ float: "right" }}>
                            <span className="filterCatLabel">Sorter etter</span>
                            <span className={`filterBtn ${alphSortAsc != undefined ? "filterBtnActive" : ""}`} onClick={() => { this.onToggleAlphSort() }}>A - Å {alphSortAsc ? <SortUp /> : <SortDown />}</span>
                            <span className={`filterBtn ${dateSortAsc != undefined ? "filterBtnActive" : ""}`} onClick={() => { this.onToggleDateSort() }}>Dato {dateSortAsc ? <SortUp /> : <SortDown />}</span>
                        </span>
                    </span>

                    {(filteredIssues && filteredIssues.length > 0) ? <AllIssuesList issues={filteredIssues!} basePath={basePath} compactView={compactView} /> : <EmptyState isBuildingOwner={isBuildingOwner} />}

                </>
            )
        }
    }
}

export default IssuesList