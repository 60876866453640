import * as React from 'react'

const DocumentIcon = (props: React.ComponentProps<"svg">) => (
    <svg width="24px" height="23px" viewBox="0 0 24 23" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
        <defs>
            <polygon id="path-1"
                     points="0 0.976941176 23.7797647 0.976941176 23.7797647 22.5882353 0 22.5882353"/>
        </defs>
        <g id="web_gårdeier_saker" transform="translate(-14.000000, -245.000000)">
            <g id="icon-/-document" transform="translate(14.000000, 244.000000)">
                <g id="Document---Report-Blue-1" transform="translate(0.000000, 1.000000)">
                    <g id="Group-4">
                        <mask id="mask-2" fill="white">
                            <use xlinkHref="#path-1"/>
                        </mask>
                        <g id="Clip-2"/>
                        <path
                            d="M10.8112941,1.13082353 C10.8112941,1.04470588 10.7421176,0.976941176 10.6588235,0.976941176 L1.23952941,0.976941176 C1.15482353,0.976941176 1.01647059,0.978352941 0.933176471,0.982588235 C0.933176471,0.982588235 0.00564705882,1.02776471 0.00564705882,2.05835294 L0.00564705882,14.8701176 C0.00564705882,14.9562353 0.00282352941,15.0945882 4.06575815e-20,15.1778824 C4.06575815e-20,15.1778824 -0.0324705882,16.104 1.08564706,16.104 L10.6588235,16.104 C10.7421176,16.104 10.8112941,16.0362353 10.8112941,15.9515294 L10.8112941,15.1792941 C10.8112941,15.0945882 10.7421176,15.0254118 10.6588235,15.0254118 L1.23952941,15.0254118 C1.15482353,15.0254118 1.08564706,14.9562353 1.08564706,14.8701176 L1.08564706,14.0964706 C1.08564706,14.0131765 1.15482353,13.944 1.23952941,13.944 L10.6588235,13.944 C10.7421176,13.944 10.8112941,13.8734118 10.8112941,13.7901176 L10.8112941,1.13082353"
                            id="Fill-1" mask="url(#mask-2)"/>
                        <path
                            d="M12.0465882,4.21835294 C11.9604706,4.21835294 11.8927059,4.28752941 11.8927059,4.37223529 L11.8927059,22.4357647 C11.8927059,22.5190588 11.9604706,22.5882353 12.0465882,22.5882353 L23.6244706,22.5882353 C23.7105882,22.5882353 23.7797647,22.5190588 23.7797647,22.4357647 L23.7797647,4.37223529 C23.7797647,4.28752941 23.7105882,4.21835294 23.6244706,4.21835294 L12.0465882,4.21835294 Z M22.6983529,21.3543529 C22.6983529,21.4390588 22.6277647,21.5096471 22.5444706,21.5096471 L13.1265882,21.5096471 C13.0404706,21.5096471 12.9712941,21.4390588 12.9712941,21.3543529 L12.9712941,5.45223529 C12.9712941,5.36752941 13.0404706,5.29835294 13.1265882,5.29835294 L22.5444706,5.29835294 C22.6277647,5.29835294 22.6983529,5.36752941 22.6983529,5.45223529 L22.6983529,21.3543529 L22.6983529,21.3543529 Z"
                            id="Fill-3" mask="url(#mask-2)"/>
                    </g>
                    <path
                        d="M21.0762353,12.8625882 L14.5948235,12.8625882 C14.2969412,12.8625882 14.0541176,12.6211765 14.0541176,12.3232941 C14.0541176,12.0268235 14.2969412,11.7825882 14.5948235,11.7825882 L21.0762353,11.7825882 C21.3741176,11.7825882 21.6169412,12.0268235 21.6169412,12.3232941 C21.6169412,12.6211765 21.3741176,12.8625882 21.0762353,12.8625882"
                        id="Fill-5"/>
                    <path
                        d="M21.0762353,10.7025882 L14.5948235,10.7025882 C14.2969412,10.7025882 14.0541176,10.4583529 14.0541176,10.1604706 C14.0541176,9.864 14.2969412,9.62117647 14.5948235,9.62117647 L21.0762353,9.62117647 C21.3741176,9.62117647 21.6169412,9.864 21.6169412,10.1604706 C21.6169412,10.4583529 21.3741176,10.7025882 21.0762353,10.7025882"
                        id="Fill-6"/>
                    <path
                        d="M21.0762353,8.54117647 L14.5948235,8.54117647 C14.2969412,8.54117647 14.0541176,8.29835294 14.0541176,8.00047059 C14.0541176,7.704 14.2969412,7.45835294 14.5948235,7.45835294 L21.0762353,7.45835294 C21.3741176,7.45835294 21.6169412,7.704 21.6169412,8.00047059 C21.6169412,8.29835294 21.3741176,8.54117647 21.0762353,8.54117647"
                        id="Fill-7"/>
                    <path
                        d="M21.0762353,15.0254118 L14.5948235,15.0254118 C14.2969412,15.0254118 14.0541176,14.7811765 14.0541176,14.4832941 C14.0541176,14.1868235 14.2969412,13.944 14.5948235,13.944 L21.0762353,13.944 C21.3741176,13.944 21.6169412,14.1868235 21.6169412,14.4832941 C21.6169412,14.7811765 21.3741176,15.0254118 21.0762353,15.0254118"
                        id="Fill-8"/>
                    <path
                        d="M21.0762353,17.1854118 L14.5948235,17.1854118 C14.2969412,17.1854118 14.0541176,16.9425882 14.0541176,16.6447059 C14.0541176,16.3482353 14.2969412,16.1054118 14.5948235,16.1054118 L21.0762353,16.1054118 C21.3741176,16.1054118 21.6169412,16.3482353 21.6169412,16.6447059 C21.6169412,16.9425882 21.3741176,17.1854118 21.0762353,17.1854118"
                        id="Fill-9"/>
                    <path
                        d="M18.9162353,19.3468235 L14.5934118,19.3468235 C14.2969412,19.3468235 14.0541176,19.104 14.0541176,18.8061176 C14.0541176,18.5096471 14.2969412,18.2682353 14.5934118,18.2682353 L18.9162353,18.2682353 C19.2141176,18.2682353 19.4569412,18.5096471 19.4569412,18.8061176 C19.4569412,19.104 19.2141176,19.3468235 18.9162353,19.3468235"
                        id="Fill-10"/>
                </g>
            </g>
        </g>
    </svg>
)

export default DocumentIcon
