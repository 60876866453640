import * as React from "react";
import classNames from "classnames";

import styles from './IssueDetails.module.scss'
import { Comment, Image, DocumentAttachment, IssueType } from "../../types/Issue";
import { formatIssueIdAndReportedDateString, formatLocationString, getFormattedStatusText, formatIssueReportedDateString, formatIssueReportedDateOnlyString, getFormattedCategory } from "../../tools/formatters";
import PriorityTag from "../PriorityTag/PriorityTag";
import Dialog, { Message } from "../Dialog/Dialog";
import { UserInfo, Building } from "../../types/UserInfo";
import LocationIcon from "../Icons/LocationIcon";
import AttachmentPreviewList from "../AttachmentPreviewList/AttachmentPreviewList";
import { getPreferredBuilding, userIsOwner } from "../../tools/userUtils";

interface Props {
    issue: IssueType
    userInfo: UserInfo
    onSubmitNewMessage(comment: string, imageIds: string[], documentIds: string[]): void
    preferredBuilding?: Building
}

const getPriorityType = (priority: number) => {
    switch (priority) {
        case 1:
            return "high"
        case 2:
            return "medium"
        case 3:
            return "low"
    }
}

const mapCommentToMessage = (comment: Comment, images: Image[], documents: DocumentAttachment[], userInfo: UserInfo): Message => {
    return {
        id: comment.id,
        content: comment.content,
        time: comment.created,
        sender: comment.senderName,
        isSystemMessage: comment.isSystemMessage,
        sentByUser: comment.senderUsername === userInfo.username,
        images: images,
        documents: documents
    }
}

const IssueDetails = (props: Props) => {
    const {
        id: issueId,
        title,
        address,
        floor,
        room,
        status,
        priority,
        comments,
        images,
        documents
    } = props.issue

    const {
        userInfo
    } = props

    // const preferredBuildingAddress = getPreferredBuilding(props.userInfo)
    // const addresss = preferredBuildingAddress ? preferredBuildingAddress.street : ""

    const wrapperClasses = classNames(styles['issue-details-wrapper'], {
        [styles['priority--high']]: priority === 1,
        [styles['priority--medium']]: priority === 2,
        [styles['priority--low']]: priority === 3
    })

    const headerClasses = classNames(styles['header'], {
        [styles['waitingCase']]: props.issue.status == "NEW" || props.issue.status == "AWAITING_RESPONSE" || props.issue.status == "WAITING_FOR_THIRD_PARTY",
        [styles['finishedCase']]: props.issue.status == "CLOSED" || props.issue.status == "SOLVED",
        [styles['openCase']]: props.issue.status == "ASSIGNED_TO_AGENT"
    })

    const getBuildingName = () => {
        if (userIsOwner(userInfo)) {

        } else {
            return props.preferredBuilding ? props.preferredBuilding.street : ""
        }
    }

    return (
        <div className={wrapperClasses} data-cy="issue-details">
            <header className={headerClasses}>
                {/* {(!!priority && priority != 0) && <PriorityTag priority={getPriorityType(priority)!} />} */}
                <span className={styles["headerStatus"]}>
                    Status: {getFormattedStatusText(props.issue.status || "")}
                </span>
                <span className={styles["headerCaseNumber"]}><span className={styles["sak"]}>SAK</span> #{props.issue.serviceDeskId}</span>
            </header>
            <section className={`${styles['section']}`}>
                <div className={styles["halfWidth"]}>
                    <h1 className={styles['heading']}>{title}</h1>
                </div>
                <div className={styles["halfWidth"]}>
                    <p className={styles['issue-location']}>
                        {/* <LocationIcon /> */}
                        <span>{formatLocationString(props.preferredBuilding ? props.preferredBuilding.street : "", floor, room)}</span>
                    </p>
                    <div className={styles['issue-info']}>
                        <span className={styles['issue-id']}>
                            {formatIssueReportedDateOnlyString(props.issue)}
                        </span>
                        <span>{props.issue.userInfo ? ` av ${props.issue.userInfo.name}` : ""}</span>
                        <span>{props.issue.contractName ? `${props.issue.contractName}` : ""}</span>
                        <span>{getFormattedCategory(props.issue.category)}</span>
                    </div>

                </div>
            </section>
            <section className={styles['scroll-section']}>
                <div>
                    <AttachmentPreviewList images={images.map((i: Image) => ({ id: i.id, name: i.name, issueId: issueId }))}
                        documents={documents.map((i: DocumentAttachment) => ({ id: i.id, name: i.name, issueId: issueId }))}
                        onRemoveAttachment={() => null} />

                    <Dialog className={styles['dialog']}
                        //todo
                        messages={comments.map(comment => mapCommentToMessage(comment, images.filter(i => i.commentId == comment.id), documents.filter(i => i.commentId == comment.id), userInfo))}
                        onSubmitNewMessage={props.onSubmitNewMessage}
                        status={status}
                        issueId={issueId} />
                </div>
            </section>
        </div>
    )
}

IssueDetails.displayName = "IssueDetails"

export default IssueDetails