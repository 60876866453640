import * as React from 'react'

import styles from './Authentication.module.scss'
import { Route, RouteComponentProps, Switch, Link } from "react-router-dom";
import LoginForm from "./forms/LoginForm";
import RegistrationForm from "./forms/RegistrationForm";
import ValidationInfo from "./ValidationInfo";

import SetNewPasswordVerification from "./SetNewPasswordVerification";
import ResetPassword from "./ResetPassword";
import EmailVerification from "./EmailVerification";
import { getBackground, getIcon, getLogo, getMainHeading } from "../../hosts/HostHandling";
import { useEffect } from 'react';

export const validatePassword = (password: string, passwordConfirm: string): string | undefined => {
    const passwordRequirements = new RegExp("(\\w|\\d){5,}") // todo: actual requirements?
    if (password != passwordConfirm) {
        return "Passordene er ikke like"
    }
    if (!passwordRequirements.test(password)) {
        return `Passord oppfyller ikke kravene`
    }

    return
}


const Authentication = () => {

    useEffect(() => {
        const favicon16 = document.getElementById("favicon16") as HTMLAnchorElement;
        const favicon32 = document.getElementById("favicon32") as HTMLAnchorElement;
        const favicon96 = document.getElementById("favicon96") as HTMLAnchorElement;
        const path = getIcon();
        if (favicon16 != null) {
            favicon16.href = path + "16x16.png";
        }
        if (favicon32 != null) {
            favicon32.href = path + "32x32.png";
        }
        if (favicon96 != null) {
            favicon96.href = path + "96x96.png";
        }
    })

    return (<div className={styles['wrapper']}>
        <div className={styles[getBackground()]}>
            <div className={styles['grid-container']}>
                {getMainHeading()}
                <section className={styles['login-box']}>
                    {getLogo()}
                    <Switch>
                        <Route exact path="/auth/login" component={LoginForm} />

                        <Route exact path="/auth/register" component={RegistrationForm} />

                        <Route exact path="/auth/register/validate" component={EmailVerification} />

                        <Route exact path="/auth/password/reset" render={() => (
                            <ResetPassword />
                        )} />

                        <Route exact path="/auth/password/new" render={(routeProps: RouteComponentProps) => (
                            <SetNewPasswordVerification {...routeProps} />
                        )} />
                        <Route exact path="/auth/validation" render={() => (
                            <ValidationInfo />
                        )} />
                    </Switch>
                </section>

                <span className={styles['copyright']}>Copyright 2019 Newsec</span>
            </div>
        </div>
    </div>)
}


export default Authentication