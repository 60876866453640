import * as React from "react";
import styles from "./NewIssueCard.module.scss";
import classNames from "classnames";

import { Card, CardProps, LinkCard } from "../Card/Cards";
import { IssueType } from "../../types/Issue";
import ArrowIcon from "../Icons/ArrowIcon";
import { formatIssueIdAndReportedDateString, formatLocationString, getFormattedStatusText, getFormattedCategory, formatIssueReportedDateString, formatIssueReportedDateStringCompact } from "../../tools/formatters";
import { ReactComponent as EmailTimeoutIcon } from '../Icons/EmailTimeoutIcon.svg'
import { ReactComponent as FileAddIcon } from '../Icons/FileAddIcon.svg'
import { ReactComponent as EmailOpenIcon } from '../Icons/EmailOpenIcon.svg'
import { ReactComponent as MediumPriorityIcon } from '../Icons/MediumPriorityIcon.svg'

interface IssueCardBaseProps extends CardProps {
    data: IssueType,
    compactView: boolean
}

interface IssueLinkCard extends IssueCardBaseProps {
    cardType: 'link'
    to: string,
    compactView: boolean
}

interface IssueCard extends IssueCardBaseProps {
    cardType: 'card'
}

const StatusIcon = ({ status }: { status: string | undefined }) => {
    if (!status) return null

    switch (status) {
        case "NEW": return <FileAddIcon className={styles["status-icon"]} />

        case "ASSIGNED_TO_AGENT": return <EmailOpenIcon className={styles["status-icon"]} />

        case "AWAITING_RESPONSE": // Intentional fallthrough
        case "WAITING_FOR_THIRD_PARTY": return <EmailTimeoutIcon className={styles["status-icon"]} />

        case "SOLVED": // Intentional fallthrough
        case "CLOSED": // Intentional fallthrough
        default: return null
    }

}

const PriorityBody = ({ priority }: { priority?: number }) => {
    let classes = classNames(styles['priority-icon'])
    switch (priority) {
        case 1:
            classes = classNames(classes, styles['priority-icon--high'])
            return (
                <><ArrowIcon className={classes} /> Høy</>
            )
        case 2:
            classes = classNames(classes, styles['priority-icon--medium'])
            return (
                <><MediumPriorityIcon className={classes} /> Medium</>
            )
        case 3:
            classes = classNames(classes, styles['priority-icon--low'])
            return (
                <><ArrowIcon className={classes} /> Lav</>
            )
        default:
            return null
    }
}

const IssueCardBody = (props: { data: IssueType, compactView: boolean }) => {
    // Compact view
    if (props.compactView) return (
        <div className={[styles['issue-card-section-compact'], "ms-Grid-row"].join(" ")}>
            <span className={[styles['description'], "ms-Grid-col ms-sm4 ms-md4 ms-lg4"].join(" ")}>
                {props.data.title}
            </span>
            <span className={[styles['status'], "ms-Grid-col ms-sm3 ms-md3 ms-lg3"].join(" ")}>
                Status: {getFormattedStatusText(props.data.status!)}
                <br></br>
                {getFormattedCategory(props.data.category)}
            </span>
            <span className={[styles['addedDate'], "ms-Grid-col ms-sm3 ms-md3 ms-lg3"].join(" ")}>
                {formatIssueReportedDateStringCompact(props.data)}
            </span>
            <span className={[styles['id'], "ms-Grid-col ms-sm2 ms-md2 ms-lg2"].join(" ")}>
                {props.data.serviceDeskId ? `#${props.data.serviceDeskId}` : "-"}
            </span>
        </div>
    )
    // Expanded view
    return (
        <div className={styles['issue-card-section']}>
            <p className={styles['description']}>
                {props.data.title}
            </p>
            <p className={styles['location']}>
                {formatLocationString(props.data.address, props.data.floor, props.data.room)}
            </p>

            <p className={styles['id']}>
                {props.data.serviceDeskId ? `#${props.data.serviceDeskId}` : "-"}
            </p>
            <div className="ms-Grid-row">
                <p className={[styles['addedDate'], "ms-Grid-col ms-sm6 ms-md6 ms-lg6"].join(" ")}>
                    {formatIssueReportedDateString(props.data)}
                </p>
                <p className={[styles['status'], "ms-Grid-col ms-sm6 ms-md6 ms-lg6"].join(" ")}>
                    Status: {getFormattedStatusText(props.data.status!)}
                    <br></br>
                    {getFormattedCategory(props.data.category)}
                </p>
            </div>
        </div>
    )
}

const NewIssueCard = (props: IssueLinkCard | IssueCard) => {
    const {
        data,
        tagType,
        className = "",
        ...restProps
    } = props

    // Delete cardType before sending props down as HTML attributes
    const htmlAttributes = { ...restProps }
    delete htmlAttributes.cardType

    if (props.cardType === 'link') {
        const linkClasses = classNames(styles['issue-card-body'], {
            [styles['issue-card-body--priority-high']]: data.priority === 1,
            [styles['issue-card-body--priority-medium']]: data.priority === 2,
            [styles['issue-card-body--priority-low']]: data.priority === 3,
            [styles['issue-card-body--new-updates']]: data.updatesSinceLastViewed > 0,
        })

        const listItemClasses = classNames(styles['issue-card-body'], {
            [styles['waitingCase']]: data.status == "NEW" || data.status == "AWAITING_RESPONSE" || data.status == "WAITING_FOR_THIRD_PARTY",
            [styles['finishedCase']]: data.status == "CLOSED" || data.status == "SOLVED",
            [styles['openCase']]: data.status == "ASSIGNED_TO_AGENT"
        })

        const { to } = props

        return (
            <LinkCard to={to}
                tagType={tagType}
                className={className}
                linkClassName={linkClasses}
                listItemClasses={listItemClasses}
                compactView={props.compactView}
                {...htmlAttributes}>
                <IssueCardBody data={data} compactView={props.compactView} />
            </LinkCard>
        )
    }

    if (props.cardType === 'card') {
        const classes = classNames({
            [styles['issue-card-body--priority-high']]: data.priority === 1,
            [styles['issue-card-body--priority-medium']]: data.priority === 2,
            [styles['issue-card-body--priority-low']]: data.priority === 3,
            [styles['issue-card-body--new-updates']]: data.updatesSinceLastViewed > 0,
            [className]: !!className
        })
        return (
            <Card tagType={tagType}
                className={classes}
                {...htmlAttributes}>
                <IssueCardBody data={data} compactView={props.compactView} />
            </Card>
        )
    }

    throw new Error('Unknow cardType')
}

NewIssueCard.displayName = "NewIssueCard"

export default NewIssueCard

