import {ServerError, ServerParseError} from 'apollo-link-http-common'
import {ApolloClient} from "apollo-client";
import {ApolloLink, split} from "apollo-link";
import {onError} from "apollo-link-error";
import {createHttpLink} from "apollo-link-http";
import {InMemoryCache} from "apollo-cache-inmemory";
import {WebSocketLink} from "apollo-link-ws";
import {getMainDefinition} from 'apollo-utilities'
import {OperationDefinitionNode} from "graphql";
import {SubscriptionClient} from "subscriptions-transport-ws";
import history from './History'

function isOfTypeServerError(error: Error | ServerError | ServerParseError | undefined): error is ServerError {
    if (!error) return false
    return (error as ServerError).statusCode !== undefined
}

export default () => {
    const errorLink = onError(({graphQLErrors, networkError}) => {
        if (networkError) {
            if (isOfTypeServerError(networkError)) {
                if ([403, 401].includes(networkError.statusCode)) {
                    localStorage.setItem("isAuthenticated", "false")
                    history.push("/auth/login")
                }
            }
        } else if (graphQLErrors) {
            throw graphQLErrors
        } else {
            throw Error("Something happened")
        }
    })

    const httpLink = createHttpLink({
        uri: "/api/graphql",
        credentials: "include"
    })

    let protocol = window.location.protocol === "https:" ? "wss:" : "ws:"
    let websocketUri = `${protocol}//${window.location.host}/api/subscriptions`

    const subscriptionClient = new SubscriptionClient(websocketUri, {
        reconnect: true,
        reconnectionAttempts: 5,
        inactivityTimeout: 86400000, // Need this and "timeout" to prevent ws reconnect all the time
        timeout: 86400000
    })

    const wsLink = new WebSocketLink(subscriptionClient)
    const protocolLinks = split(({query}) => {
        const {kind, operation} = getMainDefinition(query) as OperationDefinitionNode;
        return kind === 'OperationDefinition' && operation === 'subscription'
    }, wsLink, httpLink)

    return new ApolloClient({
        link: ApolloLink.from([
            errorLink,
            protocolLinks
        ]),
        cache: new InMemoryCache()
    })
}
