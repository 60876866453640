import * as React from 'react'
import { gql } from "apollo-boost";
import { Query } from "react-apollo";
import DocumentsTable from "./DocumentsTable";
import { Contract, UserInfo } from "../../types/UserInfo";
import CenteredLoadingIcon from "../../viewComponents/CenteredLoadingIcon/CenteredLoadingIcon";
import styles from './Brannbok.module.scss'

interface DocumentContainerProps {
    userInfo: UserInfo
    preferredContract: Contract
}

const Brannbok = (props: DocumentContainerProps) => {
    const [NoteBox1Visible, setNoteBox1Visible] = React.useState(false)
    const [NoteBox2Visible, setNoteBox2Visible] = React.useState(false)

    const QUERY = gql`
        query DocumentQuery($contractId: String!) {
            brannbok(contractId: $contractId) {
                name
                lastModified
                documentType {
                    id
                    name
                }
                fileUrl
                fileName
            }
        }
    `

    return (
        <div>
            <Query query={QUERY} variables={{ contractId: props.preferredContract.contractId }} partialRefetch={true}>
                {({ loading, error, data }) => {
                    if (loading) return <CenteredLoadingIcon text={"Laster dokumenter"} />
                    if (error) return <span>Feil ved lasting av dokumenter</span>
                    return (
                        <DocumentsTable isBuildingOwner={false} documents={data.brannbok} title="BRANNBOK" />
                    )
                }}
            </Query>
            <span className={styles.brannbokNoteWrapper}>
                <span className={styles.brannbokHeaderBtn} onClick={() => { setNoteBox1Visible(!NoteBox1Visible) }}>
                    Brannvernorganisasjon
                </span>
                <div className={NoteBox1Visible ? styles.expandable : styles.hiddenNote}>
                    <ul>
                        <span className={styles.sectionHeader}>Eier (eiendomsselskap) </span>
                        <li>Har det øverste ansvaret for brannvernet.</li>
                        <li>Oppgaver utføres normalt av teknisk forvalter</li>
                        <span className={styles.sectionHeader}>Teknisk forvalter</span>
                        <li>Forvalter brannvernarbeidet på vegne av eier</li>
                        <li>Skal sjekke at alle funksjoner utfører sine pålagte kontroller og av avvik blir lukket.</li>
                        <li>Kontaktperson mot brannmyndigheten ved tilsyn o.l.</li>
                        <li>Enkelte arbeidsoppgaver delegeres eiers representant ved behov.</li>
                        <span className={styles.sectionHeader}>Driftstekniker</span>
                        <li>Skal foreta kontroller av brannvernet i henhold til sjekklister og skal sørge for de daglige egenkontrollene.</li>
                        <li>Vaktmester skal i hovedsak følge opp feil og mangler som avdekkes.</li>
                        <li>Skal også følge opp leietakers arbeider knyttet til brannvern</li>
                        <span className={styles.sectionHeader}>Eiers representant brann</span>
                        <li>Gjennomfører brannrunder som en del av eiers revisjon av det systematiske brannvernarbeidet</li>
                        <li>Gjennomfører øvelser på vegne av leietakere</li>
                        <li>Bistå drift og teknisk forvalter med lukking av avvik etter kontroll og ettersyn.</li>
                        <span className={styles.sectionHeader}>Leietakers brannvernansvarlig</span>
                        <li>Ansvarlig for leietakers brannforebyggende arbeid iht. Forskrift om brannforebygging</li>
                        <li>Ansvarlig for at leietaker gjennomfører et systematisk sikkerhetsarbeid (internkontroll)</li>
                    </ul>

                </div>
            </span>
            <span className={styles.brannbokNoteWrapper}>
                <span className={styles.brannbokHeaderBtn} onClick={() => { setNoteBox2Visible(!NoteBox2Visible) }}>
                    Samordning av brannvernet
                </span>
                <div className={NoteBox2Visible ? styles.expandable : styles.hiddenNote}>
                    <span className={styles.sectionHeader}>Forskrift om brannforebygging fordeler plikter mellom eier og bruker av byggverket (leietaker/fremleietaker/eier).</span>
                    <p>Der det er flere som har rett til å bruke byggverket skal eier sikre at all bruk samordnes på en måte som bidrar til å forbygge brann. </p>
                    <p>Ved behov skal det etableres samordningsavtaler der dette anses som nødvendig for å forebygge brann. </p>
                    <p>I enkelte tilfeller er det gjort avtaler om at kontroll og vedlikehold gjennomføres av leietaker, gjerne i leieavtale, men det er eiers ansvar at dette faktisk blir gjennomført.</p>

                    <span className={styles.sectionHeader}>Eier er i hovedtrekk ansvarlig for:</span>
                    <p>Kunnskap og informasjon om brannsikkerhet i byggverket - Eier skal kjenne til hvilke krav til brannsikkerhet som gjelder for byggverket.
                    Eier skal gjøre den som har rett til å bruke byggverket kjent med kravene som gjelder for bruken av byggverket, og med alle egenskaper ved byggverket som har betydning for brannsikkerheten.</p>
                    <p>Kontroll og vedlikehold av bygningsdeler og sikkerhetsinnretninger - Eieren av et byggverk skal sørge for at bygningsdeler, installasjoner og utstyr i byggverket som skal oppdage brann eller begrense konsekvensene av brann, blir kontrollert og vedlikeholdt slik at de fungerer som forutsatt.
                    Kontrollern skal avklare om sikkerhetsinnretningene oppfyller kravene til brannsikkerhet som gjelder for byggverket og fungerer hver for seg og sammen med hverandre.</p>
                    Systematisk sikkerhetsarbeid- Eiers skal iverksette et systematisk sikkerhetsarbeid med;
                    rutiner som sikrer at kravene til brannsikkerhet som gjelder for byggverket blir overholdt

                    rutiner for å avdekke, rette opp og forebygge mangler ved bygningsdeler, installasjoner og utstyr som skal oppdage brann eller begrense konsekvensene av brann
                    rutiner for å avdekke, rette opp og forebygge mangler ved det systematiske sikkerhetsarbeidet
                    Dokumentasjon - Eier skal dokumentere at virksomhetens brannforbyggende plikter er oppfylt.

                    <span className={styles.sectionHeader}>Leietaker er i hovedtrekk ansvarlig for:</span>
                    <ul>
                        <li>
                            at byggverket brukes i samsvar med kravene til brannsikkerhet som gjelder for byggverket</li>
                        <li>unngå unødig risiko for brann, og sørge for at rømningsveiene opprettholder sin funksjon, herunder at fremkommeligheten ikke reduseres</li>
                        <li>informere eieren om endringer, forfall og skader i byggverket eller sikkerhetsinnretningene som kan påvirke sikkerheten mot brann</li>
                        <li>ved forhold som vesentlig reduserer brannsikkerheten, straks gjennomføre ekstraordinære tiltak inntil risikoen er normalisert.</li>
                    </ul>
                    <span className={styles.sectionHeader}>Leietaker skal iverksette et systematisk sikkerhetsarbeid, som innbefatter</span>
                    <ul>
                        <li>rutiner som sikrer at byggverket brukes i samsvar med kravene til brannsikkerhet som gjelder for byggverket</li>
                        <li>rutiner for evakuering og redning ved brann i byggverket</li>
                        <li>rutiner som sikrer at personer med arbeidsplass i byggverket har tilstrekkelige kunnskaper og ferdigheter i å forebygge og bekjempe brann</li>
                        <li>rutiner som sikrer at alle som oppholder seg i byggverket får tilstrekkelig informasjon om hvordan de skal unngå brann og opptre ved brann</li>
                        <li>rutiner for å avdekke, rette opp og forebygge mangler ved det systematiske sikkerhetsarbeidet.</li>
                    </ul>
                    <span className={styles.sectionHeader}>Dokumentasjon</span>
                    <p>Leietaker skal dokumentere at virksomhetens brannforbyggende plikter er oppfylt</p>
                </div>
            </span>
        </div>
    )
}

export default Brannbok