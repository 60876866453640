import * as React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import styles from './Cards.module.scss'
import { TagType } from "../../types/TagType";

export interface CardProps {
    tagType: TagType
    children?: React.ReactNode
    className?: string
}

export const Card = (props: CardProps) => {
    const {
        tagType,
        className = "",
        children,
        ...restProps
    } = props

    const cardClasses = classNames(styles["card"], {
        [className]: !!className
    })

    switch (tagType) {
        case TagType.li:
            return (
                <li className={cardClasses} {...restProps}>
                    {children}
                </li>
            )
        case TagType.div:
            return (
                <div className={cardClasses} {...restProps}>
                    {children}
                </div>
            )
        default:
            throw Error('Unknow tagType')
    }
}
Card.propTypes = {
    tagType: PropTypes.string.isRequired
}
Card.displayName = "Card"


export interface LinkCardProps extends CardProps {
    to: string
    linkClassName?: string,
    listItemClasses?: string,
    compactView?: boolean,
    onClick?: Function
}

export const LinkCard = (props: LinkCardProps) => {
    const {
        tagType,
        className = "",
        linkClassName = "",
        children,
        to,
        listItemClasses,
        compactView,
        onClick,
        ...restProps
    } = props

    const cardClasses = classNames(styles['link-card'], {
        [className]: !!className
    })

    const linkClasses = classNames(styles['link'], {
        [linkClassName]: !!linkClassName
    })

    switch (tagType) {
        case TagType.li:
            return (
                <li className={[cardClasses, listItemClasses].join(" ")} {...restProps} onClick={() => { if (onClick) { onClick() } }}>
                    <Link to={to} className={linkClasses}>
                        {children}
                    </Link>
                </li>
            )
        case TagType.div:
            return (
                <div className={cardClasses} {...restProps} onClick={() => { if (onClick) { onClick() } }}>
                    <Link to={to} className={linkClasses}>
                        {children}
                    </Link>
                </div>
            )
        default:
            throw Error('Unknow tagType')
    }
}
LinkCard.propTypes = {
    tagType: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired
}
LinkCard.displayName = "LinkCard"
