import * as React from 'react'

import styles from './BuildingIcon.module.scss'

interface Props extends React.ComponentProps<"svg"> {
    roundBackground?: boolean
}

const BuildingIcon = (props: Props) => {

    const {
        roundBackground = false,
        className = ""
    } = props

    return (
        <div className={`${styles["wrapper"]} ${roundBackground ? styles["round"] : ""} ${className}`}>
            <svg width="21px" height="23px" viewBox="0 0 21 23" version="1.1" xmlns="http://www.w3.org/2000/svg"
                 xmlnsXlink="http://www.w3.org/1999/xlink">
                <g id="web_gårdeierdashboard-(1)" transform="translate(-211.000000, -161.000000)">
                    <g id="Group-3" transform="translate(209.000000, 160.000000)">
                        <g id="Filled_Icons" transform="translate(2.000000, 1.000000)" fillRule="nonzero">
                            <g id="Group">
                                <path
                                    d="M20.267415,8.59937143 L16.487415,6.62794286 C16.194465,6.47515714 15.846705,6.49092857 15.56793,6.67131429 C15.2901,6.85071429 15.12,7.16811429 15.12,7.51015714 L15.12,11.04 L17.955,12.8142857 L17.955,22.6714286 L19.845,22.6714286 C20.36664,22.6714286 20.79,22.2308143 20.79,21.6857143 L20.79,9.48158571 C20.79,9.10701429 20.58777,8.76595714 20.267415,8.59937143 Z"
                                    id="Path"/>
                                <path
                                    d="M5.277825,5.49634286 C5.03118,5.31102857 4.714605,5.26174286 4.42638,5.36031429 L0.64638,6.67525714 C0.259875,6.80931429 0,7.18684286 0,7.6107 L0,21.6857143 C0,22.2308143 0.42336,22.6714286 0.945,22.6714286 L4.725,22.6714286 C5.24664,22.6714286 5.67,22.2308143 5.67,21.6857143 L5.67,6.29575714 C5.67,5.97934286 5.52447,5.68165714 5.277825,5.49634286 Z M3.78,19.7142857 L1.89,19.7142857 L1.89,17.7428571 L3.78,17.7428571 L3.78,19.7142857 Z M3.78,15.7714286 L1.89,15.7714286 L1.89,13.8 L3.78,13.8 L3.78,15.7714286 Z M3.78,11.8285714 L1.89,11.8285714 L1.89,9.85714286 L3.78,9.85714286 L3.78,11.8285714 Z"
                                    id="Shape"/>
                                <path
                                    d="M15.542415,13.5279429 C15.16158,13.3288286 13.608945,12.4426714 13.23,12.4426714 L13.23,2.68212857 C13.23,2.25827143 12.970125,1.88172857 12.58362,1.74767143 L8.80362,0.432728571 C8.515395,0.333171429 8.19882,0.383442857 7.952175,0.568757143 C7.70553,0.753085714 7.56,1.05077143 7.56,1.36718571 L7.56,2.95714286 L11.34,2.95714286 L11.34,4.92857143 L7.56,4.92857143 L7.56,6.9 L11.34,6.9 L11.34,8.87142857 L7.56,8.87142857 L7.56,10.8428571 L11.34,10.8428571 L11.34,12.8142857 L7.56,12.8142857 L7.56,21.6857143 C7.56,22.2308143 7.98336,22.6714286 8.505,22.6714286 L10.395,22.6714286 L10.395,21.1928571 C10.395,20.3766857 11.03004,19.7142857 11.8125,19.7142857 C12.59496,19.7142857 13.23,20.3766857 13.23,21.1928571 L13.23,22.6714286 L16.065,22.6714286 L16.065,14.4101571 C16.065,14.0355857 15.86277,13.6945286 15.542415,13.5279429 Z M14.175,16.7571429 L9.45,16.7571429 L9.45,14.7857143 L14.175,14.7857143 L14.175,16.7571429 Z"
                                    id="Shape"/>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    )
}

export default BuildingIcon