import * as React from 'react'
import { RouteComponentProps } from "react-router";
import IssueDetails from "../../../viewComponents/IssueDetails/IssueDetails";
import { Mutation, MutationFn, Query } from "react-apollo";
import { IssueType } from "../../../types/Issue";
import { ADD_COMMENT, COMPONENT_QUERY, ISSUE_UPDATE_SUBSCRIPTION, SET_VIEWED } from "./IssueDetailQueries";
import { Contract, UserInfo, Building } from "../../../types/UserInfo";
import FullHeightModal from "../../../viewComponents/Modal/FullHeightModal";
import CenteredLoadingIcon from "../../../viewComponents/CenteredLoadingIcon/CenteredLoadingIcon";

interface Params {
    id: string
}

const onCreateComment = (mutationFn: MutationFn, content: string, sender: string, issueId: string, imageIds: string[], documentIds: string[]) => {
    mutationFn({
        variables: {
            issueId,
            sender,
            content,
            imageIds,
            documentIds
        },
        optimisticResponse: {
            __typename: "CommentOptimisticResponse",
            updateIssue: {
                __typename: "CommandResponse",
                status: "OK",
                message: "Optimistic response"
            },
            comment: {
                __typename: "Comment",
                id: "new",
                senderName: "Lagrer...",
                senderUsername: sender,
                created: new Date().toISOString(),
                isSystemMessage: false,
                content,
                sender,
                issueId,
                imageIds,
                documentIds
            }
        },
        update: (proxy, { data }) => {
            if (data.__typename == "CommentOptimisticResponse") {
                const issueData: any = proxy.readQuery({
                    query: COMPONENT_QUERY,
                    variables: {
                        id: issueId
                    }
                })

                proxy.writeQuery({
                    query: COMPONENT_QUERY,
                    data: {
                        ...issueData,
                        issue: {
                            ...issueData.issue,
                            comments: [
                                ...issueData.issue.comments,
                                data.comment
                            ]
                        }
                    }
                })
            }
        }
    })
}

interface ContainerProps extends RouteComponentProps<Params> {
    basePath: string
    contractId?: string
    buildingId?: string
    preferredBuilding: Building
}

const IssueDetailContainer = (props: ContainerProps) => {

    const componentQueryVariables: {
        id: string
        contractId?: string
        buildingId?: string
        username?: string
    } = { id: props.match.params.id }

    return (
        <Mutation mutation={ADD_COMMENT} ignoreResults={true}>
            {(updateIssue) => (
                <Mutation mutation={SET_VIEWED}>
                    {(setIssueViewed) => (
                        <Query query={COMPONENT_QUERY} variables={componentQueryVariables} partialRefetch={true}>
                            {({ subscribeToMore, loading, error, data }) => {
                                if (loading || !data.issue) {
                                    // TODO: Should be fixed.
                                    return (
                                        <FullHeightModal closeButtonText={"Lukk"}
                                            onClose={() => props.history.push("/")}>
                                            <div style={{ backgroundColor: "white" }}>
                                                <span style={{ padding: "3.2rem", display: "block" }}>
                                                    <CenteredLoadingIcon text={"Laster sak"} />
                                                </span>
                                            </div>
                                        </FullHeightModal>
                                    )
                                }
                                if (error) return <p>Error :(</p>

                                return (
                                    <IssueDetail {...props}
                                        data={data}
                                        subscribeToMore={() => subscribeToMore({
                                            document: ISSUE_UPDATE_SUBSCRIPTION,
                                            variables: {
                                                id: props.match.params.id,
                                                contractId: props.contractId,
                                                buildingId: props.buildingId,
                                                username: data.userInfo.username
                                            },
                                            updateQuery: (prev, { subscriptionData }) => {
                                                if (!subscriptionData.data) return prev

                                                const issueUpdate = subscriptionData.data.issueUpdates

                                                if (issueUpdate.type === "UPDATED" && issueUpdate.issue.id === props.match.params.id) {
                                                    return Object.assign({}, prev, {
                                                        issue: issueUpdate.issue
                                                    })
                                                } else {
                                                    return prev
                                                }
                                            }
                                        })}
                                        setIssueViewed={setIssueViewed}
                                        onCreateComment={(message: string, imageIds: string[], documentIds: string[]) => onCreateComment(updateIssue, message, data.userInfo.username, props.match.params.id, imageIds, documentIds)}
                                        preferredBuilding={props.preferredBuilding}
                                    />
                                )
                            }}
                        </Query>
                    )}
                </Mutation>
            )}
        </Mutation>
    )
}

interface Props extends RouteComponentProps<Params> {
    data: {
        issue: IssueType
        userInfo: UserInfo
    }
    onCreateComment: (message: string, imageIds: string[], documentIds: string[]) => void

    subscribeToMore(): void

    setIssueViewed: MutationFn
    basePath: string
    preferredBuilding: Building
}

class IssueDetail extends React.Component<Props, {}> {

    componentDidMount(): void {
        this.props.subscribeToMore()

        const {
            setIssueViewed,
            data: {
                issue: { id: issueId }
            }
        } = this.props

        setIssueViewed({
            variables: {
                issueId
            }
        })
    }

    onModalClose = () => {
        const {
            history,
            basePath,
            setIssueViewed,
            data: {
                issue: { id: issueId }
            }
        } = this.props

        setIssueViewed({
            variables: {
                issueId
            }
        })
        history.push(!!basePath ? basePath : "/")
    }

    render(): React.ReactNode {
        const { data: { issue, userInfo } } = this.props

        return (
            <FullHeightModal closeButtonText={"Lukk"} onClose={this.onModalClose}>
                <IssueDetails issue={issue}
                    onSubmitNewMessage={(message: string, imageIds: string[], documentIds: string[]) => this.props.onCreateComment(message, imageIds, documentIds)}
                    userInfo={userInfo} preferredBuilding={this.props.preferredBuilding} />
            </FullHeightModal>
        )
    }
}

export default IssueDetailContainer