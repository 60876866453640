import * as React from 'react'
import { FormEvent } from 'react'
import { gql } from "apollo-boost";
import { Query } from "react-apollo";

import styles from './BuildingHeaderBlock.module.scss'
import { Building, Company, UserInfo } from "../../../types/UserInfo";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import ChevronDown from "../../../viewComponents/Icons/ChevronDown";
import BuildingIcon from "../../../viewComponents/Icons/BuildingIcon/BuildingIcon";
import CenteredLoadingIcon from "../../../viewComponents/CenteredLoadingIcon/CenteredLoadingIcon";


interface Props {
    userInfo: UserInfo
    selectedBuildingId: string
    pathname: string
    onPreferredBuildingChange: (event?: FormEvent<HTMLSelectElement>, value?: any) => void
    onPreferredContractChange: (event: FormEvent<HTMLSelectElement>) => void
}

interface State {
    buildingsListExpanded: boolean
}

class BuildingHeaderBlock extends React.Component<Props, State> {

    state: State = {
        buildingsListExpanded: false
    }

    toggleBuildingsListExpanded = () => this.setState({ buildingsListExpanded: !this.state.buildingsListExpanded })

    onBuildingLinkClick = (buildingId) => {
        this.props.onPreferredBuildingChange(undefined, buildingId)
        this.setState({ buildingsListExpanded: false })
    }

    render(): React.ReactNode {
        const { userInfo, selectedBuildingId, pathname } = this.props

        const allBuildings: Building[] = userInfo.companies.reduce((result: Building[], company: Company) => {
            return [...company.buildings, ...result]
        }, [])

        const selectedBuilding: Building | undefined = allBuildings.find(building => building.id === selectedBuildingId)

        const activeSubPageSplit = pathname.split("/")
        const activeSubPage = activeSubPageSplit[activeSubPageSplit.length - 1]

        return (
            <header className={styles["header-block"]}>
                <div className={styles["building-list-select"]}>
                    <button className={styles["building-list-expand-button"]} onClick={this.toggleBuildingsListExpanded}>
                        <BuildingIcon className={styles["building-list-icon"]} />
                        <span className={styles["building-street"]}>{selectedBuilding!.street},</span>&nbsp;
                        <span className={styles["building-city"]}>{selectedBuilding!.city.toLowerCase()}</span>&nbsp;
                        <ChevronDown className={styles["building-list-button-icon"]} />
                    </button>
                </div>

                <ul className={`${styles["buildings-list"]} ${this.state.buildingsListExpanded ? styles["buildings-list--open"] : ""}`}>
                    {allBuildings.filter(b => b.id != selectedBuildingId).map(building => (
                        <li key={building.id}>
                            <Link to={`/building/${building.id}/${activeSubPage}`} className={styles["building-link-item"]}
                                onClick={() => {
                                    this.onBuildingLinkClick(building.id)
                                }
                                }>
                                {building.street}, {building.city.toLowerCase()}
                            </Link>
                        </li>
                    ))}
                </ul>
            </header>
        )
    }
}

interface ContainerProps extends RouteComponentProps {
    selectedBuildingId: string
    onPreferredBuildingChange: (event?: FormEvent<HTMLSelectElement>, value?: any) => void
    onPreferredContractChange: (event: FormEvent<HTMLSelectElement>) => void
}

const BuildingHeaderBlockContainer = (props: ContainerProps) => {
    const BUILDINGS_QUERY = gql`
        query GetBuildings {
            userInfo {
                companies {
                    buildings {
                        id
                        street
                        city
                    }
                }
                roles
            }
        }
    `

    return (
        <Query query={BUILDINGS_QUERY} partialRefetch={true}>
            {({ loading, error, data }) => {
                if (loading) return <CenteredLoadingIcon />
                if (error) return <span>Error :(</span>

                return <BuildingHeaderBlock userInfo={data.userInfo}
                    selectedBuildingId={props.selectedBuildingId}
                    pathname={props.location.pathname}
                    onPreferredBuildingChange={props.onPreferredBuildingChange}
                    onPreferredContractChange={props.onPreferredContractChange} />
            }}
        </Query>

    )
}

export default withRouter(BuildingHeaderBlockContainer)