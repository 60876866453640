import * as React from 'react'

import useEffectSkipMount from "./useEffectSkipMount";

export interface RegisterBody {
    username: string
    password: string
    name: string
}

export interface LoginBody {
    username: string
    password: string
}

export interface NewPasswordBody {
    username: string
    token: string
    password: string
}

export interface PasswordTokenValidateBody {
    username: string
    token: string
}

export const [
    SIGNUP_PATH,
    LOGIN_PATH,
    SIGNUP_VALIDATE_PATH,
    PASSWORD_RESET_PATH,
    PASSWORD_NEW_PATH,
    PASSWORD_TOKEN_VALIDATE_PATH
] = [ "signup", "login", "signup/validate", "password/reset", "password/new", "password/validate" ]

export interface SignuptRequestParams {
    path: typeof SIGNUP_PATH
    body: RegisterBody
}

export interface LoginRequestParams {
    path: typeof LOGIN_PATH
    body: LoginBody
}

export interface SignupValidateRequestParams {
    path: typeof SIGNUP_VALIDATE_PATH
    body: string
}

export interface ResetPasswordParams {
    path: typeof PASSWORD_RESET_PATH,
    body: string
}

export interface NewPasswordParams {
    path: typeof PASSWORD_NEW_PATH
    body: NewPasswordBody
}

export interface PasswordTokenValidateParams {
    path: typeof PASSWORD_TOKEN_VALIDATE_PATH
    body: PasswordTokenValidateBody
}

type ApiParams = SignuptRequestParams | LoginRequestParams | SignupValidateRequestParams | ResetPasswordParams | NewPasswordParams | PasswordTokenValidateParams


type HookParams = {
    callOnMount: true,
    params: ApiParams
} | {
    callOnMount: false,
    params?: never
}

export const useAuthApi = (params: HookParams = { callOnMount: false }) => {
    const [request, setRequest] = React.useState<ApiParams | undefined>(params.callOnMount ? params.params: undefined)

    const [requestStatus, setRequestStatus] = React.useState<{isLoading: boolean, responseStatus?: number}>({
        isLoading: !!params.callOnMount
    })

    const post = async () => {
        if (request) {
            setRequestStatus({isLoading: true})

            const result: number = await fetch(`/api/auth/${request.path}`, {
                method: 'POST',
                headers: {
                    'Content-Type': "application/json"
                },
                body: typeof request.body == 'string' ? request.body : JSON.stringify(request.body)
            }).then(response => response.status)

            setRequestStatus({
                isLoading: false,
                responseStatus: result
            })
        }
    }

    if (params.callOnMount) {
        React.useEffect(() => {
            post()
        }, [request])
    } else {
        useEffectSkipMount(() => {
            post()
        }, [request])
    }

    const doApiCall = (apiParams: ApiParams) => {
        setRequest(apiParams)
    }

    return {
        isLoading: requestStatus.isLoading,
        responseStatus: requestStatus.responseStatus,
        doApiCall
    }
}