import * as React from 'react'
import { gql } from "apollo-boost";
import { Query } from "react-apollo";
import { Route, RouteComponentProps } from "react-router";

import { IssueType } from "../../../types/Issue";
import IssuesList from "../../IssuesDashboard/IssuesList/IssuesList";
import IssueDetailContainer from "../../IssuesDashboard/IssueDetail/IssueDetail";
import CenteredLoadingIcon from "../../../viewComponents/CenteredLoadingIcon/CenteredLoadingIcon";
import { UserInfo, Building } from "../../../types/UserInfo";

export const ISSUES_FOR_BUILDING_QUERY = gql`
    query GetIssues($buildingId: String!) {
        issuesForBuilding(buildingId: $buildingId) {
            id
            title
            reportedDate
            finishedDate
            address
            floor
            room
            priority
            status
            serviceDeskId
            contractId
            contractName
            userInfo {
                name
            }
        }
    }
`

const ISSUES_SUBSCRIPTION_ON_BUILDING = gql`
    subscription IssueUpdates($buildingId: String!, $username: String!) {
        issueUpdates(buildingId: $buildingId, username: $username) {
            type
            issue {
                id
                title
                reportedDate
                finishedDate
                address
                floor
                room
                priority
                status
                serviceDeskId
                contractId
                contractName
                userInfo {
                    name
                }
                comments {
                    id
                    issueId
                    senderName
                    senderUsername
                    content
                    created
                    isSystemMessage
                }
                images {
                    id
                    commentId
                    name
                }
                documents {
                    id
                    commentId
                    name
                }
            }
        }
    }
`

interface Props {
    issues: IssueType[]
    buildingId: string
    subscribeToMore: Function
    userInfo: UserInfo
    preferredBuilding: Building
}




class BuildingOwnerIssues extends React.Component<Props> {

    componentDidMount(): void {
        this.props.subscribeToMore()
    }

    render(): React.ReactNode {

        const { issues, buildingId } = this.props

        return (
            <>
                <IssuesList issues={issues} basePath={`/building/${buildingId}/issues`} userInfo={this.props.userInfo} />
                <Route path={"/building/:buildingId/issues/issue/:id"} render={routeProps => <IssueDetailContainer buildingId={this.props.buildingId} {...routeProps} basePath={`/building/${this.props.buildingId}/issues`} preferredBuilding={this.props.preferredBuilding} />} />
            </>
        )
    }
}
interface Params {
    buildingId: string
}

interface BuildingOwnerIssuesContainerProps extends RouteComponentProps<Params> {
    userInfo: UserInfo
    preferredBuilding: Building
}


const BuildingOwnerIssuesContainer = (props: BuildingOwnerIssuesContainerProps) => {

    const IssuesForBuildingQueryVariables: {
        buildingId: string
        username?: string
    } = { buildingId: props.match.params.buildingId }

    return (
        <Query query={ISSUES_FOR_BUILDING_QUERY} variables={IssuesForBuildingQueryVariables} partialRefetch={true}>
            {({ subscribeToMore, loading, error, data }) => {
                if (loading || error) return <CenteredLoadingIcon text={"Laster saker"} />
                if (error) return <span>Error :(</span>

                return <BuildingOwnerIssues issues={data.issuesForBuilding} buildingId={props.match.params.buildingId} preferredBuilding={props.preferredBuilding} userInfo={props.userInfo} subscribeToMore={() => subscribeToMore({
                    document: ISSUES_SUBSCRIPTION_ON_BUILDING,
                    variables: { buildingId: props.match.params.buildingId, username: props.userInfo.username },
                    updateQuery: (prev, { subscriptionData }) => {
                        if (!subscriptionData.data) return prev

                        const issueUpdate = subscriptionData.data.issueUpdates

                        switch (issueUpdate.type) {
                            case "CREATED":
                                return Object.assign({}, prev, {
                                    issues: [issueUpdate.issue, ...prev.issuesForBuilding]
                                })
                            case "UPDATED":
                                const index = prev.issuesForBuilding.findIndex((i: IssueType) => i.id === issueUpdate.issue.id)
                                if (index === -1) return prev

                                const newIssuesList = [...prev.issuesForBuilding]
                                newIssuesList[index] = issueUpdate.issue

                                return Object.assign({}, prev, {
                                    issues: newIssuesList
                                })
                        }
                    }
                })} />
            }}
        </Query>

    )
}

export default BuildingOwnerIssuesContainer