import * as React from 'react'
import { createRef } from 'react'
import uuidv4 from 'uuid/v4'
import classNames from 'classnames'

import styles from './TextField.module.scss'

const hasValue = (value?: string) => !!value

interface State {
    hasText: boolean,
    isActive: boolean
}

interface TextFieldProps extends React.HTMLProps<HTMLInputElement> {
    alwaysShowLabel?: boolean
    classNames?: string
}

class TextField extends React.Component<TextFieldProps, State> {
    static displayName = "TextField"

    private inputRef = createRef<HTMLInputElement>()

    state = {
        hasText: false,
        isActive: false
    }

    hasText = () => {
        const node = this.inputRef.current
        return hasValue(node ? node.value : undefined)
    }

    onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        this.setState({ isActive: true })
        if (this.props.onFocus) this.props.onFocus(event)
    }

    onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        this.setState({ isActive: false })
        if (this.props.onBlur) this.props.onBlur(event)
    }

    componentDidMount(): void {
        this.setState({ hasText: this.hasText() })
    }

    onChange = (event: React.FormEvent<HTMLInputElement>) => {
        this.setState({ hasText: this.hasText() })

        if (this.props.onChange) {
            this.props.onChange(event)
        }
    }

    render() {
        const {
            placeholder,
            className = "",
            alwaysShowLabel,
            ...htmlAttributes
        } = this.props

        const id = uuidv4()
        const showLabel = alwaysShowLabel || this.state.hasText

        return (
            <div className={classNames(styles['wrapper'], { [className]: className })}
                data-hasvalue={showLabel}>

                <label htmlFor={id} className={styles['label']} data-active={this.state.isActive}>
                    {placeholder}
                </label>

                <input type="text"
                    id={id}
                    ref={this.inputRef}
                    className={[styles['text-field'], this.props.classNames || ""].join(" ")}
                    onChange={this.onChange}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    {...htmlAttributes} />
            </div>

        )
    }
}

export default TextField