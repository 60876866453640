import * as React from 'react'
import { Route, RouteComponentProps } from 'react-router-dom'
import { ChildDataProps, DataProps, graphql, Query } from "react-apollo";
import gql from 'graphql-tag'

import IssuesList from "./IssuesList/IssuesList";
import styles from './IssueDashboard.module.scss'
import { IssueType } from "../../types/Issue";
import IssueDetailContainer from "./IssueDetail/IssueDetail";
import CenteredLoadingIcon from "../../viewComponents/CenteredLoadingIcon/CenteredLoadingIcon";
import { Contract, UserInfo, Building } from "../../types/UserInfo";

export const SERVICE_ORDERS_QUERY = gql`
    query GetIssues($contractId: String!) {
        serviceOrders(contractId: $contractId) {
            id
            title
            reportedDate
            finishedDate
            contractName
            address
            floor
            room
            priority
            status
            serviceDeskId
            contractId
            category {
                id
                name
                parentCategory {
                    id
                    name
                }
            }
            userInfo {
                name
            }
            comments {
                id
                issueId
                senderName
                senderUsername
                content
                created
                isSystemMessage
            }
            images {
                id
                commentId
                name
            }
            documents {
                id
                commentId
                name
            }
            updatesSinceLastViewed
        }
    }
`

const ISSUES_SUBSCRIPTION = gql`
    subscription IssueUpdates($contractId: String!, $username: String!) {
        issueUpdates(contractId: $contractId, username: $username) {
            type
            issue {
                id
                title
                reportedDate
                finishedDate
                address
                floor
                room
                priority
                status
                serviceDeskId
                contractId
                contractName
                userInfo {
                    name
                }
                comments {
                    id
                    issueId
                    senderName
                    senderUsername
                    content
                    created
                    isSystemMessage
                }
                images {
                    id
                    commentId
                    name
                }
                documents {
                    id
                    commentId
                    name
                }
                updatesSinceLastViewed
            }
        }
    }
`

interface IssuesDashboardProps extends RouteComponentProps<{ id: string }> {
    issues: IssueType[]
    buildingId?: string
    contractId?: string
    userInfo: UserInfo
    preferredBuilding: Building
}


class ServiceOrdersDashboard extends React.Component<IssuesDashboardProps, {}> {

    render(): React.ReactNode {
        return (
            <div className={styles["issue-dashboard"]}>
                <IssuesList userInfo={this.props.userInfo} issues={this.props.issues} basePath={""} service={true} />
                <Route exact path="/issue/:id"
                    render={(routerProp) => <IssueDetailContainer buildingId={this.props.buildingId} preferredBuilding={this.props.preferredBuilding}
                        contractId={this.props.contractId} {...routerProp}
                        basePath={""} />} />
            </div>
        )
    }
}


interface IssuesDashboardContainerProps extends RouteComponentProps<{ id: string }> {
    preferredContract: Contract
    preferredBuilding: Building
    userInfo: UserInfo
}

class IssueDashboardContainer extends React.Component<ChildProps> {

    private unsubscribe?: Function

    componentDidMount(): void {
        const {
            userInfo: { username },
            preferredContract: { contractId },
            preferredBuilding,
            data: { refetch, subscribeToMore }
        } = this.props

        this.unsubscribe = subscribeToMore({
            document: ISSUES_SUBSCRIPTION,
            variables: { contractId, username },
            updateQuery: (prev) => {
                refetch()
                return prev
            }
        })
    }

    componentWillUnmount(): void {
        if (this.unsubscribe) this.unsubscribe()
    }

    render(): React.ReactNode {
        const {
            userInfo,
            preferredContract: { contractId },
            preferredBuilding,
            data: { loading: issuesLoading, error: issuesError, serviceOrders },
            ...rest
        } = this.props

        if (issuesLoading) return <CenteredLoadingIcon text={"Laster saker"} />
        if (issuesError || serviceOrders == undefined) return <p>Error :(</p>

        return (
            <ServiceOrdersDashboard {...rest}
                contractId={contractId}
                issues={serviceOrders}
                userInfo={userInfo}
                preferredBuilding={preferredBuilding} />
        )
    }
}


interface ResponseType {
    serviceOrders: IssueType[]
}

interface Variables {
    contractId: string
}

type ChildProps = ChildDataProps<IssuesDashboardContainerProps, ResponseType, Variables>

export default graphql<IssuesDashboardContainerProps, ResponseType, Variables, ChildProps>(SERVICE_ORDERS_QUERY, {
    options: (props: { preferredContract: Contract }) => ({
        variables: { contractId: props.preferredContract.contractId }
    })
})(IssueDashboardContainer)
