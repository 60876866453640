import * as React from 'react'
import classNames from 'classnames'

import styles from './HamburgerButton.module.scss'
import { ReactComponent as HamSVG } from "../../viewComponents/Icons/newDesign/Hamburger-menu.svg";

interface Props extends React.ComponentProps<"button"> {
    isOpen: boolean
}

class HamburgerButton extends React.Component<Props> {
    render(): React.ReactNode {
        const {
            isOpen,
            className = "",
            onClick,
            ...htmlAttributes
        } = this.props

        const buttonClasses = classNames(styles["button"], {
            [styles["button--open"]]: isOpen,
            [className]: !!className
        })

        return (
            <button className={buttonClasses} onClick={onClick} {...htmlAttributes}>
                <HamSVG></HamSVG>
            </button>
        )
    }
}

export default HamburgerButton