import * as React from 'react'
import classNames from 'classnames'

import styles from '../SvgLogo/SvgLogo.module.scss';
import logo from '../SvgLogo/logo_svg.jpg'

// TODO: This should be an svg
const SvgLogo = (props: React.ImgHTMLAttributes<HTMLImageElement>) => {
    const { className = "", ...rest } = props

    const classes = classNames(styles['logo'], {
        [className]: !!className
    })

    return (
        <img src={logo} alt="SVG logo" className={classes} {...rest}/>
    )
}

SvgLogo.displayName = "SvgLogo"

export default SvgLogo