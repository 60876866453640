import * as React from "react";
import {Redirect, RouteComponentProps} from "react-router";
import queryString from "query-string";
import FieldErrorMessage from "../../viewComponents/FieldErrorMessage/FieldErrorMessage";
import styles from "./EmailVerification.module.scss"
import {ConfirmationButton} from "../../viewComponents/Buttons/Buttons";
import LoadingIcon from "../../viewComponents/Icons/LoadingIcon/LoadingIcon";
import {useAuthApi, SIGNUP_VALIDATE_PATH} from "../../hooks/useAuthApi";

const EmailVerification = (props: RouteComponentProps) => {

    const { k: token } = queryString.parse(props.location.search)
    const { isLoading, responseStatus } = useAuthApi({
        callOnMount: true,
        params: {
            path: SIGNUP_VALIDATE_PATH,
            body: `${token}`
        }
    })

    if (isLoading) {
        return (
            <div className={styles["validation"]}>
                <LoadingIcon/>
                <div className={styles["validation-text"]}>Bekrefter e-post...</div>
            </div>
        )
    } else if (responseStatus == 200) {
        return <Redirect to={{ pathname: "/auth/login", state: { emailValidationSuccess: true } }}/>
    } else {
        return (
            <>
                <FieldErrorMessage hidden={false} className={styles['error-message']}>
                    Bekreftelseslenken kan være utgått
                </FieldErrorMessage>
                <h1>Vi kunne ikke bekrefte e-postadressen din</h1>
                <ConfirmationButton onClick={() => props.history.push("/auth/register")}>
                    Registrer på nytt
                </ConfirmationButton>
                <p className={styles["helptext"]}>Dersom problemet vedvarer ta kontakt med <strong><a href={"mailto:kundesenter@newsec.no."}>kundesenter@newsec.no</a></strong>.</p>
            </>
        )
    }
}

export default EmailVerification