import * as React from 'react'
import {gql} from "apollo-boost";
import {Query} from "react-apollo";
import DocumentsTable from "./DocumentsTable";
import {Contract, UserInfo} from "../../types/UserInfo";
import CenteredLoadingIcon from "../../viewComponents/CenteredLoadingIcon/CenteredLoadingIcon";

interface DocumentContainerProps {
    userInfo: UserInfo
    preferredContract: Contract
}

const DocumentsContainer = (props: DocumentContainerProps) => {
    const QUERY = gql`
        query DocumentQuery($contractId: String!) {
            documents(contractId: $contractId) {
                name
                lastModified
                documentType {
                    id
                    name
                }
                fileUrl
            }
        }
    `

    return (
        <Query query={QUERY} variables={{ contractId: props.preferredContract.contractId }} partialRefetch={true}>
            {({ loading, error, data }) => {
                if (loading) return <CenteredLoadingIcon text={"Laster dokumenter"}/>
                if (error) return <span>Feil ved lasting av dokumenter</span>
                return (
                    <DocumentsTable isBuildingOwner={false} documents={data.documents} />
                )
            }}
        </Query>
    )
}

export default DocumentsContainer