import * as React from 'react'
import {Redirect, Route, RouteComponentProps, Switch} from "react-router";
import DashboardLayout from "../features/DashboardLayout/DashboardLayout";
import ImageViewer from "../features/ImageViewer/ImageViewer";

class AuthenticatedRoutes extends React.Component<RouteComponentProps> {
    render(): React.ReactNode {
        const isAuthenticated = localStorage.getItem("isAuthenticated") === "true"

        if (!isAuthenticated) return <Redirect to={{pathname: "/auth/login", state: {from: this.props.location}}}/>

        return (
            <Switch>
                <Route exact path="/issue/:issueId/image/:imageId" component={ImageViewer} />
                <Route exact path="/building/:buildingId/issues/issue/:issueId/image/:imageId" component={ImageViewer} />
                <Route component={DashboardLayout}/>
            </Switch>
        )
    }
}

export default AuthenticatedRoutes