import * as React from 'react'
import queryString from 'query-string'
import { RouteComponentProps } from "react-router";

import styles from './IssueInformationForm.module.scss'
import { FullWidthTextarea, FullWidthTextInput } from "../../../viewComponents/FullWidthTextInput/FullWidthTextField";
import { Mutation, MutationFn, Query } from "react-apollo";
import { gql } from "apollo-boost";
import { Building, Contract, UserInfo } from "../../../types/UserInfo";
import { ISSUE_QUERY } from "../../IssuesDashboard/IssuesDashboard";
import { ISSUES_FOR_BUILDING_QUERY } from "../../BuildingOwnerDashboard/Issues/BuildingOwnerIssues";
import ImageUpload, { IFileUpload } from "../../ImageUpload/ImageUpload";
import CenteredLoadingIcon from "../../../viewComponents/CenteredLoadingIcon/CenteredLoadingIcon";
import { IssueCategory } from "../../../types/IssueCategory";
import ArrowDown from "../../../viewComponents/Icons/ArrowDown";
import { IssueInput } from "../../../types/Issue";
import LoadingIcon from "../../../viewComponents/Icons/LoadingIcon/LoadingIcon";
import {
    buildingFromBuildingId,
    buildingsForUser,
    userIsOwner,
    userNotOwner
} from "../../../tools/userUtils";
import SelectInput from "../../../viewComponents/SelectInput/SelectInput";
import DocumentUpload from '../../DocumentUpload/DocumentUpload';
import IssueCreatedConfirmation from '../IssueCreatedConfirmation/IssueCreatedConfirmation';

interface Props extends RouteComponentProps {
    userInfo: UserInfo,
    issueCategories: IssueCategory[],
    categoryVisibility: {
        name: string,
        fields: { fieldId: string, value: "HIDDEN" | "OPTIONAL" }[]
    }
    preferredBuilding: Building
    preferredContract: Contract,
    isService?: boolean
}

interface State {
    images: IFileUpload[],
    documents: IFileUpload[],
    isLoading: boolean,
    chosenBuilding: Building
}

class IssueInformationForm extends React.Component<Props, State> {

    state: State = {
        images: [],
        documents: [],
        isLoading: false,
        chosenBuilding: buildingsForUser(this.props.userInfo)[0]
    }

    getValueOfFormElement = (formElements: HTMLFormControlsCollection, name: string): string | undefined => {
        const element = (formElements.namedItem(name) as HTMLInputElement)
        if (!element) return undefined

        return element.value
    }

    getFieldFromCategoryVisibility = (fieldName: string) => {
        return this.props.categoryVisibility.fields.find(field => field.fieldId == fieldName)
    }


    shouldRequireField = (fieldName: string): boolean => {
        const field = this.getFieldFromCategoryVisibility(fieldName)
        if (!field) {
            return true
        } else {
            return !["HIDDEN", "OPTIONAL"].includes(field.value)
        }
    }

    shouldShowField = (fieldName: string): boolean => {
        const field = this.getFieldFromCategoryVisibility(fieldName)
        if (!field) {
            return true
        } else {
            return field.value !== "HIDDEN"
        }
    }

    onBuildingChange = (event: React.FormEvent<HTMLSelectElement>, userInfo: UserInfo) => {
        this.setState({ chosenBuilding: buildingFromBuildingId(event.currentTarget.value, userInfo) })
    }

    onSubmitForm = (event: React.FormEvent<HTMLFormElement>, createIssue: MutationFn) => {
        event.preventDefault()
        const { topCategory, subCategory, isService } = queryString.parse(this.props.location.search)
        const formElements = event.currentTarget.elements

        const buildingId = userIsOwner(this.props.userInfo) ? (formElements.namedItem("building-id") as HTMLInputElement).value : this.props.preferredBuilding.id
        const contractId = userIsOwner(this.props.userInfo) ? this.getValueOfFormElement(formElements, "contract-id") : this.props.preferredContract.contractId
        const floor = this.getValueOfFormElement(formElements, "floor")
        const room = this.getValueOfFormElement(formElements, "room")

        let newIssue: IssueInput = {
            title: (formElements.namedItem("title") as HTMLInputElement).value,
            buildingId: buildingId,
            floor: floor,
            room: room,
            priority: 2,
            category: subCategory ? subCategory as string : topCategory as string,
            descriptionComment: {
                sender: this.props.userInfo.username,
                content: (formElements.namedItem("description") as HTMLInputElement).value,
                imageIds: this.state.images.map(f => f.id),
                documentIds: this.state.documents.map(d => d.id),
            },
            isService: !!isService
        }

        if (!!contractId && contractId != "") {
            newIssue.contractId = contractId
        }

        this.setState({ isLoading: true }, () => {
            createIssue({
                variables: newIssue
            }).then(() => {
                this.props.history.push({
                    pathname: "/new-issue/confirmation",
                    state: {
                        issue: newIssue
                    }
                })
            })
        })
    }

    refetchQueries = () => {
        if (userIsOwner(this.props.userInfo)) {
            return {
                query: ISSUES_FOR_BUILDING_QUERY,
                variables: { buildingId: this.state.chosenBuilding.id }
            }
        } else {
            return {
                query: ISSUE_QUERY,
                variables: { contractId: this.props.preferredContract.contractId }
            }
        }
    }

    render(): React.ReactNode {
        const {
            userInfo,
            issueCategories,
            preferredBuilding,
            preferredContract
        } = this.props

        const queryParams = queryString.parse(this.props.location.search)
        const titleDefaultValue = queryParams.title ? queryParams.title : ""
        const descriptionDefaultValue = queryParams.description ? queryParams.description : ""

        // Find the selected categories from id in query params.
        const {
            topCategory: topCategoryId,
            subCategory: subCategoryId
        } = queryString.parse(this.props.location.search)

        const topCategory: IssueCategory = issueCategories.find(c => c.id.toLowerCase() === topCategoryId!.toString().toLowerCase())!
        const subCategory: IssueCategory | undefined = subCategoryId ? topCategory.subCategories!.find(c => c.id.toLowerCase() == subCategoryId!.toString().toLowerCase())! : undefined

        const buildingDropdownContent = buildingsForUser(userInfo).map(building => {
            return { id: building.id, displayValue: building.street }
        })

        const contractDropdownContent = this.state.chosenBuilding.contracts.map(contract => {
            return { id: contract.contractId, displayValue: contract.name }
        })

        return (
            <Mutation mutation={CREATE_ISSUE_MUTATION} ignoreResults={true} refetchQueries={() => [this.refetchQueries()]}>
                {(createIssue) => (
                    <form className={styles['form']} onSubmit={(e) => this.onSubmitForm(e, createIssue)}>
                        <fieldset>
                            <legend>Hvor?</legend>

                            {this.shouldShowField("building-id") && userNotOwner(userInfo) && (
                                <FullWidthTextInput name="building-id"
                                    placeholder="Bygning"
                                    value={preferredBuilding.street}
                                    disabled={this.state.isLoading}
                                    readOnly />
                            )}

                            {userIsOwner(userInfo) && (
                                <div className={styles['flex-left-column']}>
                                    <div className={styles['select-dropdown']}>
                                        <label htmlFor={"building-select"}>Bygg</label>
                                        <SelectInput name={"building-id"}
                                            id={"building-select"}
                                            optionsWithId={buildingDropdownContent}
                                            onChange={(e) => this.onBuildingChange(e, this.props.userInfo)} />
                                    </div>

                                    <div className={styles['select-dropdown']}>
                                        <label htmlFor={"contract-select"}>Leieforhold (valgfritt)</label>
                                        <SelectInput name={"contract-id"}
                                            id={"contract-select"}
                                            optionsWithId={contractDropdownContent}
                                            includeBlankOption={true} />
                                    </div>
                                </div>
                            )}

                            {this.shouldShowField("floor") && (
                                <FullWidthTextInput name="floor"
                                    placeholder="Etasje"
                                    disabled={this.state.isLoading}
                                    required={this.shouldRequireField("floor")} />
                            )}

                            {this.shouldShowField("room") && (
                                <FullWidthTextInput name="room"
                                    placeholder="Rom"
                                    disabled={this.state.isLoading}
                                    required={this.shouldRequireField("room")} />
                            )}
                        </fieldset>

                        <fieldset>
                            <legend>Henvendelsen gjelder</legend>

                            <p className={styles["selected-category"]}>
                                <span className={styles["category"]}>{topCategory.name}</span>
                                {!!subCategory && (
                                    <>
                                        <ArrowDown className={styles["category-arrow"]} />
                                        <span className={styles["category"]}>{subCategory.name}</span>
                                    </>
                                )}
                            </p>

                            {userNotOwner(userInfo) && (
                                <FullWidthTextInput placeholder="Innmelder"
                                    value={`${userInfo.name}, ${preferredContract.name}`}
                                    disabled={this.state.isLoading}
                                    readOnly />
                            )}

                            <FullWidthTextInput name="title"
                                placeholder="Overskrift"
                                defaultValue={titleDefaultValue}
                                disabled={this.state.isLoading}
                                required />

                            <FullWidthTextarea name="description"
                                placeholder="Beskrivelse"
                                rows={8}
                                defaultValue={descriptionDefaultValue}
                                disabled={this.state.isLoading}
                                required />

                            <ImageUpload className={styles["upload-input"]}
                                onChange={images => this.setState({ images })}
                                disabled={this.state.isLoading} />

                            <DocumentUpload className={styles["upload-input"]}
                                onChange={documents => this.setState({ documents })}
                                disabled={this.state.isLoading} />
                        </fieldset>

                        <button type="submit" className={styles['submit-button']} disabled={this.state.isLoading}>
                            <span>{!this.state.isLoading ? "Send" : "Sender..."}</span>
                            {this.state.isLoading && <LoadingIcon className={styles["loading-icon"]} />}
                        </button>
                    </form>
                )}
            </Mutation>
        )
    }
}

const CREATE_ISSUE_MUTATION = gql`
    mutation CreateIssue($title: String!, $buildingId: String!, $floor: String, $room: String, $priority: Int!, $category: String!, $contractId: String, $descriptionComment: CommentInput!) {
        createIssue(issue: { 
            title: $title,
            buildingId: $buildingId, 
            floor: $floor, 
            room: $room, 
            priority: $priority, 
            category: $category, 
            contractId: $contractId,
            descriptionComment: $descriptionComment,
            isService: false
        }) {
            status
            message
        }
    }
`

const QUERY = gql`
    query GetIssueInformationFormData($categoryId: ID!) {
        userInfo {
            username
            name
            roles
            companies {
                buildings {
                    id
                    street
                    contracts {
                        contractId
                        name
                    }
                }
            }
            contracts {
                contractId
                name
            }
        }

        issueCategories {
            id
            name
            subCategories {
                id
                name
            }
        }

        issueCategory(id: $categoryId) {
            name
            fields {
                fieldId
                value
            }
        }
    }
`

interface IssueInformationFormProps extends RouteComponentProps {
    preferredBuilding: Building
    preferredContract: Contract
}

export default (props: IssueInformationFormProps) => {

    const {
        topCategory: topCategoryId,
        subCategory: subCategoryId,
        isService: isService
    } = queryString.parse(props.location.search)

    const categoryId = subCategoryId ? subCategoryId : topCategoryId
    return (
        <Query query={QUERY} variables={{ categoryId }} partialRefetch={true}>
            {({ loading, error, data: { userInfo, issueCategories, issueCategory } }) => {
                if (loading) return <CenteredLoadingIcon />
                if (error) return <span>Error :(</span>

                return (
                    <IssueInformationForm {...props}
                        userInfo={userInfo}
                        issueCategories={issueCategories}
                        categoryVisibility={issueCategory} />
                )
            }}
        </Query>
    )
}

