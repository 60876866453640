import * as React from 'react'

import styles from './Dialog.module.scss'
import SendIcon from "../Icons/SendIcon";
import { IconButton, PrimaryButton } from "../Buttons/Buttons";
import DialogMessageList from "./DialogMessageList/DialogMessageList";
import { Image, DocumentAttachment } from "../../types/Issue";
import ImageUpload, { IFileUpload } from "../../features/ImageUpload/ImageUpload";
import DocumentUpload from '../../features/DocumentUpload/DocumentUpload';

export interface Message {
    id: string
    content: string
    time: string
    sender?: string
    sentByUser: boolean
    isSystemMessage: boolean
    images: Image[]
    documents: DocumentAttachment[]
}

interface Props {
    className?: string
    messages: Message[]
    onSubmitNewMessage(comment: string, imageIds: string[], documentIds: string[]): void
    status?: string
    issueId: string
}

interface State {
    message: string
    images: IFileUpload[]
    documents: IFileUpload[]
}

class Dialog extends React.Component<Props, State> {
    private lastDialogElementRef = React.createRef<HTMLLIElement>()
    private textField = React.createRef<HTMLTextAreaElement>()
    private imageUploadRef = React.createRef<ImageUpload>()
    private documentUploadRef = React.createRef<DocumentUpload>()

    state: State = {
        message: "",
        images: [],
        documents: []
    }

    componentDidMount(): void {
        setTimeout(this.scrollToBottomOfDialog, 200)

    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
        this.scrollToBottomOfDialog()
    }

    scrollToBottomOfDialog = () => {
        if (this.textField.current) {
            this.textField.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
        }
    }

    onSubmitNewMessage = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const message = `${this.state.message}`
        const imageIds = this.state.images.map(f => f.id)
        const documentIds = this.state.documents.map(f => f.id)
        this.setState({ message: "", images: [], documents: [] }, () => this.props.onSubmitNewMessage(message, imageIds, documentIds))
        if (this.imageUploadRef.current) this.imageUploadRef.current.resetFileList()
        if (this.documentUploadRef.current) this.documentUploadRef.current.resetFileList()
    }

    onChangeMessage = (event: React.FormEvent<HTMLTextAreaElement>) => {
        this.setState({ message: event.currentTarget.value })
    }

    onKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault()
            const message = `${this.state.message}`
            const imageIds = this.state.images.map(f => f.id)
            const documentIds = this.state.documents.map(f => f.id)
            this.setState({ message: "", images: [], documents: [] }, () => this.props.onSubmitNewMessage(message, imageIds, documentIds))
            if (this.imageUploadRef.current) this.imageUploadRef.current.resetFileList()
            if (this.documentUploadRef.current) this.documentUploadRef.current.resetFileList()
        }
    }

    render(): React.ReactNode {
        const { status, messages, className = "", issueId } = this.props

        return (
            <section className={`${styles['wrapper']} ${className}`}>
                <DialogMessageList messages={messages} lastDialogElementRef={this.lastDialogElementRef} issueId={issueId} />

                {status !== "CLOSED" && (
                    <form className={styles['message-form']} onSubmit={this.onSubmitNewMessage}>
                        <div className={styles["message-input-wrapper"]}>
                            {/* <label htmlFor="message-textarea" className={styles["message-label"]}>
                                Legg til kommentar
                            </label> */}
                            <textarea id="message-textarea"
                                rows={4}
                                onKeyDown={this.onKeyPress}
                                className={styles["message-textarea"]}
                                value={this.state.message}
                                ref={this.textField}
                                onChange={this.onChangeMessage}
                                placeholder="Skriv din beskjed her." />

                            <IconButton IconComponent={SendIcon}
                                type="submit"
                                className={styles["message-send-button"]}
                                iconClassName={styles["message-send-button-icon"]} />
                            <ImageUpload className={styles["upload-input"]} onChange={images => this.setState({ images })} ref={this.imageUploadRef} />
                            <DocumentUpload className={styles["upload-input"]} onChange={documents => this.setState({ documents })} ref={this.documentUploadRef} />
                        </div>

                    </form>
                )}
            </section>
        )
    }
}

export default Dialog