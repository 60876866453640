import * as React from 'react'
import { NavLink, NavLinkProps } from "react-router-dom";

import styles from "./IconButton.module.scss";

interface BaseProps {
    IconComponentType: React.ComponentType<React.SVGProps<SVGSVGElement>>
    iconClassName?: string
}

interface IconButtonProps extends BaseProps, React.ComponentProps<"button"> { }
export const IconButton = (props: IconButtonProps) => {
    const {
        children,
        className = "",
        IconComponentType,
        iconClassName = "",
        disabled,
        ...buttonProps
    } = props

    return (
        <button {...buttonProps} className={`${styles["button"]} ${className}`} disabled={disabled} aria-disabled={disabled}>
            <IconComponentType className={`${styles['icon']} ${iconClassName}`} /><span>{children}</span>
        </button>
    )
}

interface IconLinkButtonProps extends BaseProps, NavLinkProps {
    disabled?: boolean
}
export const IconLinkButton = (props: IconLinkButtonProps) => {
    const {
        children,
        className = "",
        IconComponentType,
        iconClassName = "",
        disabled = false,
        ...linkProps
    } = props

    const onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        if (disabled) event.preventDefault()
        else if (props.onClick) props.onClick(event)
    }

    return (
        <NavLink
            className={`${styles["button"]} ${className}`}
            onClick={onClick}
            aria-disabled={disabled}
            {...linkProps} >

            <span>{children}</span>
        </NavLink>
    )
}