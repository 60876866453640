import * as React from 'react'
import classNames from 'classnames'

import styles from '../OsloArealLogo/OsloArealLogo.module.scss';
import logo from '../OsloArealLogo/logo_oslo_areal.png'

// TODO: This should be an svg
const OsloArealLogo = (props: React.ImgHTMLAttributes<HTMLImageElement>) => {
    const { className = "", ...rest } = props

    const classes = classNames(styles['logo'], {
        [className]: !!className
    })

    return (
        <img src={logo} alt="Oslo Areal logo" className={classes} {...rest}/>
    )
}

OsloArealLogo.displayName = "OsloArealLogo"

export default OsloArealLogo