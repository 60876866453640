import * as React from 'react'
import classNames from 'classnames'

import styles from './NewsecLogo.module.scss';
import logo from './logo.png'

// TODO: This should be an svg
const NewsecLogo = (props: React.ImgHTMLAttributes<HTMLImageElement>) => {
    const { className = "", ...rest } = props

    const classes = classNames(styles['logo'], {
        [className]: !!className
    })

    return (
        <span>
            <img src={logo} alt="Newsec logo" className={classes} {...rest} />
            <span className={styles.logoSubText}>Serviceportal</span>
        </span>
    )
}

NewsecLogo.displayName = "NewsecLogo"

export default NewsecLogo