import * as React from 'react'
import { Query } from "react-apollo"
import gql from 'graphql-tag'

import styles from './ChooseCategory.module.scss';
import ToggleButton from "../../../viewComponents/ToggleButton/ToggleButton";
import FlipMove from "react-flip-move";
import { RouteComponentProps, withRouter } from "react-router";
import ImageLink from "../../../viewComponents/ImageLink/ImageLink";
import { TagType } from "../../../types/TagType";
import CenteredLoadingIcon from "../../../viewComponents/CenteredLoadingIcon/CenteredLoadingIcon";
import { IssueCategory } from "../../../types/IssueCategory";
import { UserInfo } from "../../../types/UserInfo";
import { userNotOwner } from "../../../tools/userUtils";

interface FeaturedCategory {
    urlText: string
    topCategory: string
    subCategory?: string
    imgSrc?: string
    title: string
    description: string
}

interface State {
    selectedTopCategory?: IssueCategory
    selectedSubCategory?: IssueCategory
}

interface Props extends RouteComponentProps {
    categoryList: IssueCategory[]
    userInfo: UserInfo
    selectionCallBack: Function
}

// TODO: Hardcoded. Should be configurable.
const featuredServices: FeaturedCategory[] = [
    {
        urlText: "Vaktmestertjenester",
        topCategory: "f59c9a28-f370-4c33-a1f1-0835ab8a5e60",
        title: "Bestilling av vaktmestertjenester",
        description: "Vi ønsker et tilbud på vaktmestertjenester."
    }
]

class ChooseCategory extends React.Component<Props, State> {

    state: State = {
        selectedTopCategory: undefined,
        selectedSubCategory: undefined
    }

    onClickTopCategory = (topCategory) => {

        this.setState({
            selectedTopCategory: topCategory
        })
        if (!topCategory.subCategories || topCategory.subCategories.length === 0) {
            this.props.selectionCallBack(topCategory);
        }
    }

    onClickSubCategory = (subCategory) => {
        this.setState({
            selectedSubCategory: subCategory
        })
        this.props.selectionCallBack(this.state.selectedTopCategory, subCategory)
    }

    onResetTopCategory = () => {
        this.setState({
            selectedTopCategory: undefined,
            selectedSubCategory: undefined
        })
    }

    onResetSubCategory = () => {
        this.setState({
            selectedSubCategory: undefined
        })
    }

    filterTopCategories = (currentCategory: IssueCategory, index: number) => {
        if (this.state.selectedTopCategory) return currentCategory.id === this.state.selectedTopCategory.id

        return true
    }


    render(): React.ReactNode {
        const { categoryList, userInfo } = this.props
        const { selectedTopCategory, selectedSubCategory } = this.state

        // const showAdditionalServices = userNotOwner(userInfo)
        return (
            <div className={styles["wrapper"]}>
                <h2 className={styles["heading"]}>HVA KAN VI HJELPE DEG MED?</h2>
                {categoryList.filter(c => c.name.toLowerCase() != "vaktmestertjenester" && c.name.toLowerCase() != "kundeportal").map(category => (
                    <span key={category.id}>
                        <span
                            className={`filterBtn ${selectedTopCategory == category ? "filterBtnActive" : ""}`}
                            onClick={() => {
                                this.onClickTopCategory(category)
                            }}>
                            {category.name}
                        </span>
                    </span>
                ))}

                {selectedTopCategory && selectedTopCategory.id == '5e55d603-0fd1-48b6-8cd9-98fc3e3438ee' && <p className={styles.infoMessage}>
                    For å legge til/slette/endre kontakter, klikk på 'Kontaktliste' i sidefeltet og bruk knappene ved siden av hver kontakt og 'Legg til'-knappen nederst for å legge til en ny.
                    </p>}


                {(selectedTopCategory && selectedTopCategory.id != '5e55d603-0fd1-48b6-8cd9-98fc3e3438ee' && selectedTopCategory.subCategories && selectedTopCategory.subCategories.length > 0) &&
                    <div>
                        <h2 className={styles["heading2"]}>Hva gjelder det?</h2>
                        {selectedTopCategory.subCategories.map(subCategory => (

                            <span key={subCategory.id}>
                                <span id={subCategory.id}
                                    className={`filterBtn `}
                                    onClick={() => {
                                        this.onClickSubCategory(subCategory)
                                    }}>
                                    {subCategory.name}
                                </span>
                            </span>
                        ))}
                    </div>}
            </div>
        )
    }
}

const CATEGORY_QUERY = gql`
    {
        issueCategories {
            id
            name
            fields {
                fieldId
                value
            }
            subCategories {
                id
                name
                fields {
                    fieldId
                    value
                }
            }
        }
    }
`

interface ChooseCategoryProps extends RouteComponentProps {
    userInfo: UserInfo,
    selectionCallBack: Function
}


const ChooseCategoryContainer = (props: ChooseCategoryProps) => {
    return <Query query={CATEGORY_QUERY} partialRefetch={true}>
        {({ loading, error, data }) => {
            if (loading) return <CenteredLoadingIcon text={"Laster kategorier"} />
            if (error) return <span>Error :(</span>

            return <ChooseCategory categoryList={data.issueCategories}  {...props} />
        }}
    </Query>
}

export default withRouter(ChooseCategoryContainer)