import * as React from "react";
import ReactDOM from "react-dom";

import styles from "./FullHeightModal.module.scss";
import {Link} from "react-router-dom";

interface Props extends React.HTMLProps<HTMLElement> {
    onClose(): void
    closeButtonText: string
}

class FullHeightModal extends React.Component<Props> {

    private readonly modalContentRef = React.createRef<HTMLDivElement>()
    private closeButtonRef: HTMLAnchorElement | null = null

    componentDidMount(): void {
        this.toggleLockScroll()
        // This will always exist so skip null check
        this.closeButtonRef!.focus()
    }

    componentWillUnmount(): void {
        this.toggleLockScroll()
    }

    toggleLockScroll = () => {
        const htmlEement = document.querySelector('html') as HTMLElement
        htmlEement.classList.toggle('lock-scroll')
    }

    onKeyDown = (event: React.KeyboardEvent) => {
        return event.keyCode === 27 && this.props.onClose()
    }

    onClickOutside = (event: React.MouseEvent<HTMLElement>) => {
        let modalContentDOMNode

        modalContentDOMNode = ReactDOM.findDOMNode(this.modalContentRef.current!)
        if (!modalContentDOMNode!.contains(event.target as HTMLElement)) {
            this.props.onClose()
        }
    }

    render() {
        return ReactDOM.createPortal(
            <aside className={styles['modal-background']}
                   tabIndex={-1}
                   onKeyDown={this.onKeyDown}
                   onClick={this.onClickOutside}>

                <div className={styles['modal-inner']}>
                    <Link to="/" className={styles['modal-close']} innerRef={node => this.closeButtonRef = node}>
                        <span>{this.props.closeButtonText}</span>
                    </Link>
                    <div className={styles['modal-content']} ref={this.modalContentRef}>
                        {this.props.children}
                    </div>
                </div>

            </aside>,
            document.body
        )
    }

}

export default FullHeightModal
