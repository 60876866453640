import { IService } from "./IService";

export const getSPToken = async () => {
    let token = await fetch("/api/spservices/gettoken", {
        credentials: "include", method: "POST"
    })
        .then(response => response.text())
        .then(data => {
            return data
        });
    return token;
}

let groupBy = (xs: any[], key: string) => {
    return xs.reduce((rv: any, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

export const getServicesFromSP = async () => {
    let token = await getSPToken();
    let res = await fetch("https://strongholdinvest.sharepoint.com/sites/OnboardingClientsNorway/_api/web/lists/GetByTitle('Services')/items", {
        method: 'GET',
        headers: {
            'Content-Type': "application/json",
            Authorization: token,
            Accept: 'application/json;odata=verbose'
        }
    }).then(response => response.json())
        .then(data => {
            return data
        });
    if (res && res.d && res.d.results) {
        let items = res.d.results.map((i: any) => {
            let temp: IService = {
                Category: i["Category"],
                Title: i["Title"],
                ZendeskID: i["ZendeskID"],
                ApplyForAll: i["ApplyForAll"],
                Longdescription: i["Longdescription"],
                ShortDescription: i["ShortDescription"],
                Image: i["TileImage"],
                New: i["NewServiceBadge"],
                Popular: i["PopularBadge"]
            }
            return temp
        })
        console.log("items:", items)
        return items
    }
}

export const getSPImage = async (token, url) => {
    let res = await fetch(url, {
        method: 'GET',
        headers: {
            // 'Content-Type': "application/json",
            Authorization: token,
            // Accept: 'application/json;odata=verbose'
        }
    }).then(response => response.blob())
        .then(data => {
            return data
        });
    if (res) {
        return res
        console.log("items:", res)

    }
}