import * as React from "react";

const useEffectSkipMount = (fn: Function, dependencies: any[] = []) => {
    const didMountRef = React.useRef<boolean>(false)

    React.useEffect(() => {
        if (didMountRef.current) {
            fn()
        } else {
            didMountRef.current = true
        }
    }, dependencies)
}

export default useEffectSkipMount