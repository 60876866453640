import * as React from 'react'
import classNames from 'classnames'

import styles from "./NotificationsPanel.module.scss";


interface Props {
    className?: string
    userInfo: { name: string }
}

class NotificationsPanel extends React.Component<Props> {
    render(): React.ReactNode {
        const {className = ""} = this.props
        const wrapperClasses = classNames(styles["wrapper"], {
            [className]: !!className
        })

        return (
            <section className={wrapperClasses}>
                <span className={styles["user-name"]}>{this.props.userInfo.name}</span>
            </section>
        )
    }
}

export default NotificationsPanel
