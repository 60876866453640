import * as React from 'react'
import { Query } from "react-apollo"
import { gql } from "apollo-boost"
import uuidv4 from 'uuid/v4'
import styles from './Contacts.module.scss'
import Table from "../../viewComponents/Table/Table";
import CenteredLoadingIcon from "../../viewComponents/CenteredLoadingIcon/CenteredLoadingIcon";
import { Company, Contract, UserInfo } from "../../types/UserInfo";
import { BuildingContact } from "../../types/BuildingContact";
import { useHistory } from "react-router-dom";

interface BuildingContactsProps {
    buildingId: string
    roles: string[]
    buildingContacts: BuildingContact[]
    title?: string
    loading: boolean
}

const BuildingContactsViewBody = ({ isBuildingOwner, buildingId, title, buildingContacts, loading }: { isBuildingOwner: boolean, buildingId: string, title?: string, buildingContacts: BuildingContact[], loading: boolean }) => {
    if (loading) {
        return <>
            <Table className={styles['building-contacts-table']}>
                <caption>{title ? title : "Forvaltningsteam"}</caption>
            </Table>
            <CenteredLoadingIcon />
        </>
    }

    return (
        <>
            <Table className={styles['building-contacts-table']}>
                <caption>{title ? title : "Forvaltningsteam"}</caption>
                <tbody>
                    {isBuildingOwner && (
                        <tr>
                            <td className={styles['kundesenter-info']}>Kundesenter</td>
                            <td className={styles['kundesenter-info']} />
                            <td className={styles['kundesenter-info']}><a href="tel:06760">06760</a></td>
                            <td className={styles['kundesenter-info']}><a href="mailto:kundesenter@newsec.no">kundesenter@newsec.no</a></td>
                        </tr>
                    )}
                    {!isBuildingOwner && (
                        <tr>
                            <td className={styles['kundesenter-info']}>Kundesenter</td>
                            <td className={styles['kundesenter-info']}><a href="mailto:kundesenter@newsec.no">kundesenter@newsec.no</a></td>
                        </tr>
                    )}

                    {buildingContacts.map((buildingContact: BuildingContact, index) => (
                        <tr key={index}>
                            <td>{buildingContact.role == 'Forvaltnin' ? 'Forvaltning' : buildingContact.role}</td>
                            <td>{buildingContact.name}</td>
                            {isBuildingOwner && (
                                <>
                                    <td className={styles["no-wrap"]}>
                                        {!!buildingContact.phone && (
                                            <a href={`tel:${buildingContact.phone.replace(/\s/g, "")}`}>
                                                {buildingContact.phone}
                                            </a>
                                        )}
                                    </td>
                                    <td>
                                        {!!buildingContact.email && (
                                            <a href={`mailto:${buildingContact.email}`}>{buildingContact.email}</a>
                                        )}
                                    </td>
                                </>
                            )}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    )
}



export class BuildingContacts extends React.Component<BuildingContactsProps> {
    render(): React.ReactNode {
        const { roles, buildingId, title, buildingContacts, loading } = this.props

        return (
            <>
                <BuildingContactsViewBody isBuildingOwner={roles.includes("OWNER")} buildingId={buildingId} title={title} buildingContacts={buildingContacts} loading={loading} />
            </>
        )
    }
}

interface ContactContainerProps {
    userInfo: UserInfo
    buildingId: string
    title?: string
}

// The container component should do all GraphQL queries
const ContactsContainer = (props: ContactContainerProps) => {
    const BUILDING_CONTACT_QUERY = gql`
                    query GetBuildingContacts($buildingId: String!) {
                        contactsForBuilding(buildingId: $buildingId) {
                        role
                name
                    email
                    phone
            }
        }
                    `

    let { userInfo, buildingId, title } = props

    return (
        <span>
            <Query query={BUILDING_CONTACT_QUERY} variables={{ buildingId: buildingId }} partialRefetch={true}>
                {({ loading, error, data }) => {
                    if (error) return <span>Error :/</span>

                    return <BuildingContacts buildingId={buildingId} roles={userInfo.roles} buildingContacts={data.contactsForBuilding} title={title} loading={loading} />
                }}

            </Query>
        </span>
    )
}

export default ContactsContainer