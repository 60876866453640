import * as React from 'react'

import styles from './Table.module.scss'

class Table extends React.Component<React.HTMLProps<HTMLTableElement>> {
    render(): React.ReactNode {

        const {
            children,
            className = "",
            ...tableProps
        } = this.props

        return (
            <div className={styles["scroll-container"]}>
                <table className={`${styles["table"]} ${className}`} {...tableProps}>
                    {children}
                </table>
            </div>
        )
    }
}

export default Table