import * as React from 'react'
import uuidv4 from 'uuid/v4'
import {ContractContact, getRoleDescription, UpdateContractContact} from '../../types/ContractContact'
import { Company } from '../../types/UserInfo'
import styles from './Contacts.module.scss'
import Modal from '../../viewComponents/Modal/Modal';

const formatPhoneNumber = (input: string) => {
    return input.replace(/(\d{3})(\d{2})(\d{3})/, '$1 $2 $3');
}

const isValidPhone = (input: string) => {
    if (!input) return true;
    if (/^\+{0,2}\d{6,14}$/.test(input.replace(/ /g, ''))) {
        return true;
    }
    else {
        return false;
    }
}

const isValidEEmail = (mail: string) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return (true)
    } else return (false)
}

const isValidName = (name: string) => {
    if (name && name.length > 3) {
        return (true)
    }
    return (false)
}

const contactToUpdateForm = (company: Company, item: ContractContact, isNewPerson: boolean): UpdateContractContact => {
    return {
        contact: {
            contractId: item.contractId === undefined ? "" : item.contractId,
            dataAreaId: company.id,
            email: item.email === undefined ? "" : item.email,
            name: item.name,
            phone: item.phone === undefined ? "" : item.phone,
            roleId: item.roleId
        },
        isNewPerson: isNewPerson
    }
}

export const addOrChangeContact = (company: Company, item: ContractContact, isNewPerson: boolean) => {
    return new Promise((resolve, reject) => {
        fetch("/api/dynamics/contact", {
            credentials: "include",
            method: "POST",
            body: JSON.stringify(contactToUpdateForm(company, item, isNewPerson)),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.text())
            .then(result => {
                console.log(result)
                resolve({ result: result, state: true })
            })
            .catch(error => reject({ result: error, state: false }));
    })
}

export const deleteContact = (company: Company, item: ContractContact) => {
    return new Promise((resolve, reject) => {
        fetch("/api/dynamics/contact/delete", {
            credentials: "include",
            method: "POST",
            body: JSON.stringify(contactToUpdateForm(company, item, false)),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.text())
            .then(result => {
                console.log(result)
                resolve({ result: result, state: true })
            })
            .catch(error => reject({ result: error, state: false }));
    })
}

// 'Kunde' must be converted into "Kunde1", "Kunde2" etc. up to max 15
export const getValidRoleFromGeneric = (role: string, contacts: ContractContact[]) => {
    if(role.toLowerCase() === "kunde") {
        for(let i=0; i<15; i++) {
            const matches = contacts.filter((c) => c.roleId.toLowerCase() === `kunde${i+1}`).length;
            if(matches === 0)
                return `Kunde${i+1}`;
        }
    }
    return role;
}

export const AddContactForm = (props: { contractId: string, preferredCompany: Company, contacts: ContractContact[], setShowAddForm: Function, onAdded: (c: ContractContact) => void }) => {
    const [name, setName] = React.useState("")
    const [mobile, setMobile] = React.useState("")
    const [email, setEmail] = React.useState("")
    const [role, setRole] = React.useState("kunde")
    const [valid, setValid] = React.useState(false)
    const [message, setMessage] = React.useState("")
    const [roleOptions, setRoleOptions] = React.useState<string[]>([])
    const [didCommit, setDidCommit] = React.useState(false);

    const resetState = () => {
        setName("")
        setMobile("")
        setEmail("")
        setRole("Kunde")
        setValid(false)
        setMessage("")
    }

    const validate = () => {
        if (!name) {
            setMessage('feltet "Navn" kan ikke være tomt');
            setValid(false);
            return false;
        } else {
            let existingContact = props.contacts.find(c => (c.name || "").toLocaleLowerCase() == name.toLocaleLowerCase() && c.roleId.toLocaleLowerCase() == role.toLocaleLowerCase())
            if (existingContact) {
                setMessage("Denne kontakten eksisterer allerede. Endre navn eller rolle.");
                setValid(false);
                return false;
            } else if (!isValidEEmail(email)) {
                setMessage("Vennligst skriv inn en gyldig e-post adresse");
                setValid(false);
                return false;
            } else if (!isValidPhone(mobile)) {
                setMessage("Skriv inn et gyldig telefonnummer (8 sifre)");
                setValid(false);
                return false;
            } else {
                setMessage("");
                setValid(true);
                return true;
            }
        }
    }

    const nameId = uuidv4();

    React.useEffect(() => {
        let options: string[] = [];
        // Only 15 customer roles available in dynamics
        if (props.contacts.filter(c => c.roleId.toLowerCase().startsWith("kunde")).length < 15) {
            options.push("Kunde")
        }
        if (props.contacts.filter(c => c.roleId.toLowerCase() === "brann").length == 0) {
            options.push("Brann")
        }
        if (props.contacts.filter(c => c.roleId.toLowerCase() == "kontrakt").length == 0) {
            options.push("Kontrakt")
        }
        if (props.contacts.filter(c => c.roleId.toLowerCase() == "forvaltnin").length == 0) {
            options.push("Forvaltnin")
        }
        if (props.contacts.filter(c => c.roleId.toLowerCase() == "moms").length == 0) {
            options.push("Moms")
        }
        setRole(options.length > 0 ? options[0] : "-")
        setRoleOptions(options)
    }, [props.contacts])

    // Success prompt
    if(didCommit) {
        return (
            <Modal onCloseTo="/contacts" onClose={() => { props.setShowAddForm(false) }} closeButtonText="Close">
                <div className={styles["contactNewForm"]}>
                    <span className={styles["contactFormHeader"]}>Ny kontakt</span>
                    <p>Forespørselen din er sendt. Det kan ta noe tid før kontakten er synlig overalt.</p>
                    <br/>
                    <br/>
                    <div className={styles["formBtnWrapper"]}>
                        <div>
                            <span className={styles["contactFormCancel"]} onClick={() => {
                                resetState();
                                props.setShowAddForm(false)
                            }}>
                                Lukk
                            </span>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }

    // Regular form
    return <Modal onCloseTo="/contacts" onClose={() => { props.setShowAddForm(false) }} closeButtonText="Close">
        <div className={styles["contactNewForm"]}>
        <span className={styles["contactFormHeader"]}>Ny kontakt</span>

        <div className={styles["formRow"]}>
            <label>Navn</label>
            <input className={styles["formInput"]} type="text" id={nameId} onChange={event => setName(event.target.value)} />
        </div>
        <div className={styles["formRow"]}>
            <label>Rolle</label>
            <select className={styles["formInput"]} name="Rolle" onChange={event => setRole(event.target.value)}>
                {roleOptions.map((ro, index) => {
                    return <option key={index} value={ro}>{ro === "Forvaltnin" ? "Forvaltning" : ro}</option>
                })}
            </select>
            {getRoleDescription(role) && <span style={{ fontStyle: "italic", lineHeight: "30px", opacity: 0.5 }}>
                {getRoleDescription(role)}
            </span>}
        </div>
        <div className={styles["formRow"]}><label>E-postadresse</label>
            <input className={styles["formInput"]} type="text" onChange={event => setEmail(event.target.value)} />
        </div>
        <div className={styles["formRow"]}><label>Telefonnummer</label>
            <input className={styles["formInput"]} type="text" onChange={event => setMobile(formatPhoneNumber(event.target.value))} />
        </div>
        {!valid && message && <p className={styles["warningMessageForm"]}>{message}</p>}
        <div className={styles["formBtnWrapper"]}>
            {roleOptions.length > 0 && (
                <span className={styles["contactFormBtn"]} onClick={() => {
                    if (validate()) {
                        const validRole = getValidRoleFromGeneric(role, props.contacts);
                        const contract: ContractContact = {
                            name: name,
                            roleId: validRole,
                            phone: mobile ?
                                parseInt(mobile.replace(/ /g, '')).toString() : "",
                            email: email,
                            contractId: props.contractId,
                            dataAreaId: props.preferredCompany.id
                        };
                        setDidCommit(true);
                        props.onAdded(contract);
                        addOrChangeContact(props.preferredCompany, contract, true)
                            .then();
                    }
                }}>LAGRE NY BRUKER</span>
            )}
            {roleOptions.length === 0 && <p className={styles["warningMessageForm"]}>
                Alle mulige roller er satt
            </p>}
            <div>
                <span className={styles["contactFormCancel"]} onClick={() => {
                    resetState();
                    props.setShowAddForm(false)
                }}>AVBRYT</span>
            </div>
        </div>

    </div>
    </Modal>
}


export const EditContactForm = (props: {
    contractId: string,
    preferredCompany: Company,
    contacts: ContractContact[],
    setShowAddForm: Function,
    canDelete: boolean,
    contact: ContractContact,
    onChanged: (c: ContractContact) => void,
}) => {

    const [name, setName] = React.useState(props.contact.name)
    const [email, setEmail] = React.useState(props.contact.email ? props.contact.email : "")
    const [mobile, setMobile] = React.useState(props.contact.phone ? props.contact.phone : "")
    const [role, setRole] = React.useState(props.contact.roleId)

    const [valid, setValid] = React.useState(false)
    const [message, setMessage] = React.useState("")

    const [emailMessage, setEmailMessage] = React.useState("")
    const [nameMessage, setNameMessage] = React.useState("")
    const [mobileMessage, setMobileMessage] = React.useState("")

    const [didCommit, setDidCommit] = React.useState(false);

    const resetState = () => {
        setName("")
        setMobile("")
        setEmail("")
        setRole("Kunde")
        setValid(false)
        setMessage("")
    }

    React.useEffect(() => {
        if (props.contact) {
            setName(props.contact.name)
            setMobile(props.contact.phone)
            setRole(props.contact.roleId)
            setEmail(props.contact.email)
        }
    }, [props.contact])

    const validate = () => {
        if (!isValidPhone(mobile) || !isValidEEmail(email) || !isValidName(name)) {
            setNameMessage((name && !isValidName(name)) ? "Skriv inn et gyldig navn (3 tegn eller mer)" : "");
            setEmailMessage((email && !isValidEEmail(email)) ? "Vennligst skriv inn en gyldig e-post adresse" : "");
            setMobileMessage((mobile && !isValidPhone(mobile)) ? "Skriv inn et gyldig telefonnummer (8 sifre)" : "");
            setValid(false);
            return false;
        }
        else if (mobile === props.contact.phone && email === props.contact.email && name === props.contact.name) {
            setMobileMessage("Vennligst endre noen av verdiene før du sender inn forespørselen din.");
            setValid(false);
            return false;
        } else {
            setMobileMessage("");
            setEmailMessage("");
            setNameMessage("");
            setValid(true);
            return true;
        }
    }

    const nameId = uuidv4();

    let onEditContact = () => {
        if (validate()) {
            const contract: ContractContact = {
                name: name,
                roleId: role,
                phone: mobile ?
                    parseInt(mobile.replace(/ /g, '')).toString() : "",
                email: email,
                contractId: props.contractId,
                dataAreaId: props.contact.dataAreaId
            };
            setDidCommit(true);
            props.onChanged(contract);
            const isNewPerson = email !== props.contact.email;
            addOrChangeContact(props.preferredCompany, contract, isNewPerson)
                .then();
        }
    }

    let onDeleteContact = () => {
        const contract: ContractContact = {
            name: name,
            roleId: role,
            email: email,
            phone: "",
            contractId: props.contractId,
            dataAreaId: props.contact.dataAreaId
        };
        setDidCommit(true);
        props.onChanged(contract);
        deleteContact(props.preferredCompany, contract)
            .then();
    }

    // Success prompt
    if(didCommit) {
        return (
            <Modal onCloseTo="/contacts" onClose={() => { props.setShowAddForm(false) }} closeButtonText="Close">
                <div className={styles["contactNewForm"]}>
                    <span className={styles["contactFormHeader"]}>Forespørsel sendt</span>
                    <p>Det kan ta noe tid før endringene er synlig overalt.</p>
                    <br/>
                    <br/>
                    <div className={styles["formBtnWrapper"]}>
                        <div>
                            <span className={styles["contactFormCancel"]} onClick={() => {
                                resetState();
                                props.setShowAddForm(false)
                            }}>
                                Lukk
                            </span>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }

    // Regular form
    return <Modal onCloseTo="/contacts" onClose={() => { props.setShowAddForm(false) }} closeButtonText="Close">
        <div className={styles["contactNewForm"]}>
        <span className={styles["contactFormHeader"]}>Endre kontakt</span>

        <div className={styles["formRow"]}>
            <label>Navn</label>
            <input className={styles["formInput"]} type="text" id={nameId} value={name} onChange={event => setName(event.target.value)} />
            {nameMessage && <p className={styles["warningMessageForm"]}>{nameMessage}</p>}
        </div>
        <div className={styles["formRow"]}><label>Rolle</label>
            <select className={styles["formInput"]} name="Rolle" disabled={true}>
                <option key={0} value={role}>{role == "Forvaltnin" ? "Forvaltning" : role}</option>
            </select>
        </div>
        <div className={styles["formRow"]}><label>E-postadresse</label>
            <input className={styles["formInput"]} type="text" value={email} onChange={event => setEmail(event.target.value)} />
            {emailMessage && <p className={styles["warningMessageForm"]}>{emailMessage}</p>}
        </div>
        <div className={styles["formRow"]}><label>Telefonnummer</label>
            <input className={styles["formInput"]} type="text" value={mobile} onChange={event => setMobile(formatPhoneNumber(event.target.value))} />
            {mobileMessage && <p className={styles["warningMessageForm"]}>{mobileMessage}</p>}
        </div>
        {!valid && message && <p className={styles["warningMessageForm"]}>{message}</p>}
        <div className={styles["formBtnWrapper"]}>
            <span className={styles["contactFormBtn"]} onClick={() => {
                if (validate()) {
                    onEditContact()
                }
            }}>LAGRE ENDRINGER</span>
            <div>
                <span
                    className={styles["contactFormCancel"]}
                    onClick={() => {
                        resetState();
                        props.setShowAddForm(false)
                    }}
                >
                    AVBRYT
                </span>
                {props.canDelete && <span className={styles["contactFormCancel"]} onClick={() => {
                    onDeleteContact();
                }}>SLETT KONTAKT</span>}
            </div>
        </div>
    </div>
    </Modal>
}
